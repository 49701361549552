import React, { memo } from 'react';
import { FieldAttributes, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './index.module.scss';

type FormValues = { [key: string]: any };

type Props = {
    caption?: string;
    field?: FieldAttributes<any>;
    form?: FormikProps<FormValues>;
};

export const CheckboxInput = memo((props: Props) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { caption, field, form, ...rest } = props;

    return (
        <label className={styles.container}>
            <input {...field} {...rest} />
            <span className={styles.checkbox}>
                <FontAwesomeIcon icon={faCheck} />
            </span>
            {caption && <span className={styles.caption}>{caption}</span>}
        </label>
    );
});
