import { EventListener, Unsubscribe } from 'modules/domain/common/types';

export enum AudioFileStatus {
    pending = 'pending',
    loading = 'loading',
    ready = 'ready',
    error = 'error',
    playing = 'playing',
    pause = 'pause',
}

export type LoadFileFn = () => Promise<string>;
export type PlayEndedCb = (err?: unknown) => void;

export enum AudioEvent {
    StatusChange = 'StatusChange',
    PlayPressed = 'PlayPressed',
}

type StatusChangeEvent = {
    status: AudioFileStatus;
};

export type AudioEventPayload = {
    [AudioEvent.StatusChange]: StatusChangeEvent;
    [AudioEvent.PlayPressed]: undefined;
};

export interface IPlayerControls {
    play(src: string, onEnded: PlayEndedCb): void;
    pause(src: string): void;
    stop(src: string): void;
}

export interface IAudioFile {
    status: AudioFileStatus;
    addEventListener<T extends AudioEvent>(type: T, listener: EventListener<AudioEventPayload[T]>): Unsubscribe;
    play(): void;
    pause(): void;
    stop(): void;
}

export interface IAudioPlayer {
    addAudio(baseName: string, loadFile: LoadFileFn): IAudioFile;
}
