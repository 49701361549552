import moment from 'moment';
import React from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
    timestamp: number;
};

export const MessageTimeStamp = (props: Props) => {
    const { timestamp } = props;
    const timeString = moment.utc(timestamp).calendar('', {
        sameDay: '[Today], h:mm A',
        nextDay: '[Tomorrow], h:mm A',
        nextWeek: 'dddd, h:mm A',
        lastDay: '[Yesterday], h:mm A',
        lastWeek: '[Last] dddd, h:mm A',
        sameElse: 'MMM D, h:mm A',
    });
    return (
        <div {...testId('message-timestamp')} className={styles.time}>
            {timeString}
        </div>
    );
};
