import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { Preferences } from '../types';

export const getUserPreferences = (api: SDVLegacyAPI, userId: string) => {
    return new Promise<Preferences>((resolve, reject) => {
        api.users.preferences.get(userId, (error: unknown, response: Preferences) => {
            if (error) {
                return reject({});
            }

            resolve(response);
        });
    });
};
