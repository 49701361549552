import findLast from 'lodash/findLast';

import { DialogMessageBaseDto, DialogMessageDto, MessageDeliveryStatus } from 'modules/api/dto';
import { createMessageReference, generateMessageTag } from 'modules/utils';
import { MessageType } from 'modules/domain/dialog/types';

export const getPhotoReference = (basename: string) =>
    createMessageReference(MessageType.PHOTO, basename, basename.startsWith('/'));

export const createBaseMessageDraft = (
    animatorId: string,
    attendeeId: string,
    text = '',
): Omit<DialogMessageBaseDto, 'meta'> => ({
    tag: generateMessageTag(animatorId),
    sender: animatorId,
    recipient: attendeeId,
    text,
    timestamp: new Date().getTime(),
});

export function findFirstUnpaidUnansweredMessage(
    animatorId: string,
    attendeeId: string,
    unpaidUnansweredMessages: DialogMessageDto[],
): DialogMessageDto | undefined {
    let result = findLast(unpaidUnansweredMessages, (message, index) => {
        return (
            message.status === MessageDeliveryStatus.UNKNOWN &&
            message.sender === attendeeId &&
            !message.meta?.reference &&
            unpaidUnansweredMessages[index - 1]?.sender === animatorId &&
            !unpaidUnansweredMessages[index - 1]?.meta?.Auto
        );
    });

    if (!result) {
        result = findLast(unpaidUnansweredMessages, (message, index) => {
            return (
                message.status === MessageDeliveryStatus.UNKNOWN &&
                message.sender === attendeeId &&
                !message.meta?.reference &&
                unpaidUnansweredMessages[index - 1]?.sender === animatorId
            );
        });
    }

    return result;
}
