import { useEffect } from 'react';

import { DialogModel } from 'modules/domain/dialog';

export function useOnDialogInputFocused(callback: () => void) {
    const { isDialogInputFocused } = DialogModel.hooks.useDialogTypedMessage();

    useEffect(() => {
        if (isDialogInputFocused) {
            callback();
        }
    }, [isDialogInputFocused, callback]);
}
