import React, { useState, useLayoutEffect } from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
    occupation: string;
    isAnimator: boolean;
    userId: string;
};

export const OccupationInfo = (props: Props) => {
    const { userId, occupation, isAnimator } = props;
    const ref = React.createRef<HTMLDivElement>();
    const [showMore, setShowMore] = useState(false);
    const [tooltipOpen, setTooltipOpen] = useState(false);

    useLayoutEffect(() => {
        if (ref.current && ref.current.clientWidth < ref.current.scrollWidth) {
            setShowMore(true);
        }
    }, [ref]);

    return (
        <div onMouseEnter={() => setTooltipOpen(true)} onMouseLeave={() => setTooltipOpen(false)}>
            <div ref={ref} className={styles.occupation} {...testId(`user-occupation-${userId}`)}>
                {occupation}
            </div>
            {showMore && tooltipOpen ? (
                <div className={cn(styles.tooltip, isAnimator ? styles.tooltipLeft : styles.tooltipRight)}>
                    {occupation}
                </div>
            ) : null}
        </div>
    );
};
