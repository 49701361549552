import { useEffect, useState } from 'react';
import moment from 'moment/moment';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { ModalModel } from 'modules/domain/modals';

import { getAvailableDates } from './get-available-dates';

export type Day = {
    count: number;
    date: string;
    idMax: number;
    idMin: number;
};

export function useDaysWithMessages() {
    const [availableDays, setAvailableDays] = useState<Day[]>([]);
    const { api } = useAuthApi();
    const { activeModalData } = ModalModel.hooks.useSelectors<{ animatorId: string; recipientId: string }>();

    useEffect(() => {
        if (!activeModalData || !activeModalData.animatorId || !activeModalData.recipientId) {
            return;
        }

        getAvailableDates(
            api,
            activeModalData.animatorId,
            activeModalData.recipientId,
            moment.utc().add(-1, 'year').format('YYYY-MM-DD'),
            moment.utc().add(1, 'day').format('YYYY-MM-DD'),
        ).then(dates => setAvailableDays(dates));
    }, [api, activeModalData, activeModalData?.recipientId, activeModalData?.animatorId]);

    return availableDays;
}
