import { History } from 'history';
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { FastAnswersModel } from 'modules/domain/fast-answers';
import { UserModel } from 'modules/domain/users';
import { AnimatorLocationModel } from 'modules/domain/animators-locations';
import { DialogModel } from 'modules/domain/dialog';
import { MediaModel } from 'modules/domain/media';
import { DictionaryModel } from 'modules/domain/dictionaries';
import { ModalModel } from 'modules/domain/modals';
import { SpotifyMatchModel } from 'modules/domain/spotify-match';
import { EarningsModel } from 'modules/domain/earnings';

import { RootState } from './root-state';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: [],
};

export const configureAppStore = (history: History, preloadedState = {}) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const rootReducer = combineReducers<RootState>({
        ...UserModel.reducer,
        ...AnimatorLocationModel.reducer,
        ...DialogModel.reducer,
        ...MediaModel.reducer,
        ...DictionaryModel.reducer,
        ...ModalModel.reducer,
        ...FastAnswersModel.reducer,
        ...SpotifyMatchModel.reducer,
        ...EarningsModel.reducer,
    });

    const persistedReducer = persistReducer(persistConfig, rootReducer);

    const store = configureStore({
        reducer: persistedReducer,
        middleware: [...getDefaultMiddleware({ serializableCheck: false })],
        preloadedState,
        enhancers: [],
    });

    const persistor = persistStore(store);

    return { store, persistor };
};
