import moment from 'moment';

function convertDateToTimeStamp(date: number | string) {
    return new Date(date).getTime();
}

function transformDateToAge(birthday: string) {
    return moment().utc().diff(moment(birthday), 'years');
}

const HALF_DAY_DELTA = 0.5;

const NOON = 12;
const SECOND = 1000;
const MINUTES_HOUR = 60;
const DAY = 24;

function convertDaysToMs(days: number) {
    return days * DAY * MINUTES_HOUR * MINUTES_HOUR * SECOND;
}

function isDateInRange(today: number, month: number, day: number, before: number, after: number): boolean {
    const dateUTC = Date.UTC(new Date(today).getFullYear(), month, day, NOON, 0, 0);
    const delta = today - dateUTC;
    const beforeMs = convertDaysToMs(before + HALF_DAY_DELTA);
    const afterMs = convertDaysToMs(after + HALF_DAY_DELTA);

    return -beforeMs <= delta && delta <= afterMs;
}

export { convertDateToTimeStamp, transformDateToAge, isDateInRange };
