import { Outlet } from 'react-router-dom';
import React, { memo, useState } from 'react';

import { PrivateRoute } from 'modules/App/private-route';
import { MajorPage } from 'modules/components/common/major-page';
import { StatsPageHeader } from 'modules/components/stats-page/components/header';
import { DatesRange } from 'modules/domain/earnings/types';
import { DEFAULT_DATES_RANGE } from 'modules/domain/earnings/constants';

import { shouldShowKPI } from './helpers';
import { StatsContext } from './context';

export const StatsPage = memo(() => {
    const [selectedRange, setSelectedRange] = useState<DatesRange>(DEFAULT_DATES_RANGE);

    const showKPI = shouldShowKPI(selectedRange);

    return (
        <PrivateRoute>
            <MajorPage>
                <StatsContext.Provider value={{ showKPI, selectedRange, onRangeChange: setSelectedRange }}>
                    <StatsPageHeader />
                    <Outlet />
                </StatsContext.Provider>
            </MajorPage>
        </PrivateRoute>
    );
});
