import { IHttpTransport } from 'modules/api/transport';

import { IUsersTagsDomain } from './contracts';
import { UserExperimentTag } from './dto';

export class UsersTagsDomain implements IUsersTagsDomain {
    constructor(private readonly transport: IHttpTransport) {}

    getUserExperimentsTags(userId: string): Promise<UserExperimentTag[]> {
        return this.transport
            .get<UserExperimentTag[]>(`/users/${userId}/tags/experiments`)
            .then(response => response.data);
    }
}
