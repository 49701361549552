import { getDialogId, convertDateToTimeStamp } from 'modules/utils';
import { DialogsMetricsDto, DialogState } from 'modules/api/dto';

import { Dialog, Metrics } from '../types';

export const mapDialogsDtoToDialogs = (response: DialogsMetricsDto): Dialog[] | null => {
    const dialogs = response.Dialogs;

    if (dialogs.length === 0) {
        return null;
    }

    const realm = response.Realm;

    return dialogs.map(dto => {
        const animatorId = dto.Animator.SiteId;
        const attendeeId = dto.Client.SiteId;
        const chatSpending = dto.ChatSpendings || 0;
        const id = getDialogId(animatorId, attendeeId);

        const dialog: Dialog = {
            serverId: dto.Id,
            id,
            animatorId,
            attendeeId,
            chatSpending,
            messages: [],
            unread: false,
            answered: dto.State === DialogState.UnansweredMessageNew,
            unansweredUnpaid: dto.QueueTypeId === 3, // unanswered unpaid queue type
            timestamp: convertDateToTimeStamp(dto.Timestamp),
            realm,
            isExplicit: dto.IsExplicit || false,
            isExplicitPossibleForClient: dto.ExplicitPossibleForClient || false,
            chatStartTimestamp: dto.ChatStartTimestamp,
            someMessagesFromTheDialogWereLoaded: false,
            scrollToMessageId: null,
        };

        return dialog;
    });
};

export const mapDialogsDtoToMetrics = (response: DialogsMetricsDto): Metrics => {
    return {
        unansweredCount: response?.Unanswered?.Count || 0,
        urgentUnansweredCount: response?.UrgentUnanswered?.Count || 0,
        urgentUnansweredPeriod: response?.UrgentUnanswered?.Period || 0,
    };
};

// to transform from "database id format" to "front-end id"
export const transformDataBaseIdToFrontId = (id: string | number | undefined): string => {
    if (!id) {
        return '';
    }

    if (typeof id === 'string') {
        return id;
    }

    const system = Math.floor(id / 10000000000);

    return ((id % 10000000000) * 100 + system).toString();
};
