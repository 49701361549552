import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { MediaModel } from 'modules/domain/media';
import fallbackMaleImage from 'images/fallback-male.svg';
import fallbackFemaleImage from 'images/fallback-female.svg';
import fallbackUnknownImage from 'images/fallback-unknown-gender.svg';
import { UserModel } from 'modules/domain/users';
import { getUserThumbnail } from 'modules/domain/users/user-adapter';
import { ImageOverlay } from 'modules/components/image-overlay';
import { useToken } from 'modules/domain/auth/use-token';
import { SentMediaType } from 'modules/domain/media/types';
import { getShardUri, testId } from 'modules/utils';
import { Image } from 'modules/components/image';
import { useConfig } from 'modules/config';

import styles from './index.module.scss';

type Props = {
    ownerId: string;
};

const IMAGE_SIZE = 560;

export const UserTabSentPhotos = memo((props: Props) => {
    const { ownerId } = props;

    // Selectors
    const appConfig = useConfig();
    const { token } = useToken();

    const { user } = UserModel.hooks.useUserSelectorsById(ownerId);
    const { gender } = user;

    const sentMedia = useSelector(state => MediaModel.selectors.getChatSentMedia(state, ownerId));

    const fallback = !gender ? fallbackUnknownImage : gender === 'mal' ? fallbackMaleImage : fallbackFemaleImage;

    const photosNew = useMemo(() => {
        return sentMedia.map(sentMedia => {
            const { id: basename, thumbnail, type } = sentMedia;

            if (type === SentMediaType.chat) {
                const overlaySrc = getUserThumbnail(
                    { id: ownerId, thumbnail: basename },
                    appConfig,
                    IMAGE_SIZE,
                    true,
                    false,
                    token,
                );

                return {
                    id: basename,
                    url: thumbnail,
                    overlaySrc: overlaySrc || fallback,
                };
            } else {
                return {
                    id: basename,
                    url: thumbnail,
                    overlaySrc:
                        getShardUri(
                            `${sentMedia.url}.${IMAGE_SIZE}x${IMAGE_SIZE}?authorization=Token%20token="${token}"`,
                            appConfig.sharding,
                        ) || fallback,
                };
            }
        });
    }, [appConfig, fallback, ownerId, token, sentMedia]);

    return (
        <div {...testId(`user-tab-sent-photos-${ownerId}`)} className={styles.container}>
            <div className={styles.containerInner}>
                <div className={styles.photosBlock}>
                    <div className={styles.photosGrid}>
                        {photosNew.map(({ id, url, overlaySrc }, i) => {
                            return (
                                <ImageOverlay key={id} selectedItem={i} src={overlaySrc} size={IMAGE_SIZE}>
                                    <Image
                                        a11yLabel={`user-tab-sent-photo-${id}`}
                                        src={url}
                                        alt={url}
                                        fallback={fallback}
                                    />
                                </ImageOverlay>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
});
