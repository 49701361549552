import React, { memo, useCallback } from 'react';

import { ModalModel } from 'modules/domain/modals';
import { DialogModel } from 'modules/domain/dialog';
import { FastAnswersModel } from 'modules/domain/fast-answers';
import { toast } from 'modules/utils';

import { FastAnswerModalForm } from './form';
import styles from './index.module.scss';

export const FastAnswersModal = memo(() => {
    const { closeModal } = ModalModel.hooks.useModal();
    const { dialog } = DialogModel.hooks.useSelectors();
    const { updateFastAnswers } = FastAnswersModel.hooks.useFastAnswers();
    const { loading, realmFastAnswersMap } = FastAnswersModel.hooks.useFastAnswersByRealm();

    const submitForm = useCallback(
        payload => {
            if (!dialog?.realm) {
                return;
            }

            updateFastAnswers(payload, dialog.realm);

            closeModal();

            toast.showMessage('Templates updated.');
        },
        [closeModal, dialog, updateFastAnswers],
    );

    return (
        <div>
            <div className={styles.content}>
                <div className={styles.title}>Templates</div>
                <div className={styles.subtitle}>
                    Use templates for sending messages faster. Enter %Name% to add name in your message template. For
                    example: Hello %Name%! Do you want to chat?
                </div>
            </div>
            <div className="formWrapper">
                {!loading && realmFastAnswersMap && (
                    <FastAnswerModalForm
                        initialValues={realmFastAnswersMap}
                        onSubmit={submitForm}
                        onCancel={closeModal}
                    />
                )}
            </div>
        </div>
    );
});
