import { useEffect, useState } from 'react';

import { useConfigLoader } from 'modules/config';
import { Api } from 'modules/api';

import { AppContextType } from '../context';

export const useAppContextInitializer = () => {
    const [context, setContext] = useState<AppContextType | null>(null);

    const config = useConfigLoader();

    useEffect(() => {
        if (config) {
            const api = new Api(config);

            const appContext: AppContextType = {
                config,
                api,
            };

            setContext(appContext);
        }
    }, [config]);

    return context;
};
