import React, { memo } from 'react';
import cn from 'classnames';
import { Picker, BaseEmoji } from 'emoji-mart';
import 'emoji-mart/css/emoji-mart.css';

import icon from 'images/icon-smile.svg';
import { testId } from 'modules/utils/test-id';
import { useClickOutside } from 'modules/domain/common/hooks';

import styles from './index.module.scss';

type Props = {
    onSelect: (emoji: BaseEmoji) => void;
    isVisible: boolean;
    onToggleVisibility: (currentVisibility: boolean) => void;
};

export const SmilePicker = memo((props: Props) => {
    const { isVisible, onSelect, onToggleVisibility } = props;

    const wrapperRef = useClickOutside<HTMLDivElement>({
        condition: isVisible,
        action: () => onToggleVisibility(true),
    });

    return (
        <div className={styles.container} ref={wrapperRef}>
            <div className={styles.button}>
                <img
                    {...testId('show-smile-picker-button')}
                    src={icon}
                    alt=""
                    onClick={() => onToggleVisibility(isVisible)}
                />
            </div>
            <div {...testId('smile-picker-container')} className={cn(styles.modal, { hidden: !isVisible })}>
                <Picker theme="light" onSelect={onSelect} />
            </div>
        </div>
    );
});
