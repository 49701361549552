import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';
import { useConfig } from 'modules/config';
import { useLogger } from 'modules/utils';

import { apiUserToUser, mapOperatorDtoToOperator } from '../user-adapter';
import { actions } from '../actions';
import { sendActivityStatus } from '../api';

export const useUserApi = () => {
    // Hooks
    const dispatch = useDispatch();
    const operatorId = useOperatorId();
    const { api } = useAuthApi();
    const { operators: operatorsApi, users: usersApi } = useApi();
    const { logError } = useLogger('useUserApi');

    // Selectors
    const appConfig = useConfig();
    const { backendAlias } = appConfig;

    const fetchUserData = useCallback(
        async (userId: string) => {
            try {
                const userData = await usersApi.getUserData(userId, true);

                const user = apiUserToUser(appConfig, userData);
                dispatch(actions.setUser(user));
                return user;
            } catch (error) {
                logError(`fetchUserData error. userId: ${userId}`, { error });
            }
        },
        [appConfig, dispatch, usersApi, logError],
    );

    const ping = useCallback(async () => {
        try {
            await sendActivityStatus(api, operatorId, { realm: backendAlias });
        } catch (error) {
            logError(`sendActivityStatus error`, { error });
        }
    }, [api, operatorId, backendAlias, logError]);

    const getOperatorFields = useCallback(async () => {
        try {
            const [operator, commission] = await Promise.all([
                operatorsApi.getOperator(operatorId),
                operatorsApi.getOperatorCommission(operatorId),
            ]);

            return mapOperatorDtoToOperator(operator, commission);
        } catch (error) {
            logError('Get operator fields error', { error });
            return null;
        }
    }, [operatorsApi, logError, operatorId]);

    return {
        fetchUserData,
        ping,
        getOperatorFields,
    };
};
