import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { range } from 'lodash';

import { RootState } from 'modules/App/store/root-state';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { DialogModel } from 'modules/domain/dialog';
import { useOperatorId } from 'modules/domain/auth';
import { useLogger } from 'modules/utils';

import { fetchFastAnswersStart, namespace, setFastAnswers } from '../actions';
import { FastAnswer, FastAnswersModelState } from '../types';
import { getFastAnswers, patchFastAnswers } from '../api';

import { useFastAnswersAnalytics } from './use-fast-answers-analytics';

export const getFastAnswersModelState = (state: RootState): FastAnswersModelState => state[namespace];

export const useFastAnswers = () => {
    const data = useSelector<RootState, any>(state => getFastAnswersModelState(state).data);
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { logError } = useLogger('useFastAnswers');
    const operatorId = useOperatorId();
    const { trackChangesOfFastAnswers } = useFastAnswersAnalytics();

    const fetchFastAnswers = useCallback(async () => {
        dispatch(fetchFastAnswersStart());

        try {
            const payload = await getFastAnswers(api, operatorId);
            dispatch(setFastAnswers(payload));
        } catch (error) {
            logError('fetchFastAnswers error', { error });
        }
    }, [api, dispatch, operatorId, logError]);

    const updateFastAnswers = useCallback(
        async (payload, realm) => {
            const updatedPayload = {
                ...data,
                [realm]: payload,
            };

            try {
                await Promise.all([
                    patchFastAnswers(api, operatorId, updatedPayload),
                    trackChangesOfFastAnswers(updatedPayload),
                ]);

                dispatch(setFastAnswers(updatedPayload));
            } catch (error) {
                logError('updateFastAnswers error', { error, updatedPayload });
            }
        },
        [api, data, dispatch, operatorId, trackChangesOfFastAnswers, logError],
    );

    return {
        data,
        fetchFastAnswers,
        updateFastAnswers,
    };
};

const defaultFastAnswersMap = range(1, 10)
    .concat([0])
    .reduce((acc, number) => ({ ...acc, [`ctrl${number}`]: '' }), {});

export const useFastAnswersByRealm = () => {
    const data = useSelector<RootState, any>(state => getFastAnswersModelState(state).data);
    const loading = useSelector<RootState, any>(state => getFastAnswersModelState(state).loading);
    const { dialog } = DialogModel.hooks.useSelectors();

    const realmFastAnswersMap = useMemo(() => {
        if (dialog?.realm) {
            return data[dialog.realm] || defaultFastAnswersMap;
        }

        return null;
    }, [data, dialog]);

    const fastAnswers = useMemo(() => {
        return Object.keys(realmFastAnswersMap || {}).reduce((acc, key) => {
            const fastAnswerPhrase = realmFastAnswersMap[key];
            const fastAnswer = {
                shortcut: key,
                shortcutDigit: Number(key.slice(4)),
                phrase: fastAnswerPhrase,
            };
            return [...acc, fastAnswer];
        }, [] as FastAnswer[]);
    }, [realmFastAnswersMap]);

    const hasFastAnswers = useMemo(() => {
        return fastAnswers.some(fastAnswer => fastAnswer.phrase.length !== 0);
    }, [fastAnswers]);

    return {
        loading,
        realmFastAnswersMap,
        hasFastAnswers,
        fastAnswers,
    };
};
