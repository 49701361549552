export type QueryParams = Record<string, any>;

export type StringifyOptions = {
    /**
     * Default: true
     */
    withQuestionMark?: boolean;
};

export const stringify = (obj: QueryParams, options?: StringifyOptions) => {
    const { withQuestionMark = true } = options || {};

    const queryString = Object.entries(obj)
        .map(([key, value]) => `${key}=${value}`)
        .join('&');

    return queryString && withQuestionMark ? `?${queryString}` : queryString;
};
