import moment from 'moment';
import { useDispatch } from 'react-redux';

import { getDialogId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { actions } from 'modules/domain/dialog/actions';

import { useDaysWithMessages } from './use-days-with-messages';

export function useCalendarTiles(animatorId: string, attendeeId: string) {
    const availableDays = useDaysWithMessages();
    const dispatch = useDispatch();

    const currentDialogId = getDialogId(animatorId, attendeeId);
    const { fetchDialogMessages } = DialogModel.hooks.useDialogMessagesApi(currentDialogId);

    function scrollToMessageWithDateFromTile(date: Date) {
        const tileDate = moment(date).format('YYYY-MM-DD');
        const dataItemForThisDate = availableDays.find(date => date.date === tileDate);

        if (!dataItemForThisDate) {
            return;
        }
        let howManyToFetch = 0;
        for (let i = 0; i < availableDays.length; i++) {
            const checkedDataItem = availableDays[i];
            if (moment.utc(checkedDataItem.date).isSameOrAfter(dataItemForThisDate.date)) {
                howManyToFetch += checkedDataItem.count;
            }
        }

        fetchDialogMessages(howManyToFetch, 0).then(() => {
            dispatch(actions.setScrollToMessageId(dataItemForThisDate.idMin));
        });
    }

    return {
        scrollToMessageWithDateFromTile,
    };
}
