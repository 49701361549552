import { UserExperimentTag } from 'modules/api/dto';
import { useOperatorId } from 'modules/domain/auth';
import { useUserExperimentTags } from 'modules/domain/users/hooks';

export const useDialogSearchEnabled = () => {
    const operatorId = useOperatorId();
    const operatorExperimentTags = useUserExperimentTags(operatorId);

    return operatorExperimentTags.includes(UserExperimentTag.DialogSearchByIdEnabled);
};
