import { useCallback, useEffect } from 'react';

import { ModalModel } from 'modules/domain/modals';

type Params = {
    canUseNextChatHotkey: boolean;
    action: () => void;
};

export const useChatSwitcher = ({ canUseNextChatHotkey, action }: Params) => {
    const { visibilityStatus: someModalIsOpen } = ModalModel.hooks.useSelectors();

    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            if (someModalIsOpen) {
                return;
            }

            if (event.key === 'Tab' && !event.shiftKey && canUseNextChatHotkey) {
                event.preventDefault();
                action();
            }
        },
        [action, canUseNextChatHotkey, someModalIsOpen],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);
};
