import React, { memo, PropsWithChildren } from 'react';

import { Centerer } from '../centerer';

import styles from './index.module.css';

type Props = PropsWithChildren<{
    title: string;
}>;

export const PageBodyWithTitle = memo((props: Props) => {
    const { children, title } = props;
    return (
        <Centerer>
            <div className={styles.body}>
                <div className={styles.title}>{title}</div>
                <Centerer> {children}</Centerer>
            </div>
        </Centerer>
    );
});
