import React, { memo, useCallback } from 'react';
import cn from 'classnames';

import { DialogModel } from 'modules/domain/dialog';
import { useDialogApi } from 'modules/domain/dialog/hooks';
import { useAuth } from 'modules/domain/auth/use-auth';
import { Button } from 'modules/components/common/button/base';
import { testId } from 'modules/utils/test-id';
import { DialogAutoSwitcher } from 'modules/components/multichat/multichat-window/DialogAutoSwitcher';

import { useChatSwitcher } from './use-chat-switcher';
import { useCanSwitchDialog } from './use-can-switch-dialog';
import styles from './index.module.scss';

type Props = {
    animatorId?: string;
    attendeeId?: string;
};

export const NextChatButton = memo((props: Props) => {
    const { animatorId, attendeeId } = props;
    // Hooks
    const { auth: superUser } = useAuth();
    const { fetchDialog } = useDialogApi();

    // I can't use "operator" from the state fetching here
    // because it stores operator id in the database format
    // and the util to convert it does not work ¯\_(ツ)_/¯
    // it is just more reliable to take operator id just from auth object
    const { auth: authOperator } = useAuth();

    // Selectors
    const { foundDialogId, lastBotMessage, dialogId, dialog } = DialogModel.hooks.useSelectors();

    const canSwitchDialog = useCanSwitchDialog(dialogId);

    const searchMode = !!foundDialogId;

    const goToNextChat = useCallback(() => {
        if (superUser.id) {
            fetchDialog(superUser.id, dialog?.serverId);
        }
    }, [superUser.id, fetchDialog, dialog?.serverId]);

    const showNextChatButton = !searchMode && !lastBotMessage;

    useChatSwitcher({
        canUseNextChatHotkey: showNextChatButton && canSwitchDialog,
        action: goToNextChat,
    });

    if (!showNextChatButton) {
        return null;
    }

    return (
        <div className={styles.container}>
            <Button
                {...testId('next-chat-button')}
                type="button"
                size="xs"
                theme="green"
                className={cn(styles.button, { [styles.disabledButtonSecondStyle]: !canSwitchDialog })}
                onClick={goToNextChat}
                disabled={!canSwitchDialog}
            >
                Next Chat
            </Button>
            {authOperator?.id ? (
                <DialogAutoSwitcher animatorId={animatorId} attendeeId={attendeeId} operatorId={authOperator.id} />
            ) : (
                <></>
            )}
            <span className={styles.label}>Press Tab</span>
        </div>
    );
});
