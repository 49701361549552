import { BonusProgramDto } from 'modules/api/dto';

export function getBonusProgramsIncome(bonusPrograms: BonusProgramDto[] | undefined) {
    return (bonusPrograms || []).reduce((acc, bonusProgram) => {
        return (
            acc +
            bonusProgram.Bonuses.reduce((innerAcc, bonus) => {
                return bonus.IsReached ? innerAcc + bonus.BonusValue : innerAcc;
            }, 0)
        );
    }, 0);
}
