import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IUsersDomain } from './contracts';
import { UserDto, UserLocationDto } from './dto';

export class UsersDomain implements IUsersDomain {
    constructor(private readonly transport: IHttpTransport) {}

    getUserLocation(userId: string, holderId: string): Promise<UserLocationDto> {
        return this.transport
            .get<UserLocationDto>(`/users/${userId}/location/${holderId}`)
            .then(response => response.data);
    }

    getUserData(userId: string, isPrivate?: boolean): Promise<UserDto> {
        return this.transport
            .get<UserDto>(isPrivate ? `/users/private/${userId}` : `/users/${userId}`)
            .then(response => response.data);
    }
}
