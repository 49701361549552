import React from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';
import { SpotifyMatchModel } from 'modules/domain/spotify-match';

import styles from './index.module.scss';
import { ContentItem } from './content-item';

type Props = {
    userId: string;
    attendeeId: string;
};

export const UserTabSpotifyMatch = (props: Props) => {
    const { userId, attendeeId } = props;
    const match = SpotifyMatchModel.hooks.useSpotifyMatchSelector(userId, attendeeId);

    if (!match) {
        return null;
    }

    return (
        <div {...testId(`user-tab-spotify-match-${attendeeId}`)} className={styles.container}>
            <div {...testId('user-tab-spotify-match-title')} className={styles.title}>
                Your taste match is{' '}
                <span
                    className={cn(styles.bubble, {
                        [styles.high]: match.level === 'High',
                        [styles.medium]: match.level === 'Medium',
                        [styles.low]: match.level === 'Low',
                    })}
                >
                    {match.score}
                </span>
            </div>

            {!!match.genres && <ContentItem testIdKey="genres" title="Genres" content={match.genres.join(', ')} />}
            {!!match.artists && <ContentItem testIdKey="artists" title="Artists" content={match.artists.join(', ')} />}
            {!!match.tracks && <ContentItem testIdKey="tracks" title="Tracks" content={match.tracks.join(', ')} />}
        </div>
    );
};
