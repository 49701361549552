import React, { memo } from 'react';
import cn from 'classnames';

import { parseDialogId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { AppNameLabel } from 'modules/components/common';
import { DayIndicator } from 'modules/components/day-indicator';

import { ExplicitBanner } from './explicit-banner';
import styles from './index.module.scss';

type Props = {
    dialogId: string;
    chatIsExplicit: boolean;
    operator: { isReadyForExplicit: boolean } | null | undefined;
    timestamp: number | null;
};

export const ChatHeader = memo((props: Props) => {
    const { dialogId, chatIsExplicit, operator, timestamp } = props;

    const { canToggleExplicit } = DialogModel.hooks.useSelectors();

    const { animatorId, attendeeId } = parseDialogId(dialogId);

    return (
        <div className={styles.container}>
            <div className={cn(styles.buttonsLine, timestamp ? styles.addScroll : null)}>
                <div className={styles.layoutFix}>
                    <AppNameLabel />
                </div>
                {timestamp ? (
                    <DayIndicator
                        timestamp={timestamp}
                        attachOnboarding={false}
                        onTop={true}
                        animatorId={animatorId}
                        recipientId={attendeeId}
                    />
                ) : (
                    <div />
                )}
                {canToggleExplicit ? (
                    <ExplicitBanner
                        status={chatIsExplicit}
                        operatorIsReadyForExplicit={!!operator?.isReadyForExplicit}
                        animatorId={animatorId}
                        clientId={attendeeId}
                    />
                ) : (
                    <div className={styles.layoutFix}>&nbsp;</div>
                )}
            </div>
        </div>
    );
});
