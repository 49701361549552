import { useEffect, useState } from 'react';

import { getUserLocationString } from 'modules/domain/users/user-adapter';
import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

export function useAnimatorsLocation(animatorId: string, attendeeId: string) {
    const [location, setLocation] = useState<string>('');
    const { users: usersApi } = useApi();
    const { logError } = useLogger('useAnimatorsLocation');

    useEffect(() => {
        if (attendeeId !== animatorId && attendeeId && animatorId) {
            usersApi
                .getUserLocation(animatorId, attendeeId)
                .then(newLocation => setLocation(getUserLocationString(newLocation.city, newLocation.country)))
                .catch(error => {
                    logError('Failed to get animator location', { error, animatorId, attendeeId });
                    setLocation('');
                });
        }
    }, [animatorId, attendeeId, usersApi, logError]);

    return location;
}
