import { useApi } from 'modules/api';

export const useApiAuthTokenActualizer = (token: string | undefined) => {
    const api = useApi();

    if (token) {
        api.setAuthToken(token);
    } else {
        api.clearAuthToken();
    }
};
