import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import React, { memo } from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
    title: string;
    url: string;
};

export const NavButton = memo((props: Props) => {
    const { title, url } = props;
    return (
        <NavLink
            to={url}
            className={({ isActive }) =>
                cn(styles.navButton, {
                    [styles.activeNavButton]: isActive,
                })
            }
            {...testId(`nav-button-${title.toLowerCase()}`)}
        >
            {title}
        </NavLink>
    );
});
