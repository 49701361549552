import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useConfig } from 'modules/config';

export const useCurrentApi = () => {
    const appConfig = useConfig();
    const { api } = useAuthApi();
    const currentApi = api;

    return {
        currentApi,
        appConfig,
    };
};
