import { useCallback, useEffect, useRef } from 'react';

import { DialogModel } from 'modules/domain/dialog';

export const useUnansweredUnpaidMessageCheck = (dialogId: string) => {
    const { unansweredUnpaidState, unansweredUnpaidMessage } = DialogModel.hooks.useSelectorsById(dialogId);

    const unansweredUnpaidMessageSent = useRef(false);

    useEffect(() => {
        unansweredUnpaidMessageSent.current = false;
    }, [dialogId]);

    const checkIsUnansweredUnpaidMessage = useCallback(() => {
        return unansweredUnpaidState && !!unansweredUnpaidMessage && !unansweredUnpaidMessageSent.current;
    }, [unansweredUnpaidState, unansweredUnpaidMessage]);

    const setUnansweredUnpaidMessageSent = useCallback(() => {
        unansweredUnpaidMessageSent.current = true;
    }, []);

    return { checkIsUnansweredUnpaidMessage, setUnansweredUnpaidMessageSent };
};
