/**
 * Отображение картинки
 * При ошибке загрузки картинки - отображение заглушки
 */
import React, { useCallback, useState, useEffect, useMemo } from 'react';

import { testId } from 'modules/utils/test-id';

type Props = {
    alt: string;
    a11yLabel?: string;
    src: string | null;
    fallback: string;
    className?: string;
};

export const ImageComponent = (props: Props) => {
    const { alt, className, src, fallback, a11yLabel } = props;

    const [source, setSource] = useState(src || fallback);
    const [isFallback, setIsFallback] = useState(!src);
    const testIdData = useMemo(() => {
        if (!a11yLabel) {
            return;
        }

        return testId(isFallback ? `${a11yLabel}-fallback` : a11yLabel);
    }, [a11yLabel, isFallback]);

    const onError = useCallback(() => {
        setIsFallback(true);
    }, []);

    useEffect(() => {
        if (isFallback) {
            setSource(fallback);
        }
    }, [fallback, isFallback]);

    useEffect(() => {
        if (typeof src === 'string') {
            setSource(src);
            setIsFallback(false);
        }
    }, [src]);

    return <img {...testIdData} className={className} src={source} alt={alt} onError={onError} />;
};

export const Image = React.memo(ImageComponent);
