import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { usePoller } from 'modules/hooks';

import { DatesRange } from '../types';

import { useEarningsApi } from './use-earnings-api';

export const useEarningsPoller = (range: DatesRange) => {
    const { fetchOperatorEarnings } = useEarningsApi();
    const { earningsPollingInterval } = useConfig();

    const pollEarnings = useCallback(() => fetchOperatorEarnings(range), [fetchOperatorEarnings, range]);

    usePoller(pollEarnings, earningsPollingInterval);
};
