import { ClientImprovedNote, PatchApiImprovedNoteData } from '../../types';

import { transformClientNoteToPatchApiNote } from './transformClientNoteToPatchApiNote';

export const getApiNoteForPatchRequest = (
    data: ClientImprovedNote,
    animatorId: string,
    attendeeId: string,
): PatchApiImprovedNoteData => {
    return {
        user: {
            id: attendeeId,
            note: transformClientNoteToPatchApiNote(data[attendeeId]),
        },
        animator: {
            id: animatorId,
            note: transformClientNoteToPatchApiNote(data[animatorId]),
        },
    };
};
