import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { AuthActions } from 'modules/domain/auth/actions';
import { MultiChatReducer } from 'modules/domain/common/types';

import { namespace, ModalActions, OpenModalPayload, CloseModalPayload } from './actions';
import { ModalModelState } from './types';

type CommonPayload = OpenModalPayload & CloseModalPayload;

interface CustomReducerMap extends ReducerMap<ModalModelState, CommonPayload> {
    [ModalActions.Open]: ReducerMapValue<ModalModelState, OpenModalPayload>;
    [ModalActions.Close]: ReducerMapValue<ModalModelState, CloseModalPayload>;
}

const initialState = {
    isOpen: false,
    activeModalName: null,
    data: null,
};

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [ModalActions.Open]: (state, { payload }) =>
        update(state, {
            $set: {
                isOpen: true,
                activeModalName: payload.name,
                data: payload.data,
            },
        }),
    [ModalActions.Close]: state =>
        update(state, {
            $set: {
                isOpen: false,
                activeModalName: null,
                data: null,
            },
        }),
};

export const reducer: MultiChatReducer<ModalModelState, CommonPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
