import { Media, ChatMedia, SentMedia } from './types';

export const namespace = 'media';

export enum MediaActions {
    SetList = 'media.set-list',
    SetChatList = 'media.set-chat-list',
    UpdateChatState = 'media.update-chat-state',
}

export type SetMediaListPayload = { userId: string; input: Media[] };

export type SetMediaChatListPayload = {
    userId: string;
    input: { enabled?: boolean; list: ChatMedia[]; available: boolean; sentMedia: SentMedia[] };
};
export type UpdateMediaEnabledPayload = { userId: string; input: { result: boolean; sentMedia: SentMedia[] } };

export type SetMediaListAction = { type: MediaActions; payload: SetMediaListPayload };
export type SetChatMediaListAction = { type: MediaActions; payload: SetMediaChatListPayload };
export type UpdateMediaEnabledAction = { type: MediaActions; payload: UpdateMediaEnabledPayload };

export function setMediaList(userId: string, input: Media[]): SetMediaListAction {
    return { type: MediaActions.SetList, payload: { userId, input } };
}

export function setChatMediaList(userId: string, input: SetMediaChatListPayload['input']): SetChatMediaListAction {
    return { type: MediaActions.SetChatList, payload: { userId, input } };
}

export function updateChatMediaState(
    userId: string,
    input: UpdateMediaEnabledPayload['input'],
): UpdateMediaEnabledAction {
    return { type: MediaActions.UpdateChatState, payload: { userId, input } };
}

export const actions = {
    setMediaList,
    setChatMediaList,
    updateChatMediaState,
};
