import React, { ChangeEvent, KeyboardEvent, memo, useCallback, useEffect, useRef, useState } from 'react';
import { BaseEmoji } from 'emoji-mart';

import { DialogModel } from 'modules/domain/dialog';
import { MediaSelectorButton } from 'modules/components/chat/media-selector/button';
import { SmilePicker } from 'modules/components/common';
import { GiftsPicker } from 'modules/components/gift-picker';
import { useChatMediaEnabled } from 'modules/domain/media/hooks';
import { testId } from 'modules/utils/test-id';
import { useAudioMessagesAvailable, useIcebreakersAvailable } from 'modules/domain/features';
import { getDialogId } from 'modules/utils';
import { AudioSelectorButton } from 'modules/components/chat/audio/selector/button';

import { IcebreakerPicker } from './icebreaker-picker';
import { TypedMessageIndicator } from './typed-message-indicator';
import { uncapsTheLastWord } from './utils';
import {
    useSendTextMessage,
    useCopilotOptionClicked,
    useTypedMessageIndicator,
    useChatInputTypingActions,
} from './hooks';
import styles from './index.module.scss';

type Props = {
    operatorCommission: string;
    animatorId: string;
    attendeeId: string;
    loading: boolean;
};

const SEND_BTN_KEY = 'Enter';

export const ChatForm = memo((props: Props) => {
    const { animatorId, attendeeId, loading } = props;

    const [smilesPickerIsVisible, setSmilesPickerVisible] = useState(false);

    const dialogId = getDialogId(animatorId, attendeeId);

    const { typedTextMessage, setTypedTextMessage, setAdditionalMessagePart, setIsDialogInputFocused } =
        DialogModel.hooks.useDialogTypedMessage();
    const { latestMessage } = DialogModel.hooks.useSelectorsById(dialogId);
    const indicator = useTypedMessageIndicator(typedTextMessage, latestMessage);
    const mediaInChatEnabled = useChatMediaEnabled(animatorId);
    const icebreakersEnabled = useIcebreakersAvailable(attendeeId);
    const audioMessagesEnabled = useAudioMessagesAvailable();
    const handleTypingChange = useChatInputTypingActions(dialogId, SEND_BTN_KEY);
    const sendTextMessage = useSendTextMessage(animatorId, attendeeId, indicator.status);

    useEffect(() => {
        setTypedTextMessage('');
    }, [dialogId, setTypedTextMessage]);

    const copilotOptionClicked = useCopilotOptionClicked(typedTextMessage);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (!loading || copilotOptionClicked) {
            inputRef.current?.focus();
        }
    }, [loading, copilotOptionClicked]);

    const handleEnterPress = () => {
        const trimmedMessage = typedTextMessage.trim();

        if (trimmedMessage) {
            const success = sendTextMessage(trimmedMessage);

            if (success) {
                setTypedTextMessage('');
            }
        }
    };

    const handleInputKeyPress = (event: KeyboardEvent<HTMLInputElement> & ChangeEvent<HTMLInputElement>) => {
        handleTypingChange(event);

        /*
         * It is kinda redundant for the current UI, the smiles popup will be closed on losing focus anyway
         * However, it could make sense if UI changes
         * */
        setSmilesPickerVisible(false);

        switch (event.key) {
            case ' ':
                setTypedTextMessage(uncapsTheLastWord(typedTextMessage));
                break;

            case 'Enter':
                handleEnterPress();
                break;
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        setTypedTextMessage(event.target.value);
    };

    const onSmileSelect = useCallback(
        (selectedSmile: BaseEmoji) => {
            setAdditionalMessagePart(selectedSmile.native, 'smile');
        },
        [setAdditionalMessagePart],
    );

    const toggleSmilesVisibility = useCallback(visible => setSmilesPickerVisible(!visible), []);

    return (
        <div className={styles.container}>
            <SmilePicker
                isVisible={smilesPickerIsVisible}
                onSelect={onSmileSelect}
                onToggleVisibility={toggleSmilesVisibility}
            />
            <input
                {...testId('send-message-input')}
                ref={inputRef}
                className={styles.input}
                type="text"
                placeholder="Type a message..."
                onKeyPress={handleInputKeyPress}
                onChange={handleInputChange}
                onFocus={() => setIsDialogInputFocused(true)}
                onBlur={() => setIsDialogInputFocused(false)}
                value={typedTextMessage}
            />
            <div className={styles.buttons}>
                {audioMessagesEnabled && <AudioSelectorButton dialogId={dialogId} />}
                <GiftsPicker senderId={animatorId} attendeeId={attendeeId} />
                {mediaInChatEnabled && (
                    <MediaSelectorButton
                        senderId={animatorId}
                        recipientId={attendeeId}
                        operatorCommission={props.operatorCommission}
                    />
                )}
                {icebreakersEnabled && <IcebreakerPicker senderId={animatorId} attendeeId={attendeeId} />}
            </div>
            <TypedMessageIndicator status={indicator.status} percent={indicator.percent} />
        </div>
    );
});
