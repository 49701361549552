import React from 'react';

export const ChevronUpIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M16.53 14.53c-.266.267-.683.29-.976.073l-.084-.073L12 11.061l-3.47 3.47c-.266.266-.683.29-.976.072l-.084-.073c-.267-.266-.29-.683-.073-.976l.073-.084 4-4c.266-.267.683-.29.976-.073l.084.073 4 4c.293.293.293.767 0 1.06z"
        />
    </svg>
);
