import { useEffect, useMemo, useCallback } from 'react';

// Domain
import { DictionaryModel } from 'modules/domain/dictionaries';
import { FastAnswersModel } from 'modules/domain/fast-answers';
import { DialogModel } from 'modules/domain/dialog';
import { UserModel } from 'modules/domain/users';
import { MediaModel } from 'modules/domain/media';
// Hooks
import { useAuth } from 'modules/domain/auth/use-auth';
import {
    useOnboarding,
    useUserApi,
    useUserInterestsApi,
    useUserPreferencesApi,
    useUserStoryApi,
    useUserTagsApi,
} from 'modules/domain/users/hooks';
import { useDialogApi } from 'modules/domain/dialog/hooks';

export const useDataPrefetch = () => {
    // Hooks
    const { auth: superUser } = useAuth();
    const { fetchAllDictionaries } = DictionaryModel.hooks.useDictionaryApi();
    const { fetchDialogNotes, fetchDialogPreferences } = DialogModel.hooks.useDialogNoteApi();
    const { fetchLifeStory } = useUserStoryApi();
    const { fetchDialog } = useDialogApi();
    const { fetchFastAnswers } = FastAnswersModel.hooks.useFastAnswers();
    const { dialog } = DialogModel.hooks.useSelectors();
    const { fetchMediaData } = MediaModel.hooks.useMediaApi();

    const { fetchUserData } = useUserApi();
    const { fetchUserTags, fetchUserExperimentsTags } = useUserTagsApi();
    const { fetchUserInterests } = useUserInterestsApi();
    const { fetchUserPreferences } = useUserPreferencesApi();
    const { initializeOnboardingMode } = useOnboarding();
    // Memo
    const animatorId = useMemo(() => dialog?.animatorId, [dialog]);
    const attendeeId = useMemo(() => dialog?.attendeeId, [dialog]);
    const conditionForFetchFastAnswers = useMemo(() => superUser.id && dialog?.realm, [superUser.id, dialog]);

    const { user: animatorUser } = UserModel.hooks.useUserSelectorsById(animatorId || '');
    const { user: attendeeUser } = UserModel.hooks.useUserSelectorsById(attendeeId || '');

    MediaModel.hooks.useChatMedia(animatorUser, attendeeUser);

    const fetchAllUserDataWithNested = useCallback(
        (userId: string) => {
            return fetchUserData(userId).then(user => {
                fetchLifeStory(userId);
                fetchUserInterests(userId);
                fetchUserTags(userId);
                fetchUserPreferences(userId);
                fetchUserExperimentsTags(userId);

                return user;
            });
        },
        [
            fetchLifeStory,
            fetchUserData,
            fetchUserExperimentsTags,
            fetchUserInterests,
            fetchUserPreferences,
            fetchUserTags,
        ],
    );

    useEffect(() => {
        const operatorId = superUser.id;
        if (operatorId) {
            fetchDialog(operatorId);
            fetchAllUserDataWithNested(operatorId).then(operator => {
                initializeOnboardingMode();
                fetchAllDictionaries(operator?.info.languages[0] || 'en');
            });
        }
    }, [initializeOnboardingMode, fetchDialog, superUser.id, fetchAllUserDataWithNested, fetchAllDictionaries]);

    useEffect(() => {
        if (conditionForFetchFastAnswers) {
            fetchFastAnswers().then();
        }
    }, [conditionForFetchFastAnswers, fetchFastAnswers]);

    useEffect(() => {
        if (animatorId && attendeeId) {
            fetchAllUserDataWithNested(animatorId);
            fetchAllUserDataWithNested(attendeeId);

            fetchDialogNotes(animatorId, attendeeId);
            fetchDialogPreferences(animatorId, attendeeId);

            fetchMediaData(animatorId);
            fetchMediaData(attendeeId);
        }
    }, [animatorId, attendeeId, fetchDialogPreferences, fetchDialogNotes, fetchMediaData, fetchAllUserDataWithNested]);

    return {};
};
