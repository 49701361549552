import { EarningsDto } from 'modules/api/dto';

export function getNumberOfBonuses(earnings: EarningsDto | null) {
    if (!earnings) {
        return 0;
    }

    const {
        FirstPurchaseBonus,
        MediaViewBonus,
        AnsweredFollowUpBonus,
        AnsweredUnpaidBonus,
        GiftsReplyBonus,
        BonusPrograms,
    } = earnings;

    const bonusProgramsBenefitsNumber = (BonusPrograms || []).reduce((acc, bonusProgram) => {
        return (
            acc +
            bonusProgram.Bonuses.reduce((acc, bonus) => {
                return bonus.IsReached ? acc + 1 : acc;
            }, 0)
        );
    }, 0);

    const allBenefitsNumber = [
        FirstPurchaseBonus,
        MediaViewBonus,
        AnsweredUnpaidBonus,
        AnsweredFollowUpBonus,
        GiftsReplyBonus,
    ]
        .filter(Boolean)
        .reduce((acc, bonus) => {
            return acc + (bonus?.BonusesCount || 0);
        }, bonusProgramsBenefitsNumber);

    return allBenefitsNumber;
}
