import { MatchType } from './types';

export enum MatchActions {
    SetData = 'spotify-match.set-data',
}

export type AddMatchPayload = { match: MatchType; animatorId: string; attendeeId: string };
type AddMatchAction = { type: MatchActions; payload: AddMatchPayload };

export const setMatch = (match: MatchType, animatorId: string, attendeeId: string): AddMatchAction => ({
    type: MatchActions.SetData,
    payload: { match, animatorId, attendeeId },
});
