import { useCallback } from 'react';

import { useDialogMessagesApi } from 'modules/domain/dialog/hooks';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { createMessageReference, getDialogId, useLogger } from 'modules/utils';
import { MessageType } from 'modules/domain/dialog/types';

import { fetchGifts, fetchGiftsAllowedData } from '../api';
import { getGiftReference, getGiftsPriceLimitFromDto } from '../helpers';
import { GiftDto } from '../types';

export const useGiftsApi = (senderId: string, attendeeId: string) => {
    const { api } = useAuthApi();
    const { logError } = useLogger('useGiftsApi');
    const dialogId = getDialogId(senderId, attendeeId);
    const { sendMessage } = useDialogMessagesApi(dialogId);

    const getGifts = useCallback(async () => {
        try {
            return await fetchGifts(api, attendeeId);
        } catch (error) {
            logError('Fetch gifts error', { error, attendeeId });

            return [];
        }
    }, [api, attendeeId, logError]);

    const getGiftsPriceLimit = useCallback(async () => {
        try {
            const response = await fetchGiftsAllowedData(api, senderId, attendeeId);

            return getGiftsPriceLimitFromDto(response);
        } catch (error) {
            logError('fetch gifts allowed data error', { error, senderId, attendeeId });

            return getGiftsPriceLimitFromDto({ result: false });
        }
    }, [api, senderId, attendeeId, logError]);

    const sendGift = useCallback(
        async (gift: GiftDto) => {
            try {
                await sendMessage({
                    reference: createMessageReference(MessageType.GIFT, getGiftReference(gift), true),
                    meta: { gift: gift.giftId },
                });

                return true;
            } catch (error) {
                logError('Send gift error', { error, gift });

                return false;
            }
        },
        [sendMessage, logError],
    );

    return { getGifts, getGiftsPriceLimit, sendGift };
};
