import React, { memo } from 'react';
import classNames from 'classnames';

import { MultichatHeader } from 'modules/components/multichat/multichat-header';
import { MultichatFooter } from 'modules/components/multichat/multichat-footer';
import { MultichatWindow } from 'modules/components/multichat/multichat-window';
import { Modal } from 'modules/components/common/modal';

import styles from './index.module.scss';

export const MultichatPage = memo(() => {
    return (
        <>
            <div className={classNames(styles.container, 'h-100')}>
                <MultichatHeader />
                <MultichatWindow />
                <MultichatFooter />
            </div>
            <Modal />
        </>
    );
});
