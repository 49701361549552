import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { transformDateToAge } from 'modules/utils/date';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';
import { getUserPreferences } from '../api';
import { FriendPreferences, Preferences } from '../types';

export const useUserPreferencesApi = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { logError } = useLogger('useUserPreferencesApi');

    const fetchUserPreferences = useCallback(
        async (userId: string) => {
            try {
                const preferences = await getUserPreferences(api, userId);
                const derivedPreferences: Preferences & FriendPreferences = { ...preferences };
                derivedPreferences.age =
                    derivedPreferences.birthday === undefined
                        ? undefined
                        : transformDateToAge(derivedPreferences.birthday);

                dispatch(actions.setUserPreferences(userId, derivedPreferences));
            } catch (error) {
                logError('fetchUserPreferences error', { userId, error });
            }
        },
        [api, dispatch, logError],
    );

    return {
        fetchUserPreferences,
    };
};
