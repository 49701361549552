import { ImprovedNoteEntity, NotesKey, GetApiImprovedNoteEntity } from '../../types';

import { generateDefaultNotes } from './constants';

export function transformGetApiNoteToClientNote(data: Partial<GetApiImprovedNoteEntity>): ImprovedNoteEntity {
    const keys = Object.keys(generateDefaultNotes()) as NotesKey[];

    const clientNote = keys.reduce((acc, key) => {
        const values = data[key]?.values;

        acc[key] = Array.isArray(values) ? values : [];

        return acc;
    }, {} as ImprovedNoteEntity);

    return clientNote;
}
