import React, { memo, useEffect, useMemo } from 'react';

import { UserTag, UserTargetedTag } from 'modules/api/dto';
import { User as UserType } from 'modules/domain/users/types';
import { useUserTargetedTagsApi } from 'modules/domain/users/hooks';
import { DialogModel } from 'modules/domain/dialog';
import { UserModel } from 'modules/domain/users';

import { LabelIds } from '../constants/label-ids';

import { LabelItem } from './item';
import styles from './index.module.scss';

type Props = UserType & {
    me: boolean;
    animatorId: string;
    attendeeId: string;
};

type LabelConfig = { id: LabelIds; enabled: boolean; textTemplateParam?: string | number };

export const Labels = memo(function Labels(props: Props) {
    const { attendeeId, me, tags, birthday: animatorBirthday } = props;
    const isAnimator = me;

    const { dialog } = DialogModel.hooks.useSelectors();
    const { user: attendeeData } = UserModel.hooks.useUserSelectorsById(attendeeId);

    const { isExplicitPossibleForClient } = dialog || {};

    const { targetedTagsState: targetedTagsStateRevert, fetchUserTargetedTags: fetchUserTargetedTagsRevert } =
        useUserTargetedTagsApi();

    // todo overcomplicated, could be refactored
    const { idsForTargetedTagsRevert } = useMemo((): {
        idsForTargetedTags: [string, string];
        idsForTargetedTagsRevert: [string, string];
    } => {
        return {
            idsForTargetedTags: isAnimator
                ? [props.attendeeId, props.animatorId]
                : [props.animatorId, props.attendeeId],
            idsForTargetedTagsRevert: !isAnimator
                ? [props.attendeeId, props.animatorId]
                : [props.animatorId, props.attendeeId],
        };
    }, [isAnimator, props.animatorId, props.attendeeId]);

    useEffect(() => {
        fetchUserTargetedTagsRevert(...idsForTargetedTagsRevert);
    }, [fetchUserTargetedTagsRevert, idsForTargetedTagsRevert]);

    const firstChatShowingNeeded =
        !targetedTagsStateRevert.loading &&
        !!targetedTagsStateRevert.value &&
        targetedTagsStateRevert.value.includes(UserTargetedTag.DialogsFirstChat);

    const activeLabels = useMemo(() => {
        const clientLabelsWithOrder: LabelConfig[] = [
            {
                id: LabelIds.CurrentTime,
                enabled: attendeeData.timezone !== undefined,
                textTemplateParam: attendeeData.timezone,
            },
            {
                id: LabelIds.FirstMessageDate,
                enabled: !!dialog?.chatStartTimestamp,
                textTemplateParam: dialog?.chatStartTimestamp,
            },
            {
                id: LabelIds.VIP,
                enabled: tags?.includes(UserTag.CreditsAccountsVip),
            },
            {
                id: LabelIds.SEXTING,
                enabled: !!isExplicitPossibleForClient,
            },
            {
                id: LabelIds.FIRST_CHAT,
                enabled: firstChatShowingNeeded,
            },
        ];

        const animatorLabelsWithOrder: LabelConfig[] = [
            {
                id: LabelIds.Birthday,
                enabled: !!animatorBirthday,
                textTemplateParam: animatorBirthday,
            },
        ];

        const relevantLabels = isAnimator ? animatorLabelsWithOrder : clientLabelsWithOrder;
        return relevantLabels.filter(label => label.enabled);
    }, [
        animatorBirthday,
        attendeeData.timezone,
        dialog?.chatStartTimestamp,
        tags,
        isExplicitPossibleForClient,
        firstChatShowingNeeded,
        isAnimator,
    ]);

    return (
        <div className={styles.labels}>
            {activeLabels.map(({ id, textTemplateParam }) => (
                <LabelItem key={id} id={id} textTemplateParam={textTemplateParam} />
            ))}
        </div>
    );
});
