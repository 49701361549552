import React, { memo, useEffect, useMemo, useState } from 'react';

import {
    AudioMessageTemplate,
    useAudioMessagesTemplates,
    useSendAudioMessageTemplate,
} from 'modules/domain/dialog/audio';
import { Loader } from 'modules/components/loader';

import { AudioCategoriesSidebar } from './components/categories-sidebar';
import { AudioMessagesList } from './components/messages-list';
import { groupTemplatesByCategories } from './helpers';
import { useTypingIndicatorActive } from './hooks';
import styles from './styles.module.scss';

export const AudioSelectorModal = memo(() => {
    const [selectedCategory, setSelectedCategory] = useState<string>('');

    // keep typing indicator active while audio selector modal is opened
    useTypingIndicatorActive();

    const { audioMessagesTemplates } = useAudioMessagesTemplates();
    const sendMessageTemplate = useSendAudioMessageTemplate();

    const { templatesByCategories, categories } = useMemo(
        () => groupTemplatesByCategories(audioMessagesTemplates),
        [audioMessagesTemplates],
    );

    useEffect(() => {
        const [firstCategory] = categories;
        if (firstCategory) {
            setSelectedCategory(firstCategory);
        }
    }, [categories]);

    const handleItemSendClick = (template: AudioMessageTemplate) => {
        sendMessageTemplate(template);
    };

    const renderContent = () => {
        if (!templatesByCategories) {
            return (
                <div className={styles.placeholderContainer}>
                    <Loader size={60} />
                </div>
            );
        }

        const categoryMessages = templatesByCategories[selectedCategory];

        if (!categoryMessages) {
            return (
                <div className={styles.placeholderContainer}>
                    <p>There are no available voice messages for this client</p>
                </div>
            );
        }

        return (
            <div className={styles.content}>
                <AudioCategoriesSidebar
                    categories={categories}
                    selected={selectedCategory}
                    onSelect={setSelectedCategory}
                />
                <AudioMessagesList
                    messages={templatesByCategories[selectedCategory]}
                    onItemSendClick={handleItemSendClick}
                />
            </div>
        );
    };

    return (
        <div className={styles.container}>
            <h4 className={styles.title}>Send Voice Message</h4>
            {renderContent()}
        </div>
    );
});
