import React from 'react';

import { AudioMessageTemplate } from 'modules/domain/dialog/audio';

import { AudioMessageItem } from './message-item';
import styles from './styles.module.scss';

export type Props = {
    messages: AudioMessageTemplate[];
    onItemSendClick: (message: AudioMessageTemplate) => void;
};

export const AudioMessagesList = (props: Props) => {
    const { messages, onItemSendClick } = props;

    return (
        <div className={styles.messagesList}>
            <div className={styles.inner}>
                {messages.map(item => (
                    <AudioMessageItem
                        key={item.id}
                        text={item.text}
                        basename={item.basename}
                        onSendClick={() => onItemSendClick(item)}
                    />
                ))}
            </div>
        </div>
    );
};
