import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';

import { useOperatorId } from 'modules/domain/auth';
import { NetworkErrorStatus, HttpError, useApi } from 'modules/api';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useChatMediaApi } from 'modules/domain/media/hooks';
import { parseDialogId, request, getFormData, MESSAGE_TYPES, getUrl, useLogger } from 'modules/utils';
import { DialogMessageBaseDto, SearchedDialogMessageDto } from 'modules/api/dto';
import { SendMessagePayload } from 'modules/api/payload';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { SearchMessagesDirection, MessageType, SendDialogMessageData } from 'modules/domain/dialog/types';
import { actions } from 'modules/domain/dialog/actions';

import { useSelectorsById } from '../use-selectors';
import { useDialogTypedMessage } from '../use-dialog-typed-message';
import { useDialogAnalytics } from '../use-dialog-analytics';

import { createBaseMessageDraft, findFirstUnpaidUnansweredMessage, getPhotoReference } from './helpers';

const MIN_SEARCH_PHRASE_LENGTH = 3;

export const useDialogMessagesApi = (dialogId: string) => {
    // Hooks
    const dispatch = useDispatch();
    const operatorId = useOperatorId();
    const { dialogsMessages: dialogsMessagesApi } = useApi();
    const { api } = useAuthApi();
    const { fastAnswerInTypedMessage } = useDialogTypedMessage();
    const { fetchChatMediaState } = useChatMediaApi();
    const { trackDialogMessageSendingWithFastAnswer } = useDialogAnalytics();
    const { logError } = useLogger('useDialogMessagesApi');

    const { noMoreMessages, searchedMessages: searchedMessagesState, messages } = useSelectorsById(dialogId);

    // States
    const [messageSending, setMessageSending] = useState(false);

    const { animatorId, attendeeId } = parseDialogId(dialogId);

    const fetchDialogMessages = useCallback(
        async (count: number, skip: number) => {
            if (noMoreMessages && skip !== 0) {
                return;
            }

            try {
                const messages = await dialogsMessagesApi.getDialogMessages(animatorId, attendeeId, {
                    select: count,
                    omit: skip,
                });

                if (messages.length < count) {
                    dispatch(actions.setNoMoreMessages(dialogId));
                }

                dispatch(actions.setMessages(dialogId, messages));
            } catch (error) {
                logError(`Failed to fetch dialog messages. DialogId: ${dialogId}`, { error });
            }
        },
        [noMoreMessages, attendeeId, animatorId, dialogsMessagesApi, dispatch, dialogId, logError],
    );

    const sendMessageBase = useCallback(
        async (data: SendDialogMessageData, draft?: DialogMessageBaseDto) => {
            const messageDraft: DialogMessageBaseDto = {
                ...createBaseMessageDraft(animatorId, attendeeId, data.text),
                ...draft,
                meta: {
                    ...draft?.meta,
                    ...data.meta,
                },
            };

            if (data.reference) {
                messageDraft.meta.reference = data.reference;
            }

            try {
                setMessageSending(true);

                dispatch(actions.apiMessageSent(dialogId, messageDraft));

                const messageBody: SendMessagePayload = {
                    tag: messageDraft.tag,
                    text: messageDraft.text,
                    reference: data.reference,
                    instant: 1,
                    operator: operatorId,
                    meta: data.meta,
                };

                await dialogsMessagesApi.sendMessage(animatorId, attendeeId, messageBody);

                if (fastAnswerInTypedMessage) {
                    trackDialogMessageSendingWithFastAnswer(attendeeId, messageBody.text, fastAnswerInTypedMessage);
                }

                setMessageSending(false);
            } catch (error) {
                logError('Failed to send a message', { error, message: messageDraft, dialogId });

                dispatch(
                    actions.apiSendMessageFailure(
                        dialogId,
                        messageDraft.tag,
                        (error as HttpError).status === NetworkErrorStatus.Conflict,
                    ),
                );

                throw error;
            }
        },
        [
            dialogsMessagesApi,
            animatorId,
            dialogId,
            dispatch,
            fastAnswerInTypedMessage,
            attendeeId,
            operatorId,
            trackDialogMessageSendingWithFastAnswer,
            logError,
        ],
    );

    const sendMessage = useCallback((data: SendDialogMessageData) => sendMessageBase(data), [sendMessageBase]);

    const sendPhotoMessage = useCallback(
        (basename: string) => {
            const reference = getPhotoReference(basename);

            return sendMessageBase({ reference });
        },
        [sendMessageBase],
    );

    const sendPhotoMessageWithFileUpload = useCallback(
        async (file: File) => {
            const mediaUrl = URL.createObjectURL(file);
            const mediaType = MESSAGE_TYPES.get(file.type);

            if (mediaType !== MessageType.PHOTO) return;

            const draftMessage: DialogMessageBaseDto = {
                ...createBaseMessageDraft(animatorId, attendeeId),
                meta: {
                    localPhotoUri: mediaUrl,
                },
            };

            dispatch(actions.apiUploadMediaStart(dialogId, draftMessage));

            try {
                const uploadResponse = await request(getUrl(api, `/dialogs/usermedia/${attendeeId}/${animatorId}`), {
                    method: 'POST',
                    body: getFormData(file),
                    headers: {
                        authorization: api.authorize(),
                    },
                    onUploadProgress: progress => {
                        dispatch(actions.apiUploadMediaSetProgress(dialogId, draftMessage.tag, progress));
                    },
                });

                dispatch(actions.apiUploadMediaSuccess(dialogId, draftMessage.tag));

                const dto = JSON.parse(uploadResponse.responseText);
                const basename = dto.basename || (dto.basenames || dto)[0];
                const reference = getPhotoReference(basename);

                await sendMessageBase({ reference }, draftMessage);

                fetchChatMediaState(animatorId, attendeeId);
            } catch (error) {
                logError('Failed to send a photo message with file upload', {
                    error,
                    message: draftMessage,
                    dialogId,
                });

                dispatch(actions.apiUploadMediaFailure(dialogId, draftMessage.tag));

                showChatErrorToast('Failed to send a photo. Please check your connection and try again later');
            }
        },
        [animatorId, attendeeId, dispatch, api, sendMessageBase, dialogId, fetchChatMediaState, logError],
    );

    const messagesCount = messages.length;

    const onSearchedMessageActiveIndexChange = useCallback(
        async (searchedMessages: SearchedDialogMessageDto[], activeIndex: number) => {
            const { n, messageId } = searchedMessages[activeIndex];

            const requiredMessagesCount = n - messagesCount;

            if (requiredMessagesCount > 0) {
                await fetchDialogMessages(requiredMessagesCount, messagesCount);
            }

            dispatch(actions.setScrollToMessageId(messageId));
        },
        [dispatch, fetchDialogMessages, messagesCount],
    );

    const switchSearchMessages = useCallback(
        (direction: SearchMessagesDirection) => {
            if (!searchedMessagesState) {
                return;
            }

            const { currentSearchedMessageIndex, messages } = searchedMessagesState;

            const newIndex = {
                up: currentSearchedMessageIndex + 1,
                down: currentSearchedMessageIndex - 1,
            }[direction];

            dispatch(actions.switchSearchedMessageIndex(dialogId, newIndex));

            return onSearchedMessageActiveIndexChange(messages, newIndex);
        },
        [dialogId, dispatch, searchedMessagesState, onSearchedMessageActiveIndexChange],
    );

    const searchMessages = useCallback(
        async (phrase: string) => {
            if (phrase.length < MIN_SEARCH_PHRASE_LENGTH) {
                dispatch(actions.clearSearchedMessages(dialogId));
                return;
            }

            try {
                const response = await dialogsMessagesApi.searchDialogMessages(animatorId, attendeeId, phrase);
                dispatch(actions.apiSearchMessagesSuccess(dialogId, phrase, response));

                if (response.messages.length) {
                    await onSearchedMessageActiveIndexChange(response.messages, 0);
                }
            } catch (error) {
                logError('Failed to search dialog messages', { error, phrase });
            }
        },
        [dialogsMessagesApi, animatorId, dialogId, dispatch, onSearchedMessageActiveIndexChange, attendeeId, logError],
    );

    const fetchUnansweredUnpaidDialogMessage = useCallback(async () => {
        try {
            const messages = await dialogsMessagesApi.getDialogMessages(attendeeId, animatorId, {
                select: 30,
                omit: 0,
                withUnsent: true,
            });

            const firstUnpaidUnansweredMessage = findFirstUnpaidUnansweredMessage(animatorId, attendeeId, messages);

            if (firstUnpaidUnansweredMessage) {
                dispatch(actions.setUnpaidUnansweredMessage(dialogId, firstUnpaidUnansweredMessage));
            }
        } catch (error) {
            logError('Failed to fetchUnansweredUnpaidDialogMessage', { error });
        }
    }, [attendeeId, animatorId, dialogsMessagesApi, dispatch, dialogId, logError]);

    return {
        sendMessage,
        sendPhotoMessage,
        sendPhotoMessageWithFileUpload,
        fetchDialogMessages,
        fetchUnansweredUnpaidDialogMessage,
        searchMessages,
        switchSearchMessages,
        messageSending,
    };
};
