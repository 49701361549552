import React from 'react';

export const LogoutIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M9.45866 11.0009V3.35352H10.542V11.0009H9.45866ZM10.0003 16.5842C9.0815 16.5842 8.22442 16.4122 7.42908 16.0681C6.63371 15.7239 5.93826 15.2541 5.3427 14.6585C4.74713 14.063 4.27729 13.3675 3.93318 12.5722C3.58905 11.7768 3.41699 10.9187 3.41699 9.99768C3.41699 9.00306 3.63468 8.0589 4.07005 7.1652C4.50542 6.27151 5.11144 5.5127 5.88814 4.88877L6.65416 5.65479C5.96826 6.17722 5.4378 6.8169 5.0628 7.57385C4.6878 8.33079 4.5003 9.13982 4.5003 10.0009C4.5003 11.5287 5.03676 12.8273 6.10966 13.8968C7.18258 14.9662 8.47792 15.5009 9.99568 15.5009C11.5265 15.5009 12.8267 14.9662 13.8961 13.8968C14.9656 12.8273 15.5003 11.5287 15.5003 10.0009C15.5003 9.142 15.3163 8.33005 14.9482 7.56508C14.5802 6.80009 14.0462 6.16333 13.3465 5.65479L14.1125 4.88877C14.9084 5.5127 15.5192 6.27013 15.945 7.16108C16.3707 8.05202 16.5836 8.99864 16.5836 10.0009C16.5836 10.9197 16.4109 11.7768 16.0655 12.5722C15.7201 13.3675 15.2504 14.063 14.6564 14.6585C14.0624 15.2541 13.3663 15.7239 12.568 16.0681C11.7697 16.4122 10.9138 16.5842 10.0003 16.5842Z"
            fill="currentColor"
        />
    </svg>
);
