import React, { memo, useMemo } from 'react';

import { UserModel } from 'modules/domain/users';
import { UserPanel } from 'modules/components/user/user-panel';
import { UserTag } from 'modules/api/dto';

export type Props = {
    id: string;
    attendeeId: string;
    animatorId: string;
};

export const Attendee = memo((props: Props) => {
    const { user } = UserModel.hooks.useUserSelectorsById(props.id);
    const userTags = useMemo(() => (user && user.tags) || [], [user]);
    const isCustomer = useMemo(() => userTags.some(tag => tag === UserTag.Customer), [userTags]);

    return <UserPanel {...props} {...user} me={false} isCustomer={isCustomer} />;
});
