import React, { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from 'modules/components/common/button/base';
import { AppRoutes } from 'modules/navigation';
import icon from 'images/icon-offline.svg';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

export function OfflineBanner() {
    const navigate = useNavigate();

    const handleClick = useCallback(() => {
        navigate(AppRoutes.Chat);
    }, [navigate]);

    return (
        <div {...testId('offline-banner')} className={styles.container}>
            <img {...testId('offline-banner-icon')} className={styles.icon} src={icon} alt="" />
            <h1 {...testId('offline-banner-title')} className={styles.title}>
                You are offline now
            </h1>
            <p {...testId('offline-banner-description')} className={styles.caption}>
                Go online to continue chat!
            </p>
            <Button
                {...testId('offline-banner-go-online-button')}
                type="button"
                size="lg"
                theme="green"
                onClick={handleClick}
            >
                Go Online
            </Button>
        </div>
    );
}
