import React, { memo, ReactNode, useState } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import classNames from 'classnames';

import { Image } from 'modules/components/image';
import fallbackImage from 'images/image.svg';
import { testId } from 'modules/utils/test-id';

import { CrossIcon } from '../icon';

import styles from './styles.module.scss';
import { useClickOverlay } from './use-click-overlay';

type Props = {
    children: ReactNode;
    className?: string;
    src?: string | string[];
    size?: number;
    selectedItem?: number;
};

export const ImageOverlay = memo((props: Props) => {
    const { children, className, src = '', size, selectedItem } = props;

    const [isHoverOverlayOpen, setHoverOverlayOpen] = useState(false);
    const { isClickOverlayOpen, openClickOverlay, closeClickOverlay } = useClickOverlay();

    const isMultipleSrc = Array.isArray(src);

    const renderOverlayImage = (imageSrc: string) => {
        return (
            <Image a11yLabel={`modal-image-slide-${imageSrc}`} fallback={fallbackImage} src={imageSrc} alt={imageSrc} />
        );
    };

    const renderClickOverlay = () => {
        return (
            isClickOverlayOpen && (
                <div {...testId('modal-image-wrapper')} className={styles.wrapper}>
                    <div
                        {...testId('modal-image-close-button')}
                        className={styles.closeBtn}
                        onClick={closeClickOverlay}
                    >
                        <CrossIcon />
                    </div>
                    <div className={styles.content} {...testId('modal-image-content')}>
                        {isMultipleSrc && src.length > 1 ? (
                            <Carousel
                                selectedItem={selectedItem}
                                width={size}
                                autoPlay={false}
                                autoFocus
                                useKeyboardArrows
                                className={styles.carousel}
                            >
                                {src.map(el => (
                                    <React.Fragment key={el}>{renderOverlayImage(el)}</React.Fragment>
                                ))}
                            </Carousel>
                        ) : (
                            <div {...testId('modal-image-content-simple')} className={styles.carousel}>
                                {renderOverlayImage(isMultipleSrc ? src[0] : src)}
                            </div>
                        )}
                    </div>
                </div>
            )
        );
    };

    const renderHoverOverlay = () => {
        const showHoverOverlay = isHoverOverlayOpen && !isClickOverlayOpen;

        return (
            showHoverOverlay && (
                <div {...testId('modal-image-wrapper')} className={styles.hoverOverlayWrapper}>
                    {renderOverlayImage(isMultipleSrc ? src[selectedItem || 0] : src)}
                </div>
            )
        );
    };

    return (
        <div className={classNames(styles.container, className)}>
            <div
                onClick={openClickOverlay}
                onMouseEnter={() => setHoverOverlayOpen(true)}
                onMouseLeave={() => setHoverOverlayOpen(false)}
            >
                {children}
            </div>
            {renderClickOverlay()}
            {renderHoverOverlay()}
        </div>
    );
});
