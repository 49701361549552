import React, { memo } from 'react';

import { DialogMessageMetaDto } from 'modules/api/dto';

import { AutoGeneratedMessageText } from './message-text';
import { checkAutoGeneratedMessageUsed } from './helpers';

type Props = {
    meta: DialogMessageMetaDto;
};

export const AutoGeneratedMessageInfo = memo((props: Props) => {
    const { meta } = props;

    if (meta.Auto) {
        return <AutoGeneratedMessageText text="This message has been generated and sent automatically" />;
    }

    if (checkAutoGeneratedMessageUsed(meta)) {
        return <AutoGeneratedMessageText text="This message has been generated and sent by choice" />;
    }

    return null;
});
