import { request } from 'modules/utils';
import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { GiftDto } from '../types';

export function fetchGifts(api: SDVLegacyAPI, userId: string): Promise<GiftDto[]> {
    return request(`${api.getBaseHost()}/gifts/config/${userId}`, {
        headers: {
            Authorization: api.authorize(),
            'content-type': 'application/json',
        },
    })
        .then(res => JSON.parse(res.response))
        .then(res => res.Gifts);
}
