import React from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
    testIdKey: string;
    title: string;
    content: string;
};

export const ContentItem = (props: Props) => {
    const { testIdKey, title, content } = props;

    return (
        <div {...testId(`user-tab-spotify-match-${testIdKey}`)} className={styles.content}>
            <div {...testId(`user-tab-spotify-match-${testIdKey}-title`)} className={styles.contentTitle}>
                {title}
            </div>
            <div {...testId(`user-tab-spotify-match-${testIdKey}-value`)} className={styles.contentValue}>
                {content}
            </div>
        </div>
    );
};
