import React, { useRef } from 'react';

import { ModalModel } from 'modules/domain/modals';
import { ComplaintOn, DialogProps, Report } from 'modules/domain/complaint/types';

import { SendComplaintForm } from './form';
import styles from './index.module.scss';
import { ComplaintsOnAnimator, ComplaintsOnClient } from './config';

const SendComplaint = () => {
    const { closeModal } = ModalModel.hooks.useModal();
    const { activeModalData } = ModalModel.hooks.useSelectors<DialogProps>();

    const reportType = useRef<Report>();

    if (activeModalData?.outgoing) {
        reportType.current = {
            complaintOn: ComplaintOn.Animator,
            complaints: ComplaintsOnAnimator,
        };
    } else {
        reportType.current = {
            complaintOn: ComplaintOn.Client,
            complaints: ComplaintsOnClient,
        };
    }

    if (!reportType) {
        return null;
    }

    return (
        <div>
            <div className={styles.content}>
                <div className={styles.title}>Are you sure?</div>
                <div className={styles.subtitle}>Message will be checked on prohibited content.</div>
                <SendComplaintForm reportType={reportType as React.MutableRefObject<Report>} onDismiss={closeModal} />
            </div>
        </div>
    );
};

export const SendComplaintModal = React.memo(SendComplaint);
