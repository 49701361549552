import React from 'react';
import classNames from 'classnames';

import styles from './styles.module.scss';

type Props = {
    accuracy: number;
};

export const AccuracyText = (props: Props) => {
    const { accuracy } = props;

    const formattedAccuracy = Math.floor(accuracy * 100);

    const isBadAccuracy = formattedAccuracy <= 30;

    return (
        <p className={styles.accuracyText}>
            Speech accuracy:{' '}
            <span className={classNames({ [styles.badAccuracyValue]: isBadAccuracy })}>{formattedAccuracy}%</span>
        </p>
    );
};
