import { useCallback } from 'react';

import { useWindowEventHandler } from 'modules/hooks';

export function useEscapePressHandler(handler: (event: KeyboardEvent) => void) {
    const keydownHandler = useCallback(
        (event: KeyboardEvent) => {
            if (event.code === 'Escape') {
                handler(event);
            }
        },
        [handler],
    );

    useWindowEventHandler('keydown', keydownHandler);
}
