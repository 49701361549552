import React, { memo, useCallback, useMemo } from 'react';

import { SearchFormValues } from './types';
import View from './view';
import { STORAGE_KEY_ANIMATOR, STORAGE_KEY_CLIENT } from './constants';

type Props = {
    onSubmit: (userId: string, animatorId: string) => void;
    onClickClose: () => void;
};

export const DialogsSearchForm = memo((props: Props) => {
    const { onSubmit, onClickClose } = props;
    const savedClientId = localStorage.getItem(STORAGE_KEY_CLIENT) || '';
    const savedAnimatorId = localStorage.getItem(STORAGE_KEY_ANIMATOR) || '';
    const initialFormState: SearchFormValues = useMemo(
        () => ({
            userId: savedClientId,
            animatorId: savedAnimatorId,
        }),
        [savedClientId, savedAnimatorId],
    );

    const validate = useCallback((values: SearchFormValues) => {
        const errors: Partial<SearchFormValues> = {};
        if (!values.userId) {
            errors.userId = 'Required';
        }

        if (!values.animatorId) {
            errors.animatorId = 'Required';
        }

        return errors;
    }, []);

    const handleSubmit = useCallback(
        async (values, { setSubmitting }) => {
            const { userId, animatorId } = values;

            onSubmit?.(String(userId), String(animatorId));

            setSubmitting(false);
        },
        [onSubmit],
    );

    return (
        <View
            initialFormState={initialFormState}
            validate={validate}
            handleSubmit={handleSubmit}
            onClickClose={onClickClose}
        />
    );
});
