import React, { useState } from 'react';
import cn from 'classnames';

import { ExtendedTimer } from 'modules/components/extended-timer';
import { RoundButton } from 'modules/components/common/button/round';
import { testId } from 'modules/utils/test-id';
import { SearchIcon } from 'modules/components/icon';
import { ChatSearchWidget } from 'modules/components/chat/header/search/widget';
import { DialogModel } from 'modules/domain/dialog';

import { ThreeDotsMenu } from './three-dots-menu';
import { EarningsBadges } from './earnings-badges';
import { CurrentSearchIndicator } from './current-search-indicator';
import styles from './index.module.css';

export function BadgesAndSearch() {
    const [messageSearchActive, setMessageSearchActive] = useState<boolean>(false);
    const { dialog, foundDialogId } = DialogModel.hooks.useSelectors();
    const chatSearchActive = !!foundDialogId;

    const isDefaultMode = !messageSearchActive && !chatSearchActive;
    const isChatSearchMode = !messageSearchActive && chatSearchActive;

    return (
        <div className={cn(styles.centerHeader, { [styles.messageSearchMode]: messageSearchActive })}>
            {messageSearchActive && (
                <ChatSearchWidget dialogId={dialog?.id || ''} onClose={() => setMessageSearchActive(false)} />
            )}
            {isDefaultMode && (
                <>
                    <div className={styles.scales}>
                        <EarningsBadges />
                        <ExtendedTimer />
                    </div>
                    <div className={styles.controls}>
                        <RoundButton
                            {...testId('in-chat-search-button')}
                            size="md"
                            theme="darker"
                            onClick={() => setMessageSearchActive(true)}
                        >
                            <SearchIcon size={12} />
                        </RoundButton>
                        <ThreeDotsMenu />
                    </div>
                </>
            )}
            {isChatSearchMode && (
                <CurrentSearchIndicator animatorId={dialog?.animatorId} attendeeId={dialog?.attendeeId} />
            )}
        </div>
    );
}
