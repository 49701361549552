import { RootState } from 'modules/App/store/root-state';
import { EntityMap } from 'modules/domain/common/types';

import { Media, MediaState, ChatMediaEntity, ChatMedia, SentMedia } from './types';
import { namespace } from './actions';

const getMediaModelState = (state: RootState): MediaState<Media[]> => state[namespace] || {};
const getMediaMap = (state: RootState): EntityMap<Media[]> => getMediaModelState(state).entities.byId || {};
const getChatMediaMap = (state: RootState): EntityMap<ChatMediaEntity> =>
    getMediaModelState(state).chatEntities.byId || {};

const getMedia = (state: any, id: string): Media[] => {
    return getMediaMap(state)[id];
};
const getChatMedia = (state: any, id: string): ChatMedia[] => {
    return getChatMediaMap(state)[id].list;
};
const getChatSentMedia = (state: any, id: string): SentMedia[] => {
    return getChatMediaMap(state)[id]?.sentMedia ?? [];
};
const isChatMediaEnabled = (state: any, id: string): boolean | undefined => {
    const chatMediaUserState = getChatMediaMap(state)[id] || {};

    return chatMediaUserState.enabled;
};
const isChatMediaVisible = (state: any, id: string): boolean | undefined => {
    const chatMediaUserState = getChatMediaMap(state)[id] || {};

    return chatMediaUserState.enabled && chatMediaUserState.list.length > 0;
};
const isChatMediaAvailable = (state: any, id: string): boolean | undefined => {
    const chatMediaUserState = getChatMediaMap(state)[id] || {};

    return !!chatMediaUserState.available;
};

export const selectors = {
    getMedia,
    getChatMedia,
    getChatSentMedia,
    isChatMediaEnabled,
    isChatMediaVisible,
    isChatMediaAvailable,
};
