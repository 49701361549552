import { useCallback, useEffect } from 'react';

import { isDigitKey } from './isDigitKey';

type MainShortcutKey = 'ctrlKey' | 'altKey';

export const useShortcutListener = (callback: (keyNumber: number) => void, mainShortcutKey: MainShortcutKey) => {
    const handleKeyPress = useCallback(
        (event: KeyboardEvent) => {
            /*
             * it is kinda harder than expected
             * because event.key yields unexpected results on macbook
             * when used in shortcut with macbook option key
             * */
            if (event[mainShortcutKey] && isDigitKey(event.code)) {
                const digit = Number(event.code[event.code.length - 1]);
                event.preventDefault();
                callback(digit);
            }
        },
        [callback, mainShortcutKey],
    );

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => document.removeEventListener('keydown', handleKeyPress);
    }, [handleKeyPress]);

    return null;
};
