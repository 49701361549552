import React, { memo, useCallback } from 'react';

import { ModalModel } from 'modules/domain/modals';
import { ModalNames } from 'modules/domain/modals/types';
import { FastAnswerIcon } from 'modules/components/icon';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
    variant: 'default' | 'extended';
};

export const FastAnswersFillingButton = memo((props: Props) => {
    const { variant } = props;
    const { openModal: openModalBase } = ModalModel.hooks.useModal();

    const openModal = useCallback(() => {
        openModalBase(ModalNames.FastAnswers);
    }, [openModalBase]);

    return (
        <button {...testId('add-template-button')} type="button" className={styles.container} onClick={openModal}>
            <FastAnswerIcon />
            {variant === 'extended' && <span>Click here to add Templates</span>}
        </button>
    );
});
