import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';
import { cloneDeep, setWith } from 'lodash';

import { ModelState, MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';

import { AnimatorAliasesState } from './types';
import {
    AnimatorLocationActions,
    FetchAnimatorLocationPayload,
    FetchFailureAnimatorLocationPayload,
    FetchSuccessAnimatorLocationPayload,
    namespace,
} from './actions';

interface CustomReducerMap extends ReducerMap<ModelState<AnimatorAliasesState>, any> {
    [AnimatorLocationActions.FetchStart]: ReducerMapValue<
        ModelState<AnimatorAliasesState>,
        FetchAnimatorLocationPayload
    >;
    [AnimatorLocationActions.FetchSuccess]: ReducerMapValue<
        ModelState<AnimatorAliasesState>,
        FetchSuccessAnimatorLocationPayload
    >;
    [AnimatorLocationActions.FetchFailure]: ReducerMapValue<
        ModelState<AnimatorAliasesState>,
        FetchFailureAnimatorLocationPayload
    >;
}

const initialState = {
    entities: { byId: {} },
};

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [AnimatorLocationActions.FetchStart]: (state, { payload }) =>
        setWith(
            { ...cloneDeep(state) },
            ['entities', 'byId', payload.animatorId, 'byAttendeeId', payload.attendeeId, 'pending'],
            true,
            Object,
        ),
    [AnimatorLocationActions.FetchSuccess]: (state, { payload }) =>
        update(state, {
            entities: {
                byId: {
                    [payload.animatorId]: {
                        byAttendeeId: {
                            [payload.attendeeId]: {
                                pending: { $set: false },
                                entity: { $set: payload.result },
                            },
                        },
                    },
                },
            },
        }),
    [AnimatorLocationActions.FetchFailure]: (state, { payload }) =>
        update(state, {
            entities: {
                byId: {
                    [payload.animatorId]: {
                        byAttendeeId: {
                            [payload.attendeeId]: {
                                pending: { $set: false },
                                error: { $set: payload.error },
                            },
                        },
                    },
                },
            },
        }),
};

export const reducer: MultiChatReducer<ModelState<AnimatorAliasesState>, any> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
