import React, { useEffect, useState, memo } from 'react';
import Lottie from 'lottie-react';

import { Loader } from 'modules/components/loader';
import { request } from 'modules/utils/request';
import { useConfig } from 'modules/config';

import styles from './index.module.css';

type Props = {
    animationPath: string;
};

export const AnimatedGift = memo((props: Props) => {
    const { animationPath } = props;
    const config = useConfig();
    const [animationData, setAnimationData] = useState(null);
    useEffect(() => {
        request(`//${config.api}${animationPath}`).then(result => setAnimationData(result.response));
    }, [animationPath, config.api]);

    if (animationData === null) {
        return (
            <div className={styles.container}>
                <Loader />
            </div>
        );
    }
    return (
        <div className={styles.container}>
            <Lottie animationData={JSON.parse(animationData)} />
        </div>
    );
});
