import { useCallback } from 'react';
import { batch, useDispatch } from 'react-redux';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useLogger } from 'modules/utils';

import { setDictionary } from '../actions';
import { DictionaryOptions } from '../types';
import { getDictionary } from '../api';

const dictionariesList = Object.values(DictionaryOptions);

export const useDictionaryApi = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { logError } = useLogger('useDictionaryApi');

    // get animator language somehow
    const fetchAllDictionaries = useCallback(
        async (language: string) => {
            try {
                const requests = dictionariesList.map(dictionaryName => getDictionary(api, dictionaryName, language));

                const responses = await Promise.all(requests);

                batch(() => {
                    dictionariesList.forEach((dictionaryName, index) =>
                        dispatch(setDictionary(dictionaryName, responses[index])),
                    );
                });
            } catch (error) {
                logError('fetchAllDictionaries error', { language, error });
            }
        },
        [dispatch, api, logError],
    );

    return {
        fetchAllDictionaries,
    };
};
