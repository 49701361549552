import React, { useCallback, useEffect } from 'react';
import useWebSocket from 'react-use-websocket';
import { useSelector } from 'react-redux';

import { getSocketShards } from 'modules/domain/sharding/getSocketShards';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useDialogApi } from 'modules/domain/dialog/hooks';
import { RootState } from 'modules/App/store/root-state';
import { getCurrentDialogIsForceLocked } from 'modules/domain/dialog/selectors';
import { useConfig } from 'modules/config';

type Props = {
    operatorId: string;
    animatorId?: string;
    attendeeId?: string;
};

export const DialogAutoSwitcher = (props: Props) => {
    const { operatorId, animatorId, attendeeId } = props;
    const { api } = useAuthApi();
    const appConfig = useConfig();
    const { fetchDialog } = useDialogApi();
    // if dialog switched from the other sources;
    const switchFromCurrentDialog = useSelector<RootState, boolean>(state => getCurrentDialogIsForceLocked(state));

    const getSocketUrl = useCallback(async () => {
        const res = await getSocketShards(api, operatorId);
        return `wss://rt${res.shard}.${appConfig.sharding.host}/${res.key}`;
    }, [operatorId, api, appConfig.sharding.host]);

    const { lastMessage } = useWebSocket(getSocketUrl, {
        shouldReconnect: () => true,
    });

    useEffect(() => {
        if (lastMessage === null) {
            return;
        }
        const messageData = JSON.parse(lastMessage?.data);
        const justLockedChatAnimatorId = messageData?.details?.animatorId;
        const justLockedChatClientId = messageData?.details?.clientId;
        if (
            attendeeId &&
            animatorId &&
            justLockedChatClientId === attendeeId &&
            justLockedChatAnimatorId === animatorId
        ) {
            fetchDialog(operatorId);
        }
    }, [animatorId, attendeeId, lastMessage, operatorId, fetchDialog]);

    useEffect(() => {
        if (switchFromCurrentDialog) {
            fetchDialog(operatorId);
        }
    }, [switchFromCurrentDialog, fetchDialog, operatorId]);
    return <></>;
};
