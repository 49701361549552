import { reducer } from './reducer';
import { actions } from './actions';
import { selectors } from './selectors';
import { useDictionaryApi } from './hooks';

export const DictionaryModel = {
    reducer,
    actions,
    selectors,
    hooks: {
        useDictionaryApi,
    },
};

export const DictionaryTribes = {
    bi: 'Bi',
    'bi-curious': 'Bi-curious',
    daddy: 'Daddy',
    discreet: 'Discreet',
    fem: 'Fem',
    gay: 'Gay',
    jock: 'Jock',
    leather: 'Leather',
    masc: 'Masc',
    'non-binary': 'Non-binary',
    straight: 'Straight',
    student: 'Student',
    trans: 'Trans',
};
