import React, { memo } from 'react';

import { testId } from 'modules/utils/test-id';
import { UserModel } from 'modules/domain/users';
import { UserTag } from 'modules/api/dto';

import styles from './index.module.scss';

type Props = {
    attendeeId: string;
};

export const VipCaption = memo((props: Props) => {
    const { attendeeId } = props;

    const { user: attendee } = UserModel.hooks.useUserSelectorsById(attendeeId);

    const isVip = attendee.tags?.includes(UserTag.CreditsAccountsVip);

    if (!isVip) {
        return null;
    }

    return (
        <div {...testId('vip-client-label')} className={styles.container}>
            <p className={styles.content}>You&apos;re communicating with a VIP client. Be creative!</p>
        </div>
    );
});
