import { getUrl } from 'modules/utils';
import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { MatchResponseError, MatchType } from './types';

const requestsResolver = (promises: Promise<any>[]) =>
    Promise.all(promises).then(responses => Promise.all(responses.map(res => res.json())));

export const getFetchMatchController = (api: SDVLegacyAPI, userId: string, attendeeId: string) => {
    const controller = new AbortController();
    const signal = controller.signal;

    const load = async (): Promise<MatchType | MatchResponseError | undefined> => {
        const headers = {
            authorization: api.authorize(),
            'content-type': 'application/json',
        };

        try {
            const [scoreRes, topContentRes] = await requestsResolver([
                fetch(getUrl(api, `/exo/phyllo/${userId}/${attendeeId}/matchscore/spotify`), {
                    headers,
                    signal,
                }),
                fetch(getUrl(api, `/exo/phyllo/top-content/${attendeeId}/spotify`), {
                    headers,
                    signal,
                }),
            ]);

            if (scoreRes.error) {
                return { error: scoreRes.error };
            }

            const { top } = topContentRes || {};

            return {
                score: scoreRes.score,
                level: scoreRes.level,
                artists: top?.artists,
                genres: top?.genres,
                tracks: top?.tracks,
            };
        } catch (error: unknown) {
            return { error: 'Unknown error' };
        }
    };

    return {
        load,
        unsubscribe: () => controller.abort(),
    };
};
