import React, { memo, useCallback } from 'react';

import { FastAnswersFormErrorValues, FastAnswersFormValues } from './types';
import View from './view';

type Props = {
    initialValues: any;
    onSubmit: (values: FastAnswersFormValues) => void;
    onCancel: () => void;
};

export const FastAnswerModalForm = memo((props: Props) => {
    const { initialValues, onSubmit, onCancel } = props;

    const handleSubmit = useCallback(
        async (values: FastAnswersFormValues, { setSubmitting }) => {
            const prettyValues = Object.keys(values).reduce((acc, key) => {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                return { ...acc, [key]: values[key].trim() };
            }, {} as FastAnswersFormValues);

            onSubmit(prettyValues);
            setSubmitting(false);
        },
        [onSubmit],
    );

    const validate = useCallback((values: FastAnswersFormValues) => {
        const errors: Partial<FastAnswersFormErrorValues> = Object.keys(values).reduce((acc, key) => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const value = values[key].replace(/\s\s+/g, ' ').trim();
            if (value.length > 0 && value.length <= 20) {
                return { ...acc, [key]: 'Min 20 symbols' };
            }

            return acc;
        }, {} as FastAnswersFormValues);

        return errors;
    }, []);

    return (
        <View
            {...props}
            initialFormState={initialValues as FastAnswersFormValues}
            validate={validate}
            handleSubmit={handleSubmit}
            onDismiss={onCancel}
        />
    );
});
