import { useState, useCallback, useEffect, useMemo } from 'react';

import { useConfig } from 'modules/config';

type Activity = 'active' | 'inactive';

const MAX_INACTIVITY_TIME_DEFAULT = 15 * 60 * 1000; // 15 min

export const useClientInactivityTime = () => {
    const [activity, setActivity] = useState<Activity>('active');
    const [resetValue, setResetValue] = useState({});
    const appConfig = useConfig();

    // todo I can leave the default value in the config
    // but I need to figure out when config does not exist
    const maxInactivityTime = useMemo(() => {
        return appConfig?.maxInactivityTime || MAX_INACTIVITY_TIME_DEFAULT;
    }, [appConfig]);

    const handleEvent = useCallback(() => {
        setActivity('active');
        setResetValue({});
    }, []);

    const setIncativeStatus = useCallback(() => setActivity('inactive'), []);

    useEffect(() => {
        const timer = setTimeout(setIncativeStatus, maxInactivityTime);

        return () => clearTimeout(timer);
    }, [maxInactivityTime, resetValue, setIncativeStatus]);

    useEffect(() => {
        document.addEventListener('keydown', handleEvent);
        document.addEventListener('mousemove', handleEvent);
        return () => {
            document.removeEventListener('keydown', handleEvent);
            document.removeEventListener('mousemove', handleEvent);
        };
    }, [handleEvent]);

    return activity;
};
