import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { UserModel } from 'modules/domain/users';
import { testId } from 'modules/utils/test-id';

import { UserTabStoryOverlay } from '../user-tab-story-overlay';

import styles from './index.module.scss';

type Props = {
    ownerId: string;
};

export const UserTabStory = (props: Props) => {
    const { ownerId } = props;
    const {
        populatedUser: { info: userInfo },
    } = UserModel.hooks.useUserSelectorsById(ownerId);

    const story = userInfo?.lifeStory;

    return (
        <div data-tooltip-id={'story-tooltip'}>
            <div {...testId(`user-tab-story-${ownerId}`)} className={styles.container}>
                <div className={styles.content}>{story}</div>
            </div>
            <UserTabStoryOverlay />
            <ReactTooltip id="story-tooltip" variant="light" noArrow={true} place={'left-start'}>
                <div className={styles.tooltip}>
                    <div className={styles.tooltipTitle}> This is the life-story you can share with client.</div>
                    <div className={styles.tooltipContent}>
                        If you have already discussed it, check the box below. Once you check the box the other operator
                        will note not to tell the story to this client again.
                    </div>
                </div>
            </ReactTooltip>
        </div>
    );
};
