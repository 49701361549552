import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';

import { DatesRange } from 'modules/domain/earnings/types';
import { getDatesRangeMap } from 'modules/domain/earnings/constants';
import { TooltipMenu } from 'modules/components/tooltip-menu';
import { testId } from 'modules/utils/test-id';
import { useStatsContext } from 'modules/components/stats-page/context';
import { AppRoutes } from 'modules/navigation';

import { ThisWeekIcon, LastWeekIcon, ThisMonthIcon } from './icons';
import styles from './index.module.css';

const icons: { [key in DatesRange]: React.ReactNode | null } = {
    [DatesRange.ThisWeek]: <ThisWeekIcon />,
    [DatesRange.LastWeek]: <LastWeekIcon />,
    [DatesRange.ThisMonth]: <ThisMonthIcon />,
    [DatesRange.LastMonth]: null,
};

const dateRanges = getDatesRangeMap();

const KPI_RANGES: DatesRange[] = [DatesRange.ThisWeek, DatesRange.LastWeek];
const INCOME_RANGES: DatesRange[] = [DatesRange.ThisWeek, DatesRange.LastWeek, DatesRange.ThisMonth];

export const DateRangesSwitcher = memo(() => {
    const { selectedRange, onRangeChange } = useStatsContext();
    const { pathname } = useLocation();

    const { title: selectedRangeTitle } = dateRanges[selectedRange];

    const isKPIPage = pathname === AppRoutes.Kpi;
    const allowedRanges = isKPIPage ? KPI_RANGES : INCOME_RANGES;

    return (
        <TooltipMenu
            options={allowedRanges.map(range => {
                const { id, title } = dateRanges[range];

                return {
                    icon: icons[id],
                    caption: title,
                    onClick: () => {
                        onRangeChange(range);
                    },
                    testIdPrefix: `date-ranges-switcher-${title.toLowerCase().split(' ').join('-')}`,
                };
            })}
            trigger={
                <div className={styles.rangePickerContainer} tabIndex={-1}>
                    <div {...testId('date-ranges-switcher-selected-range')}>{selectedRangeTitle}</div>
                </div>
            }
            position="bottom-right"
        />
    );
});
