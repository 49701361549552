import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';

import { App, configureAppStore } from './modules/App';
import { AppContextProvider, useAppContextInitializer } from './modules/domain/app-context';
import { init as initDatadog } from './modules/utils/datadog';
import { init as initSentry } from './services/sentry';
import './index.scss';

initSentry();
initDatadog();

const history = createBrowserHistory();
const { store, persistor } = configureAppStore(history, {});
const rootEl = document.getElementById('root');

const Root = () => {
    const appContext = useAppContextInitializer();

    if (!appContext) {
        return null;
    }

    return (
        <AppContextProvider value={appContext}>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <App />
                </PersistGate>
            </Provider>
        </AppContextProvider>
    );
};

ReactDOM.render(<Root />, rootEl);
