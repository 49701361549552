import { useCallback } from 'react';

import { useConfig } from 'modules/config';

import { create as createOriginal } from './api-create';
import { getApiInstance as getApiInstanceOriginal } from './api-instance';

export const useApi = () => {
    // Selectors
    const appConfig = useConfig();

    const appConfigApi = appConfig.api;

    // Callbacks
    const create = useCallback(() => {
        return createOriginal(appConfigApi);
    }, [appConfigApi]);

    const getApiInstance = useCallback(
        (id: string, token: string) => {
            return getApiInstanceOriginal(id, token, appConfigApi);
        },
        [appConfigApi],
    );

    return {
        create,
        getApiInstance,
    };
};
