import { useAsyncFn } from 'react-use';

import { useAuthApi } from 'modules/api-old/use-auth-api';

import { getUserTargetedTags } from '../../api';

export const useUserTargetedTagsApi = () => {
    const { api } = useAuthApi();

    const [targetedTagsState, fetchUserTargetedTags] = useAsyncFn(
        async (holderId: string, targetId: string) => {
            return getUserTargetedTags(api, holderId, targetId);
        },
        [],
        { loading: true },
    );

    return {
        targetedTagsState,
        fetchUserTargetedTags,
    };
};
