import React, { ChangeEvent, KeyboardEvent, memo, useMemo, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';

import { ImprovedNoteValue } from 'modules/domain/dialog/types';

import { focusOnElement, sectionTitles } from './utils';
import styles from './index.module.scss';

type Props = {
    defaultValues: Record<string, ImprovedNoteValue[]>;
    onSave: (values: Record<string, ImprovedNoteValue[]>) => void;
};

interface NoteDiv extends HTMLDivElement {
    dataset: {
        id: string;
    };
}

const NOTES_LIMIT = 10;

export const UserTabNotesImprovedView = memo((props: Props) => {
    const { defaultValues, onSave } = props;

    const cachedValues = useRef(cloneDeep(defaultValues));
    const [, refresh] = useState<Record<string, ImprovedNoteValue[]>>(cachedValues.current);
    const containerRef = useRef<HTMLDivElement>(null);
    const titlesObj = useMemo(() => {
        return Object.entries(sectionTitles);
    }, []);

    const save = () => {
        onSave(cachedValues.current);
    };

    const onInput = (event: ChangeEvent<NoteDiv>) => {
        const [noteId, noteIndex] = event.target.dataset.id.split('-');

        const newNoteValue: ImprovedNoteValue = {
            text: (event.target.innerText || '').trim(),
            isAutoChanged: false,
        };

        const currentNotes = cachedValues.current;

        cachedValues.current = {
            ...currentNotes,
            [noteId]: currentNotes[noteId].map((currentNote, currentIndex) =>
                /*
                 * todo the logic here is unnecessary obscure, it might be rewritten easier
                 * */
                currentIndex === +noteIndex ? newNoteValue : currentNote,
            ),
        };
    };

    const onKeyDown = (notesKey: string, index: number) => (event: KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            const currentNotes = cachedValues.current;
            const EMPTY_NOTE_VALUE: ImprovedNoteValue = {
                text: '',
                isAutoChanged: false,
            };

            const isEmpty =
                !currentNotes[notesKey] || !(currentNotes[notesKey][index] || EMPTY_NOTE_VALUE).text.trim().length;

            if (isEmpty || (currentNotes[notesKey] && currentNotes[notesKey].length === NOTES_LIMIT)) {
                return;
            }

            refresh(() => {
                const NEW_NOTE: ImprovedNoteValue = {
                    text: '',
                    isAutoChanged: false,
                };
                const values = cachedValues.current;
                const newIndex = values[notesKey].push(NEW_NOTE) - 1;

                cachedValues.current = { ...values };
                focusOnElement(containerRef.current, notesKey, newIndex);

                return {};
            });
            return false;
        }
    };

    const onBlur = (key: string) => (event: React.FocusEvent<HTMLDivElement>) => {
        const text = (event.currentTarget.innerText || '').trim();

        if (!text.length) {
            refresh(() => {
                const currentNotes = cachedValues.current;

                const newNoteValue = {
                    ...currentNotes,
                    [key]: currentNotes[key].filter(note => note !== null && !!note.text.length),
                };

                cachedValues.current = newNoteValue;

                save();
                return {};
            });
        } else {
            save();
        }
    };

    const onSectionHeaderClick = (key: string) => () => {
        const notes = cachedValues.current;
        if (notes[key] && notes[key] !== null && notes[key].length === NOTES_LIMIT) {
            return;
        }

        refresh(() => {
            const EMPTY_NOTE_VALUE: ImprovedNoteValue = {
                text: '',
                isAutoChanged: false,
            };

            const values = cachedValues.current;
            values[key] = values[key] || [];

            const index = values[key].push(EMPTY_NOTE_VALUE) - 1;
            cachedValues.current = { ...values };

            focusOnElement(containerRef.current, key, index);

            return {};
        });
    };

    return (
        <div ref={containerRef} className={styles.container}>
            {titlesObj.map(([key, title]) => (
                <React.Fragment key={key}>
                    <div className={styles.editableTitle} onClick={onSectionHeaderClick(key)}>
                        {title}
                    </div>
                    <div key={key} className={styles.editableContainer} data-id={key} tabIndex={0}>
                        {(cachedValues.current[key] || []).map((currentCachedNote, index) => {
                            return (
                                <div
                                    className={styles.noteRow}
                                    key={index /*TODO notes can be removed. It is most likely buggy*/}
                                >
                                    {currentCachedNote.isAutoChanged ? (
                                        <div
                                            className={styles.autoMark}
                                            onClick={
                                                /* todo there should be some pure-html way to do it */
                                                () => focusOnElement(containerRef.current, key, index)
                                            }
                                        >
                                            🤖
                                        </div>
                                    ) : null}
                                    <div
                                        data-id={`${key}-${index}`}
                                        className={styles.note}
                                        contentEditable
                                        onInput={onInput}
                                        onKeyDown={onKeyDown(key, index)}
                                        onBlur={onBlur(key)}
                                        dangerouslySetInnerHTML={{
                                            /*TODO stored xss here*/
                                            __html: currentCachedNote.text,
                                        }}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </React.Fragment>
            ))}
        </div>
    );
});
