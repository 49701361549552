import React from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';
import { FileInput } from 'modules/components/file-input';

import styles from './index.module.css';

export type MenuItemProps = {
    icon: React.ReactNode;
    caption: string | React.ReactNode;
    onClick?: () => void;
    dangerous?: boolean;
    testIdPrefix?: string;
    onChange?: (files: File[]) => void;
    fileInput?: boolean;
    doNotClose?: boolean;
};

export const MenuItem = (props: MenuItemProps) => {
    const { icon, caption, onClick, dangerous, testIdPrefix, onChange, fileInput } = props;

    if (fileInput && onChange) {
        return (
            <FileInput
                accept={['image/*']}
                // TODO add maxSize prop to image picker
                // maxSize={}
                maxFiles={1}
                onChange={onChange}
            >
                <div
                    className={cn(styles.menuItem, { [styles.dangerousItem]: dangerous })}
                    onClick={onClick}
                    {...testId(testIdPrefix || 'some-menu-item')}
                >
                    {icon ? <div className={styles.icon}>{icon}</div> : null}
                    <div>{caption}</div>
                </div>
            </FileInput>
        );
    }

    return (
        <div
            className={cn(styles.menuItem, { [styles.dangerousItem]: dangerous })}
            onClick={onClick}
            {...testId(testIdPrefix || 'some-menu-item')}
        >
            {icon ? <div className={styles.icon}>{icon}</div> : null}
            <div>{caption}</div>
        </div>
    );
};
