import { merge } from 'lodash';

import commonConfig from './config.json';
import { getEnv, MultichatEnv } from './get-env';

export type ClientConfig = typeof commonConfig & {
    env: MultichatEnv;
};

export const clientConfig = merge({}, commonConfig, {
    env: getEnv(),
});
