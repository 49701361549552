import React from 'react';

type Props = { size?: number };

export const SearchIcon = (props: Props) => {
    const { size = 16 } = props;

    return (
        <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="7.5" cy="7.5" r="5.5" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M14.3615 14.6666L11.3064 11.6171" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
        </svg>
    );
};
