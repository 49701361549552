import React from 'react';

export const CrossIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
        <path
            fillRule="evenodd"
            d="M16.066 7.934c.268.269.29.69.067.984l-.067.077-3.006 3.004 3.006 3.006c.293.293.293.768 0 1.06-.269.27-.69.292-.984.068l-.077-.067L12 13.06l-3.004 3.006c-.293.293-.768.293-1.06 0-.27-.269-.292-.69-.068-.984l.067-.077L10.938 12 7.934 8.995c-.293-.293-.293-.768 0-1.06.269-.27.69-.292.984-.068l.077.067 3.004 3.004 3.006-3.004c.293-.293.768-.293 1.06 0z"
        />
    </svg>
);
