import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update, { Spec } from 'immutability-helper';
import { cloneDeep, difference } from 'lodash';

import { MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';

import { setStateSpec } from '../utils/reducer';

import { User, UserModelState } from './types';
import {
    namespace,
    SetUserTagsPayload,
    SetUserExperimentsTagsPayload,
    UserActions,
    SetUserPayload,
    SetUserInterestsPayload,
    SetUserPreferencesPayload,
    SetUserLifeStoryPayload,
    SetUserOnboardingPayload,
    UserActionPayload,
} from './actions';

type CommonUserPayload = SetUserPayload &
    SetUserTagsPayload &
    SetUserExperimentsTagsPayload &
    SetUserInterestsPayload &
    SetUserLifeStoryPayload &
    SetUserPreferencesPayload &
    SetUserOnboardingPayload;

type UserReducerMapValue<Payload> = ReducerMapValue<UserModelState, Payload>;

interface CustomReducerMap extends ReducerMap<UserModelState, CommonUserPayload> {
    [UserActions.Set]: UserReducerMapValue<SetUserPayload>;
    [UserActions.SetTags]: UserReducerMapValue<SetUserTagsPayload>;
    [UserActions.SetExperimentsTags]: UserReducerMapValue<SetUserExperimentsTagsPayload>;
    [UserActions.SetInterests]: UserReducerMapValue<SetUserInterestsPayload>;
    [UserActions.SetLifeStory]: UserReducerMapValue<SetUserLifeStoryPayload>;
    [UserActions.SetPreferences]: UserReducerMapValue<SetUserPreferencesPayload>;
    [UserActions.SetOnboarding]: UserReducerMapValue<SetUserOnboardingPayload>;
}

const initialState: UserModelState = {
    entities: { byId: {} },
};

const setUserStateSpec = <P extends UserActionPayload<unknown>>(
    fn: (payload: P) => Spec<User>,
): UserReducerMapValue<P> =>
    setStateSpec(payload => ({
        entities: {
            byId: {
                [payload.id]: fn(payload),
            },
        },
    }));

const updateUserState = <P extends UserActionPayload<unknown>>(
    fn: (user: User, payload: P) => User,
): UserReducerMapValue<P> =>
    setUserStateSpec(payload => ({
        $apply: user => {
            if (user) {
                const userCopy = cloneDeep(user);
                return fn(userCopy, payload);
            }
            return user;
        },
    }));

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [UserActions.Set]: setUserStateSpec(payload => ({ $set: payload })),
    [UserActions.SetTags]: updateUserState((user, payload) => {
        const diff = difference(payload.input, user?.tags);
        user.tags = user.tags.concat(diff);
        return user;
    }),
    [UserActions.SetExperimentsTags]: updateUserState((user, payload) => {
        const diff = difference(payload.input, user?.experiments);
        user.experiments = user.experiments.concat(diff);
        return user;
    }),
    [UserActions.SetInterests]: updateUserState((user, payload) => {
        user.info.interests = payload.input;
        return user;
    }),
    [UserActions.SetLifeStory]: updateUserState((user, payload) => {
        user.info.lifeStory = payload.lifeStory;
        return user;
    }),
    [UserActions.SetPreferences]: updateUserState((user, payload) => {
        if (payload.input.about) {
            user.info.aboutMyPartner = payload.input.about;
        }
        if (payload.input['preferred-gender']) {
            user.preferredGender = payload.input['preferred-gender'];
        }
        return user;
    }),
    [UserActions.SetOnboarding]: updateUserState((user, payload) => {
        user.needOnboarding = payload.newStatus;
        return user;
    }),
};

export const reducer: MultiChatReducer<UserModelState, CommonUserPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
