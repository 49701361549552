import { useEffect } from 'react';

import { DatesRange } from '../types';

import { useEarningsApi } from './use-earnings-api';

export const useEarningsActualize = (range: DatesRange, withKPI?: boolean) => {
    const { fetchOperatorEarnings, fetchOperatorKPI } = useEarningsApi();

    useEffect(() => {
        fetchOperatorEarnings(range);
        if (withKPI) {
            fetchOperatorKPI(range);
        }
    }, [fetchOperatorEarnings, fetchOperatorKPI, range, withKPI]);
};
