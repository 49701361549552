import { AutoGeneratedAnswerOptionDto, AutoGeneratedAnswersDto } from 'modules/api/dto';

import { CopilotOption, CopilotState } from '../types';

const mapOptionDtoToCopilotOption = (dto: AutoGeneratedAnswerOptionDto, index: number): CopilotOption => ({
    id: `${dto.name}_${dto.type}_${Date.now()}_${index}`,
    text: dto.name,
    type: dto.type,
});

export const mapAutoGeneratedAnswersDtoToCopilotState = (dto: AutoGeneratedAnswersDto): CopilotState => ({
    botName: dto.botName,
    options: dto.options.map(mapOptionDtoToCopilotOption),
});
