import { useCallback, useEffect, useState } from 'react';
import { batch, useDispatch } from 'react-redux';

import { HttpError, useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';
import { mapDialogsDtoToDialogs, mapDialogsDtoToMetrics } from '../adapter/dialog-event-adapter';

import { useSelectors } from './use-selectors';

export const useDialogApi = () => {
    // Hooks
    const dispatch = useDispatch();
    const { dialogs: dialogsApi } = useApi();
    const { logError } = useLogger('useDialogApi');

    // Selectors
    const { sortedDialogIdList } = useSelectors();

    // State
    const [newEventReceived, setNewEventReceived] = useState<boolean>(false);

    /*
     * TODO Refactor it. At least two days wasted here
     *  As we manage the switching dialog through local state we can't rerender the component
     *  which uses "fetchDialog".
     *  The information we should switch it will be lost otherwise
     * */
    useEffect(() => {
        if (newEventReceived) {
            dispatch(actions.switchDialog(sortedDialogIdList[0]));
            setNewEventReceived(false);
        }
    }, [dispatch, newEventReceived, sortedDialogIdList]);

    const fetchDialog = useCallback(
        async (operatorId: string, currentServerDialogId?: number) => {
            try {
                const dialogsDto = await dialogsApi.getOperatorDialogs(operatorId, {
                    exceptDialogId: currentServerDialogId,
                });

                const dialogs = mapDialogsDtoToDialogs(dialogsDto);

                if (!dialogs) {
                    dispatch(actions.apiRequestListEmpty());
                    return;
                }
                const metrics = mapDialogsDtoToMetrics(dialogsDto);

                batch(() => {
                    dispatch(actions.apiRequestSuccessDialogList(dialogs, false));
                    dispatch(actions.setDialogsMetrics(metrics));
                });

                setNewEventReceived(true);
            } catch (err: unknown) {
                const error = err as HttpError;
                logError(`fetchDialog error. operatorId: ${operatorId}`, { error });

                dispatch(actions.apiRequestFailureDialogList(error.message));
            }
        },
        [dispatch, dialogsApi, logError],
    );

    return {
        fetchDialog,
    };
};
