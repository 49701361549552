import { useCallback } from 'react';

import { HttpError, useApi, NetworkErrorStatus } from 'modules/api';
import { IdentityRole } from 'modules/api/dto';
import { useLogger } from 'modules/utils';
import { useAuth } from 'modules/domain/auth/use-auth';

import { SignInResult } from '../types';

export const ALLOWED_ROLE = IdentityRole.Animator;

export const useSignIn = () => {
    const { identity: identityApi } = useApi();
    const { setAuthInfo, clearAuthInfo } = useAuth();
    const { logError } = useLogger('login screen');

    const signIn = useCallback(
        async (email: string, password: string, remember: boolean): Promise<SignInResult> => {
            try {
                const { roles, id, token } = await identityApi.signIn(email, password);

                if (!roles.includes(ALLOWED_ROLE)) {
                    logError('Incorrect role', {
                        role: ALLOWED_ROLE,
                        userId: id,
                        roles: roles.join(',') || 'no roles',
                    });

                    return { success: false, error: 'incorrect-role' };
                }

                setAuthInfo(id, email, token, remember);

                return { success: true };
            } catch (error) {
                const httpError = error as HttpError;
                logError('Exception in login process', { error });

                clearAuthInfo();

                const isForbidden = [NetworkErrorStatus.NotFound, NetworkErrorStatus.Unauthorized].includes(
                    httpError.status as number,
                );

                return { success: false, error: isForbidden ? 'forbidden' : 'unknown' };
            }
        },
        [clearAuthInfo, setAuthInfo, identityApi, logError],
    );

    return signIn;
};
