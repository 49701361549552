import React, { memo } from 'react';

import { User as UserType } from 'modules/domain/users/types';
import { AccordionTabs } from 'modules/components/accordion-tabs';

// import { Header } from './header';
import { Labels } from './labels';
import styles from './index.module.scss';

export type UserProps = UserType & {
    isCustomer?: boolean;
    me: boolean;
    animatorId: string;
    attendeeId: string;
};

export const UserPanel = memo(function UserPanel(props: UserProps) {
    const { id, animatorId, me } = props;

    return (
        <div className={styles.container} key={id} data-id={id}>
            <div className={styles.userContainer}>
                {/*<Header {...props} />*/}
                <Labels {...props} />
            </div>
            <AccordionTabs ownerId={id} isAnimator={me} animatorId={animatorId} />
        </div>
    );
});
