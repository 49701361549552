export enum DialogState {
    AnsweredMessageNew = 'Answered',
    UnansweredMessageNew = 'Unanswered',
    UnansweredUnpaid = 'UnansweredUnpaid',
    Invitation = 'invitation',
}

export type DialogDto = {
    Id: number;
    Client: {
        Id: number;
        SiteId: string;
    };
    Animator: {
        Id: number;
        SiteId: string;
    };
    Operator: {
        Id: number;
        SiteId: string;
    };
    State: DialogState;
    Timestamp: string;
    ChatSpendings?: number;
    IsExplicit?: boolean;
    ExplicitPossibleForClient?: boolean;
    QueueTypeId: number;
    ChatStartTimestamp?: string;
};

type MetricsDto = {
    Unanswered: {
        Count: number;
    };
    UrgentUnanswered: {
        Count: number;
        Period: number;
    };
};

type DialogsDto = {
    Dialogs: DialogDto[];
    Realm: string;
};

export type DialogsMetricsDto = DialogsDto & MetricsDto;
