import React, { forwardRef, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type Props = ButtonHTMLAttributes<HTMLButtonElement>;

export const IconButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
    const { className, ...btnProps } = props;

    return <button ref={ref} className={classNames(styles.iconButton, className)} {...btnProps} />;
});
