export type Dictionary = {
    [key: string]: string;
};

export enum DictionaryOptions {
    Interests = 'interests',
    Educations = 'educations-v2',
    Languages = 'languages',
    Relationships = 'relationships',
    Smoke = 'smoke',
    Drinking = 'drinking-v2',
    BodyType = 'bodytype',
    Eyes = 'eyes',
    Hairs = 'hairs',
}

export type AllowedDictionaryLanguages = 'en' | 'fr' | 'de';
