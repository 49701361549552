import React, { memo, useCallback, useMemo } from 'react';
import cn from 'classnames';

import { DialogModel } from 'modules/domain/dialog';
import { Note } from 'modules/domain/dialog/types';

import styles from './index.module.scss';

export const UserTabStoryOverlay = memo(() => {
    const { dialogId } = DialogModel.hooks.useSelectors();
    const { saveDialogNotes } = DialogModel.hooks.useDialogNoteApi();
    const { notes } = DialogModel.hooks.useSelectorsById(dialogId);
    const storyTold = useMemo(() => notes.storyTold === 'true' || false, [notes]);

    const onCheckBoxClick = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.checked;

            const newNotes = { ...notes, storyTold: String(value) } as Note;

            saveDialogNotes(dialogId, newNotes).then();
        },
        [dialogId, notes, saveDialogNotes],
    );

    return (
        <div className={styles.container}>
            <label className={cn(styles.label, { [styles.labelChecked]: storyTold })}>
                <input
                    type="checkbox"
                    onChange={onCheckBoxClick}
                    checked={storyTold}
                    className={cn(storyTold ? styles.checked : null)}
                />
                <span>The story has been told</span>
            </label>
        </div>
    );
});
