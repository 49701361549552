/**
 * Создать и сохранить в кэш инстанс апи для конкретного пользователя
 */
import { create } from './api-create';

const ApiInstanceMap = new Map();

export function getApiInstance(id, token, appConfigApi) {
    if (!ApiInstanceMap.get(id)) {
        ApiInstanceMap.set(id, create(appConfigApi));
    }

    const apiInstance = ApiInstanceMap.get(id);

    apiInstance.variable('user-id', id);

    if (token) {
        apiInstance.authorize.token(token);
    }

    return apiInstance;
}
