import React, { memo } from 'react';
import { Field, Form, Formik } from 'formik';

import { Button } from 'modules/components/common/button/base';
import { TextInput } from 'modules/components/common/text-input';
import { testId } from 'modules/utils/test-id';

import { FastAnswersFormValues } from './types';
import { keysSet } from './config';
import styles from './index.module.scss';

type Props = {
    initialFormState: FastAnswersFormValues;
    validate: (values: FastAnswersFormValues) => void;
    handleSubmit: (values: FastAnswersFormValues, methods: any) => void;
    onDismiss: () => void;
};

export default memo((props: Props) => {
    const { initialFormState, validate, handleSubmit, onDismiss } = props;

    return (
        <div className={styles.container}>
            <Formik initialValues={initialFormState} validate={validate} onSubmit={handleSubmit}>
                {({ isSubmitting }) => (
                    <Form {...testId('templates-modal-fast-answers-container')}>
                        {keysSet.map(keyItem => {
                            return (
                                <div key={keyItem.key} className={styles.inputWrapper}>
                                    <span className={styles.inputLabel}>Ctrl + {keyItem.keyNumber}</span>
                                    <Field
                                        type="text"
                                        name={keyItem.key}
                                        placeholder="Type your message..."
                                        backgroundTheme="light"
                                        className={styles.input}
                                        component={TextInput}
                                    />
                                </div>
                            );
                        })}
                        <div className={styles.row}>
                            <Button
                                {...testId('templates-modal-cancel-button')}
                                type="button"
                                size="md"
                                disabled={isSubmitting}
                                onClick={onDismiss}
                            >
                                Cancel
                            </Button>
                            <Button
                                {...testId('templates-modal-save-button')}
                                type="submit"
                                size="md"
                                theme="green"
                                disabled={isSubmitting}
                            >
                                Save
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
});
