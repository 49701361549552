import { useCallback } from 'react';

import { useConfig } from 'modules/config';
import { DialogModel } from 'modules/domain/dialog';

export function checkHasStopPhrases(message: string, stopPhrases: string[]) {
    const findMessage = message.toLowerCase();

    return stopPhrases.some(stopPhrase => findMessage.includes(stopPhrase));
}

export const useHasForbiddenMessageCheck = (dialogId: string) => {
    const { stopPhrases } = useConfig();
    const { latestMessage } = DialogModel.hooks.useSelectorsById(dialogId);

    const checkHasForbiddenMessage = useCallback(
        (message: string) => {
            const isDuplicate = latestMessage?.content === message;
            const hasStopPhrases = checkHasStopPhrases(message, stopPhrases);

            return isDuplicate || hasStopPhrases;
        },
        [stopPhrases, latestMessage?.content],
    );

    return checkHasForbiddenMessage;
};
