export class ResponseTimeStorage {
    static STORAGE_KEY = 'extended-timer';

    static get = (userId: string): number[] | null => {
        const rawValue = localStorage.getItem(ResponseTimeStorage.STORAGE_KEY);

        if (typeof rawValue !== 'string') {
            return null;
        }

        const value = JSON.parse(rawValue);

        return value[userId];
    };

    static set = (userId: string, seconds: number): void => {
        const currentValue = ResponseTimeStorage.get(userId);
        const jsonValue = { [userId]: [seconds] };

        if (currentValue && currentValue.length) {
            jsonValue[userId].unshift(...currentValue);
        }
        const value = JSON.stringify(jsonValue);

        localStorage.setItem(ResponseTimeStorage.STORAGE_KEY, value);
    };
    static clear = () => {
        localStorage.removeItem(ResponseTimeStorage.STORAGE_KEY);
    };
}
