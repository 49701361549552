import { useCallback, useState } from 'react';

import { useEscapePressHandler } from './use-escape-press-handler';

type BaseEvent = Pick<Event, 'stopPropagation'>;

export function useClickOverlay() {
    const [isClickOverlayOpen, setIsClickOverlayOpen] = useState(false);

    const openClickOverlay = useCallback(() => {
        setIsClickOverlayOpen(true);
    }, []);

    const closeClickOverlay = useCallback((event: BaseEvent) => {
        event?.stopPropagation();
        setIsClickOverlayOpen(false);
    }, []);

    useEscapePressHandler(closeClickOverlay);

    return { isClickOverlayOpen, openClickOverlay, closeClickOverlay };
}
