import React, { memo } from 'react';
import cn from 'classnames';

import { UserModel } from 'modules/domain/users';
import { testId } from 'modules/utils';

import { AnimatorPreferences } from './animator-preferences/animator-preferences';
import { Avatar } from './avatar';
import { OccupationInfo } from './occupation-info';
import { useLocation } from './useLocation';
import styles from './index.module.css';

type Props = {
    attendeeId: string;
    animatorId: string;
    isAnimator: boolean;
};

export const UserHeader = memo((props: Props) => {
    const { animatorId, isAnimator, attendeeId } = props;
    const userId = isAnimator ? animatorId : attendeeId;
    const { user } = UserModel.hooks.useUserSelectorsById(userId);
    const { name, gender, thumbnail, parsedThumbnailForAvatar, age, occupation } = user;
    const nameAndAge = [name, age].filter(Boolean).join(', ');

    const location = useLocation(animatorId, attendeeId, isAnimator);

    return (
        <div className={cn(styles.userHeader, { [styles.male]: gender === 'mal', [styles.female]: gender === 'fem' })}>
            <div className={styles.generalInfo}>
                <Avatar
                    a11yLabel={`user-avatar-${userId}`}
                    thumbnail={thumbnail}
                    name={name}
                    id={userId}
                    gender={gender}
                    parsedThumbnailForAvatar={parsedThumbnailForAvatar}
                />
                <div className={styles.textInfo}>
                    <div className={styles.nameAndAge} {...testId(`user-name-and-age-${userId}`)}>
                        {nameAndAge}
                    </div>
                    <div className={styles.location} {...testId(`user-location-${userId}`)}>
                        {location || 'City is not specified'}
                    </div>
                    <OccupationInfo occupation={occupation} isAnimator={isAnimator} userId={userId} />
                </div>
            </div>
            <div className={styles.iconContainer}>{isAnimator && <AnimatorPreferences />}</div>
        </div>
    );
});
