import React, { memo } from 'react';

import { Button } from 'modules/components/common/button/base';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
    id: string;
    dialogId: string;
    url: string;
    disabled: boolean;
    onSelect: (dialogId: string, basename: string) => void;
};

export const MediaItem = memo((props: Props) => {
    const basename = /[^/]*$/.exec(props.id)?.[0] ?? '';

    return (
        <div className={styles.listItem} {...testId(`send-library-media-${basename}`)}>
            <img className={styles.photo} src={props.url} />
            <Button
                type="button"
                size="sm"
                theme="green"
                disabled={props.disabled}
                onClick={() => props.onSelect(props.dialogId, props.id)}
            >
                Send
            </Button>
        </div>
    );
});
