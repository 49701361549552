import pify from 'pify';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { FastAnswersDto } from './types';

export const getFastAnswers = (api: SDVLegacyAPI, operatorId: string) => {
    return pify(api.multichat.operators['fast-answers'].get)(operatorId) as Promise<FastAnswersDto>;
};

export const patchFastAnswers = (api: SDVLegacyAPI, operatorId: string, payload: any) => {
    return pify(api.multichat.operators['fast-answers'].patch)(operatorId, payload) as Promise<any>;
};
