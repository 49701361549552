import { RootState } from 'modules/App/store/root-state';

import { MatchState, MatchType, namespace } from './types';

export const getSpotifyMatchModelState = (state: RootState): MatchState => state[namespace];

export const getSpotifyMatch = (state: RootState): { [x: string]: MatchType } => {
    return getSpotifyMatchModelState(state)[namespace];
};

export const selectors = {
    getSpotifyMatch,
};
