import React, { memo, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'modules/navigation';

import { useClientInactivityTime } from './use-client-inactivity-time';

export const MajorPage = memo(props => {
    const { children } = props;

    const activityStatus = useClientInactivityTime();
    const navigate = useNavigate();

    useEffect(() => {
        if (activityStatus === 'inactive') {
            navigate(AppRoutes.Offline);
        }
    }, [navigate, activityStatus]);

    return <>{children}</>;
});
