import moment from 'moment';
import React, { useCallback } from 'react';
import cn from 'classnames';

import { useOnboarding } from 'modules/domain/users/hooks';
import { UserModel } from 'modules/domain/users';
import { useAuth } from 'modules/domain/auth/use-auth';
import { ModalModel } from 'modules/domain/modals';
import { ModalNames } from 'modules/domain/modals/types';

import styles from './index.module.css';
import { CloseIcon } from './close-icon';
import { moreThanAYearAgo } from './moreThanYearAgo';

type Props = {
    timestamp: number;
    attachOnboarding: boolean;
    animatorId: string;
    recipientId: string;
    onTop: boolean;
};

export const DayIndicator = (props: Props) => {
    const { timestamp, attachOnboarding, onTop, animatorId, recipientId } = props;
    const { finishOnboarding } = useOnboarding();
    const { auth: operator } = useAuth();
    const { openModal } = ModalModel.hooks.useModal();

    const onDayClick = useCallback(() => {
        if (moreThanAYearAgo(timestamp)) {
            return;
        }
        openModal(ModalNames.Calendar, {
            animatorId,
            recipientId,
            timestamp,
        });
        finishOnboarding();
    }, [timestamp, animatorId, recipientId, openModal, finishOnboarding]);

    const { user } = UserModel.hooks.useUserSelectorsById(operator.id || '');
    const onboardingStatus = user.needOnboarding;

    const onboardingMode = onboardingStatus;
    const timeString = moment(timestamp).calendar('', {
        sameDay: '[Today]',
        lastDay: '[Yesterday]',
        lastWeek: '[Last] dddd',
        sameElse: 'MMM D YYYY',
    });

    return (
        <div
            className={cn(styles.container, {
                [styles.onTop]: onTop,
                [styles.onboarding]: attachOnboarding && onboardingMode,
            })}
            onClick={onDayClick}
        >
            {timeString}
            {attachOnboarding && onboardingMode ? (
                <div className={styles.onboardingHint} onClick={event => event.stopPropagation()}>
                    Click on the dates to open the calendar and jump to a specific date!
                    <CloseIcon className={styles.closeButton} onClick={finishOnboarding} />
                </div>
            ) : null}
        </div>
    );
};
