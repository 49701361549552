export enum UserTag {
    Customer = 'customer',
    CreditsAccountsVip = 'credits.accounts.vip',
    SpotifyConnected = 'phyllo.spotify.connected',
}

export enum UserExperimentTag {
    DialogSearchByIdEnabled = 'search.enabled',
}

export enum UserTargetedTag {
    DialogsFirstChat = 'dialogs.first.chat',
}
