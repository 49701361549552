import { Link } from 'react-router-dom';
import React from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { testId } from 'modules/utils/test-id';
import { Loader } from 'modules/components/loader';

import styles from './index.module.css';

type Props = {
    caption: string;
    url: string | null;
    testIdPostfix?: string;
    loading?: boolean;
    value: string | React.ReactNode;
    tooltip?: string;
    tooltipId?: string;
};

export const HeaderScale = (props: Props) => {
    const { caption, url, testIdPostfix, loading, value, tooltip, tooltipId } = props;

    if (!url) {
        return (
            <div className={styles.container} {...testId(testIdPostfix || '')}>
                {loading ? (
                    <Loader />
                ) : (
                    <div data-tooltip-id={tooltipId} className={styles.scale}>
                        {' '}
                        {value}
                    </div>
                )}
                <div className={styles.caption}>{caption}</div>
                {tooltip ? (
                    <ReactTooltip id={tooltipId} variant="light" noArrow={true} place={'bottom-start'}>
                        <div className={styles.tooltip}>{tooltip}</div>
                    </ReactTooltip>
                ) : null}
            </div>
        );
    }

    return (
        <Link to={url} className={styles.container} {...testId(testIdPostfix || '')}>
            {loading ? <Loader /> : <div className={styles.scale}>{value}</div>}
            <div className={styles.caption}>{caption}</div>
        </Link>
    );
};
