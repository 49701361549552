import XMLHttpRequest from 'xhr2';

import { calculateProgress } from 'modules/api/utils';

import { stringify } from './query-string';

const addProgressListener = (object, listener) => {
    const handler = event => {
        listener(calculateProgress(event));

        if (!event.lengthComputable) {
            object.removeEventListener('progress', handler);
        }
    };
    object.addEventListener('progress', handler, false);
};

export function request(url, options = {}) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();

        const {
            params,
            method = 'get',
            headers = {},
            timeout,
            onUploadProgress,
            onDownloadProgress,
            responseType,
        } = options;

        if (params) {
            url += stringify(params);
        }

        xhr.open(method, url, true);

        for (const key in headers) {
            if (headers.hasOwnProperty(key)) {
                xhr.setRequestHeader(key, headers[key]);
            }
        }

        if (typeof timeout === 'number') {
            xhr.timeout = timeout;
        }
        xhr.onload = e => resolve(e.target);
        xhr.onerror = reject;
        xhr.ontimeout = reject;

        if (responseType) {
            xhr.responseType = responseType;
        }

        if (onDownloadProgress) {
            addProgressListener(xhr, onDownloadProgress);
        }

        if (xhr.upload && onUploadProgress) {
            addProgressListener(xhr.upload, onUploadProgress);
        }

        xhr.send(options.body);
    });
}
