import React, { memo, useMemo } from 'react';

import { DialogModel } from 'modules/domain/dialog';
import { FastAnswersModel } from 'modules/domain/fast-answers';
import { NextChatButton } from 'modules/components/chat/next-chat-button';
import { FastAnswersFillingButton } from 'modules/components/fast-answers/filling-button';
import { FastAnswersSlider } from 'modules/components/fast-answers/slider';

import styles from './index.module.scss';

export const MultichatFooter = memo(() => {
    const { hasFastAnswers, fastAnswers } = FastAnswersModel.hooks.useFastAnswersByRealm();
    const { dialog } = DialogModel.hooks.useSelectors();

    const filledFastAnswers = useMemo(() => {
        return fastAnswers.filter(fastAnswer => fastAnswer.phrase.length !== 0);
    }, [fastAnswers]);

    const fastAnswersComponent = useMemo(() => {
        if (!dialog || !dialog.realm) {
            return null;
        }

        return (
            <>
                <FastAnswersFillingButton variant={hasFastAnswers ? 'default' : 'extended'} />
                {hasFastAnswers && (
                    <FastAnswersSlider data={filledFastAnswers} realm={dialog.realm} attendeeId={dialog.attendeeId} />
                )}
            </>
        );
    }, [dialog, filledFastAnswers, hasFastAnswers]);

    return (
        <div className={styles.container}>
            <div className={styles.fastAnswers}>{fastAnswersComponent}</div>
            <NextChatButton animatorId={dialog?.animatorId} attendeeId={dialog?.attendeeId} />
        </div>
    );
});
