export function uncapsTheLastWord(message: string) {
    const lastWord = message.slice(message.lastIndexOf(' ') + 1);
    if (lastWord.length < 3) {
        // too short to count as word
        return message;
    }
    if (lastWord === lastWord.toLocaleUpperCase()) {
        const lastWordIndex = message.lastIndexOf(lastWord);
        return message.substring(0, lastWordIndex) + lastWord.toLocaleLowerCase();
    } else return message;
}
