import React, { useMemo } from 'react';

import { UserTabNotes } from 'modules/components/user/user-tab-notes';
import { UserTabInfo } from 'modules/components/user/user-tab-info';
import { UserModel } from 'modules/domain/users';
import { UserTabPhotos } from 'modules/components/user/user-tab-photos';
import { UserTabSentPhotos } from 'modules/components/user/user-tab-sent-photos';
import { UserTabStory } from 'modules/components/user/user-tab-story';
import { UserTabSpotifyMatch } from 'modules/components/user/user-tab-spotify-match';
import { SpotifyMatchModel } from 'modules/domain/spotify-match';

import styles from './index.module.css';
import { AccordionSection } from './accordion-section';

type Props = {
    ownerId: string;
    isAnimator: boolean;
    animatorId: string;
};

export const AccordionTabs = (props: Props) => {
    const { ownerId, isAnimator, animatorId } = props;

    const {
        populatedUser: { info: userInfo },
    } = UserModel.hooks.useUserSelectorsById(ownerId);

    const spotifyMatchEnabled = SpotifyMatchModel.hooks.useSpotifyEnabledFeature();

    const tabConfig = useMemo(() => {
        return [
            {
                id: 'notes',
                title: 'Notes',
                enabled: true,
                content: <UserTabNotes ownerId={ownerId} />,
            },
            {
                id: 'info',
                title: 'Info',
                enabled: true,
                content: <UserTabInfo ownerId={ownerId} />,
            },
            {
                id: 'photo',
                title: 'Photos',
                enabled: true,
                content: <UserTabPhotos ownerId={ownerId} />,
            },
            {
                id: 'story',
                title: 'Life Story',
                enabled: !!userInfo?.lifeStory,
                content: <UserTabStory ownerId={ownerId} />,
            },
            {
                id: 'sentPhotos',
                title: 'Sent Photos',
                enabled: isAnimator,
                content: <UserTabSentPhotos ownerId={ownerId} />,
            },
            {
                id: 'spotify-match',
                title: 'Spotify',
                enabled: spotifyMatchEnabled && !isAnimator,
                content: <UserTabSpotifyMatch userId={animatorId} attendeeId={ownerId} />,
            },
        ];
    }, [isAnimator, ownerId, userInfo?.lifeStory, animatorId, spotifyMatchEnabled]);

    return (
        <div className={styles.accordionContainer}>
            {tabConfig
                .filter(section => section.enabled)
                .map(({ id, title, content }) => (
                    <AccordionSection key={id} title={title} content={content} id={id} isAnimator={isAnimator} />
                ))}
        </div>
    );
};
