import { useContext, createContext } from 'react';

import { DatesRange } from 'modules/domain/earnings/types';
import { DEFAULT_DATES_RANGE } from 'modules/domain/earnings/constants';

import { shouldShowKPI } from './helpers';

export type StatsContextType = {
    selectedRange: DatesRange;
    showKPI: boolean;
    onRangeChange: (range: DatesRange) => void;
};

export const StatsContext = createContext<StatsContextType>({
    selectedRange: DEFAULT_DATES_RANGE,
    showKPI: shouldShowKPI(DEFAULT_DATES_RANGE),
    onRangeChange: () => {},
});

export const useStatsContext = () => {
    return useContext(StatsContext);
};
