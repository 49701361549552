import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useLogger } from 'modules/utils';
import { getUserTags } from 'modules/domain/users/api';
import { actions } from 'modules/domain/users/actions';

export const useUserTagsApi = () => {
    const dispatch = useDispatch();
    const { usersTags: usersTagsApi } = useApi();
    const { api } = useAuthApi();
    const { logError } = useLogger('useUserTagsApi');

    const fetchUserTags = useCallback(
        async (userId: string) => {
            try {
                const tags = await getUserTags(api, userId);
                dispatch(actions.setUserTags(userId, tags));
            } catch (error) {
                logError('fetchUserTags error', { userId, error });
            }
        },
        [dispatch, api, logError],
    );

    const fetchUserExperimentsTags = useCallback(
        async (userId: string) => {
            try {
                const tags = await usersTagsApi.getUserExperimentsTags(userId);
                dispatch(actions.setUserExperimentsTags(userId, tags));
            } catch (error) {
                logError('setUserExperimentsTags error', { userId, error });
            }
        },
        [dispatch, usersTagsApi, logError],
    );

    return {
        fetchUserTags,
        fetchUserExperimentsTags,
    };
};
