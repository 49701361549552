export enum ApiEventsType {
    AnsweredMessage = 'message.answered',
    UnansweredMessage = 'message.unanswered',
    NewMessage = 'message:message:v2',
    TypingStarted = 'event.user.typing.started',
    TypingEnded = 'event.user.typing.ended',
}

export type TypingEventDto = {
    label: ApiEventsType.TypingStarted | ApiEventsType.TypingEnded;
    payload: {
        sender: string;
    };
};

export type EventDto = TypingEventDto;
