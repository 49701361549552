import { FastAnswerInTypedMessage } from '../types';

import { DialogActions } from './common';

export type SetTypedMessagePayload = {
    message: string;
};
export type SetMessageAction = {
    type: DialogActions;
    payload: SetTypedMessagePayload;
};
const typeMessage = (message: string): SetMessageAction => ({
    type: DialogActions.SetTypedMessage,
    payload: { message },
});

export type SetAdditionalMessagePartPayload = {
    message: string;
    fastAnswer?: FastAnswerInTypedMessage;
};
export type SetAdditionalMessagePartAction = {
    type: DialogActions;
    payload: SetAdditionalMessagePartPayload;
};
const setAdditionalMessagePart = (
    message: string,
    fastAnswer?: FastAnswerInTypedMessage,
): SetAdditionalMessagePartAction => ({
    type: DialogActions.SetAdditionalMessagePart,
    payload: { message, fastAnswer },
});

export type SetDialogInputIsFocusedPayload = {
    isFocused: boolean;
};
const setDialogInputIsFocused = (isFocused: boolean) => ({
    type: DialogActions.SetDialogInputIsFocused,
    payload: { isFocused },
});

export const messageTypingActions = {
    typeMessage,
    setAdditionalMessagePart,
    setDialogInputIsFocused,
};
