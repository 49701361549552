import React, { memo } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';

import { testId } from 'modules/utils/test-id';

import { LabelsConfig } from '../../constants/labels-config';
import { LabelIds } from '../../constants/label-ids';
import styles from '../index.module.scss';

type Props = {
    id: LabelIds;
    textTemplateParam?: string | number;
};

export const LabelItem = memo((props: Props) => {
    const { id, textTemplateParam } = props;
    const { color = '#fff', backgroundColor, text, tooltip, testNodeId } = LabelsConfig[id];

    const caption = typeof text === 'string' ? text : text(textTemplateParam || '');
    const testIdData = testNodeId ? testId(testNodeId) : testId(caption);

    if (!tooltip) {
        return (
            <span {...testIdData} className={styles.label} style={{ backgroundColor, color }}>
                {caption}
            </span>
        );
    }

    return (
        <>
            <span
                {...testIdData}
                data-tooltip-place="bottom-start"
                className={styles.label}
                style={{ backgroundColor, color }}
                data-tooltip-id={id}
            >
                {caption}
            </span>
            <ReactTooltip id={id} variant="light" className={styles.tooltipContainer} noArrow={true} delayHide={100}>
                <span>{tooltip}</span>
            </ReactTooltip>
        </>
    );
});
