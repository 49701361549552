import { useEffect } from 'react';

import { TypingSource, useTypingTracker } from 'modules/domain/typing-tracker';
import { DialogModel } from 'modules/domain/dialog';

export const useTypingIndicatorActive = () => {
    const { dialogId } = DialogModel.hooks.useSelectors();
    const typingTracker = useTypingTracker();

    useEffect(() => {
        typingTracker.setTypingStart(dialogId, TypingSource.AudioPicker);

        return () => {
            typingTracker.setTypingEnd(dialogId, TypingSource.AudioPicker);
        };
    }, [typingTracker, dialogId]);
};
