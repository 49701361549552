import React, { memo } from 'react';
import { Formik, Form, Field } from 'formik';

import { TextInput } from 'modules/components/common/text-input';
import { Button } from 'modules/components/common/button/base';
import { testId } from 'modules/utils/test-id';

import { SearchFormValues } from './types';
import { STORAGE_KEY_CLIENT, STORAGE_KEY_ANIMATOR } from './constants';
import styles from './index.module.scss';

type Props = {
    initialFormState: SearchFormValues;
    validate: (values: SearchFormValues) => void;
    handleSubmit: (values: SearchFormValues, methods: any) => void;
    onClickClose: () => void;
};

export default memo((props: Props) => {
    const { initialFormState, validate, handleSubmit, onClickClose } = props;
    return (
        <div className={styles.container}>
            <div className={styles.caption}>Search by ID</div>
            <Formik initialValues={initialFormState} validate={validate} onSubmit={handleSubmit}>
                {({ isSubmitting, handleChange }) => (
                    <Form>
                        <Field
                            {...testId('search-client-id-field')}
                            type="number"
                            name="userId"
                            placeholder="Client’s ID"
                            backgroundTheme="light"
                            component={TextInput}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(event);
                                window.localStorage.setItem(STORAGE_KEY_CLIENT, event.target.value);
                            }}
                        />
                        <Field
                            {...testId('search-animator-id-field')}
                            type="number"
                            name="animatorId"
                            placeholder="Animator’s ID"
                            backgroundTheme="light"
                            component={TextInput}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                handleChange(event);
                                window.localStorage.setItem(STORAGE_KEY_ANIMATOR, event.target.value);
                            }}
                        />
                        <div className={styles.row}>
                            <Button {...testId('close-search-button')} size="xs" theme="default" onClick={onClickClose}>
                                Cancel
                            </Button>
                            <Button
                                {...testId('search-chat-button')}
                                type="submit"
                                size="xs"
                                theme="green"
                                disabled={isSubmitting}
                            >
                                Search
                            </Button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
});
