import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { DialogModel } from 'modules/domain/dialog';
import { UserModel } from 'modules/domain/users';
import { FastAnswer } from 'modules/domain/fast-answers/types';
import { replaceByMask } from 'modules/domain/fast-answers/utils';
import { usePrevious } from 'modules/domain/common/hooks';
import { testId } from 'modules/utils/test-id';
import { useShortcutListener } from 'modules/components/common/hooks/use-shortcut-listener';

import { FastAnswersSliderSwitchButton } from './switch-button';
import styles from './index.module.scss';

type Props = {
    data: FastAnswer[];
    realm: string;
    attendeeId: string;
};

const itemsCountInOneSlide = 5;

export const FastAnswersSlider = memo((props: Props) => {
    const { data, realm, attendeeId } = props;
    // Hooks
    const { user } = UserModel.hooks.useUserSelectorsById(attendeeId);
    const { setAdditionalMessagePart } = DialogModel.hooks.useDialogTypedMessage();

    // States
    const [page, setPage] = useState<number>(1);

    // Memos
    const dataPerPage = useMemo(() => {
        const startIndex = (page - 1) * itemsCountInOneSlide;
        return data.slice(startIndex, startIndex + itemsCountInOneSlide);
    }, [data, page]);

    const dataCount = useMemo(() => data.length, [data]);
    const prevDataCount = usePrevious(dataCount);
    const dataCountPerPage = useMemo(() => dataPerPage.length, [dataPerPage]);

    const sendMessage = useCallback(
        (fastAnswer: FastAnswer) => {
            const message = replaceByMask(fastAnswer.phrase, '%name%', user.name);
            const payload = { realm, phrase: fastAnswer.phrase, hotkey: fastAnswer.shortcut };
            setAdditionalMessagePart(message, 'fast-answer', payload);
        },
        [realm, setAdditionalMessagePart, user.name],
    );

    const handleItemClick = useCallback((fastAnswer: FastAnswer) => () => sendMessage(fastAnswer), [sendMessage]);

    const handleShortcutPress = useCallback(
        (keyNumber: number) => {
            const foundFastAnswer = data.find(item => keyNumber === item.shortcutDigit);

            if (foundFastAnswer) {
                sendMessage(foundFastAnswer);
            }
        },
        [data, sendMessage],
    );

    useShortcutListener(handleShortcutPress, 'ctrlKey');

    const renderItem = useCallback(
        (item: FastAnswer) => {
            return (
                <div
                    {...testId(`footer-shortcut-${item.shortcut}`)}
                    key={item.shortcut}
                    className={classNames(styles.itemWrapper, {
                        [styles.itemWrapperStretch]: dataCountPerPage === itemsCountInOneSlide,
                    })}
                    onClick={handleItemClick(item)}
                >
                    <div className={styles.itemCaption}>Ctrl + {item.shortcutDigit}</div>
                    <div className={styles.itemTitle}>{item.phrase}</div>
                </div>
            );
        },
        [dataCountPerPage, handleItemClick],
    );

    const items = useMemo(() => dataPerPage.map(renderItem), [dataPerPage, renderItem]);

    const needToSwitch = useMemo(() => (data || []).length > itemsCountInOneSlide, [data]);

    const direction = useMemo(() => (page === 1 ? 'next' : 'prev'), [page]);

    const slideCarousel = useCallback(() => {
        setPage(prevPage => (prevPage === 1 ? 2 : 1));
    }, []);

    useEffect(() => {
        if (page === 2 && typeof prevDataCount === 'number' && dataCount < prevDataCount && dataCount <= 5) {
            setPage(1);
        }
    }, [dataCount, page, prevDataCount]);

    return (
        <>
            <div className={styles.container}>{items}</div>
            {needToSwitch && <FastAnswersSliderSwitchButton direction={direction} onClick={slideCarousel} />}
        </>
    );
});
