import { RootState } from 'modules/App/store/root-state';

import { namespace } from './actions';
import { ModalNames, ModalModelState } from './types';

export const getModalModelState = (state: RootState): ModalModelState => state[namespace];

export const getModalVisibilityStatus = (state: RootState): boolean => {
    return getModalModelState(state).isOpen;
};

export const getActiveModalName = (state: RootState): ModalNames | null => {
    return getModalModelState(state).activeModalName;
};

export const getActiveModalData = <T>(state: RootState): T | null => {
    return getModalModelState(state).data;
};

export const selectors = {
    getModalVisibilityStatus,
    getActiveModalName,
};
