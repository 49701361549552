import React, { memo, ComponentProps } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from 'modules/navigation';
import { useSignIn, ALLOWED_ROLE } from 'modules/domain/users/hooks';
import { toast } from 'modules/utils';

import { View } from './view';

type ViewProps = ComponentProps<typeof View>;

export const AuthForm = memo(() => {
    const navigate = useNavigate();
    const signIn = useSignIn();

    const handleSubmit: ViewProps['handleSubmit'] = async (values, { setSubmitting, setErrors }) => {
        const { email, password, remember } = values;

        setSubmitting(true);

        const result = await signIn(email, password, remember);

        setSubmitting(false);

        if (result.success) {
            navigate(AppRoutes.Chat);
            return;
        }

        switch (result.error) {
            case 'unknown':
                toast.showError('Something went wrong. Check your internet connection and try again later.');
                return; // The explicit return means that we don't need to setErrors on form fields for this error
            case 'forbidden':
                toast.showError('Email or password is incorrect or the user not found.');
                break;
            case 'incorrect-role':
                toast.showError(`Sign In is possible only with the "${ALLOWED_ROLE}" role`);
                break;
        }

        setErrors({ email: 'Error', password: 'Error' });
    };

    return <View handleSubmit={handleSubmit} />;
});
