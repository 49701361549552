import { UserModel } from 'modules/domain/users';
import { useAnimatorsLocation } from 'modules/domain/animators-locations/hooks';

export function useLocation(animatorId: string, attendeeId: string, isAnimator: boolean) {
    const userId = isAnimator ? animatorId : attendeeId;
    const { user } = UserModel.hooks.useUserSelectorsById(userId);

    const location = useAnimatorsLocation(animatorId, attendeeId);

    if (!isAnimator) {
        return user.location;
    }

    return location;
}
