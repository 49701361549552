import React, { ButtonHTMLAttributes, memo, useMemo } from 'react';
import classNames from 'classnames';

import styles from './index.module.scss';

type ButtonDefaultProps = ButtonHTMLAttributes<HTMLButtonElement>;

type Props = {
    theme?: 'default' | 'green' | 'raspberry' | 'blue';
    size?: 'default' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg';
} & ButtonDefaultProps;

export const Button = memo((props: Props) => {
    const { theme = 'default', size = 'default', children, className: externalStyles, ...rest } = props;

    const className = useMemo(
        () =>
            classNames(
                styles.container,
                { [styles[theme]]: theme !== 'default' },
                { [styles[size]]: size !== 'default' },
                externalStyles,
            ),
        [theme, size, externalStyles],
    );

    return (
        <button type="button" {...rest} className={className}>
            {children}
        </button>
    );
});
