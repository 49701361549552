import pify from 'pify';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

type SocketShard = {
    key: string;
    shard: string;
};

export function getSocketShards(api: SDVLegacyAPI, operatorId: string): Promise<SocketShard> {
    return pify(api.shards.get)(operatorId);
}
