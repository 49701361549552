import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { MultiChatReducer } from 'modules/domain/common/types';

import { MatchActions, AddMatchPayload } from './actions';
import { namespace, MatchState } from './types';

type CommonPayload = AddMatchPayload;

interface CustomReducerMap extends ReducerMap<MatchState, CommonPayload> {
    [MatchActions.SetData]: ReducerMapValue<MatchState, AddMatchPayload>;
}

const initialState = {
    [namespace]: {},
};

const reducerMapping: CustomReducerMap = {
    [MatchActions.SetData]: (state, { payload }) =>
        update(state, {
            [namespace]: {
                $set: { ...state[namespace], [`${payload.animatorId}:${payload.attendeeId}`]: payload.match },
            },
        }),
};

export const reducer: MultiChatReducer<MatchState, CommonPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
