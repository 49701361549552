import { useSelector } from 'react-redux';

import { getUserLocationString } from 'modules/domain/users/user-adapter';
import { UserModel } from 'modules/domain/users';
import { AnimatorLocationModel } from 'modules/domain/animators-locations';
import { RootState } from 'modules/App/store/root-state';

import { AnimatorProps } from './types';

export function useAnimatorData(props: AnimatorProps) {
    const { id, attendeeId } = props;
    const { user } = UserModel.hooks.useUserSelectorsById(id);
    const aliasedLocation = useSelector((state: RootState) =>
        AnimatorLocationModel.selectors.getAnimatorLocation(state, id, attendeeId),
    );

    const result = { ...user };

    if (aliasedLocation && Object.keys(aliasedLocation).length) {
        result.location = getUserLocationString(aliasedLocation.city, aliasedLocation.country);
    }

    return result;
}
