import { useEffect } from 'react';

export type PollerFn = () => any;

export const usePoller = (fn: PollerFn, interval: number) => {
    useEffect(() => {
        // todo good enough for now, we can replace it by the poller from some library if needed
        // there might be a race condition, when the request takes more than the interval
        // it is ok for now to have slightly stale data
        const intervalTimerId = window.setInterval(fn, interval);

        fn();

        return () => {
            clearInterval(intervalTimerId);
        };
    }, [fn, interval]);
};
