import { Dialog } from '../types';

import { DialogActions } from './common';

export type ApiRequestDialogListAction = {
    type: DialogActions.ApiRequestListStart;
    payload: null;
};

export function apiRequestDialogList(): ApiRequestDialogListAction {
    return { type: DialogActions.ApiRequestListStart, payload: null };
}

export type ApiRequestSuccessDialogListPayload = { dialogList: Dialog[]; searchMode: boolean };
export type ApiRequestSuccessDialogListAction = {
    type: DialogActions.ApiRequestListSuccess;
    payload: ApiRequestSuccessDialogListPayload;
};

export function apiRequestSuccessDialogList(
    dialogList: Dialog[],
    searchMode: boolean,
): ApiRequestSuccessDialogListAction {
    return { type: DialogActions.ApiRequestListSuccess, payload: { dialogList, searchMode } };
}

export type ApiRequestFailureDialogListPayload = { error: string };
export type ApiRequestFailureDialogListAction = {
    type: DialogActions.ApiRequestListFailure;
    payload: ApiRequestFailureDialogListPayload;
};

export function apiRequestFailureDialogList(error: string): ApiRequestFailureDialogListAction {
    return { type: DialogActions.ApiRequestListFailure, payload: { error } };
}

export type ApiRequestListEmptyAction = {
    type: DialogActions.ApiListEmpty;
};

export function apiRequestListEmpty(): ApiRequestListEmptyAction {
    return { type: DialogActions.ApiListEmpty };
}

export const apiDialogActions = {
    apiRequestDialogList,
    apiRequestSuccessDialogList,
    apiRequestFailureDialogList,
    apiRequestListEmpty,
};
