import { QueryParams } from 'modules/utils/query-string';
import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

export const sendActivityStatus = (api: SDVLegacyAPI, operatorId: string, params: QueryParams = {}) => {
    return new Promise((resolve, reject) => {
        api.multichat.operators.touch.post(operatorId, params, (error: unknown, response: void) => {
            if (error) {
                return reject({});
            }

            resolve(response);
        });
    });
};
