import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

export const getUserInterests = (api: SDVLegacyAPI, userId: string) => {
    const extraHeaders = {
        'Client-Version': '2',
    };

    return new Promise<string[]>((resolve, reject) => {
        api.users.interests.get(
            userId,
            (error: unknown, response: string[]) => {
                if (error) {
                    return reject({});
                }

                resolve(response);
            },
            extraHeaders,
        );
    });
};
