import { useDispatch, batch } from 'react-redux';
import { useCallback } from 'react';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { getUserRealmTags } from 'modules/domain/users/api';
import { getDialogId } from 'modules/utils';

import { Dialog } from '../types';
import { actions } from '../actions';
import { AudioMessageTemplate } from '../audio';

import { useDialogAnalytics } from './use-dialog-analytics';

export const useDialogActions = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { trackSearchDialog } = useDialogAnalytics();

    const findDialog = useCallback(
        async (attendeeId: string, animatorId: string) => {
            const id = getDialogId(animatorId, attendeeId);

            trackSearchDialog(animatorId, attendeeId);

            const [realm] = await getUserRealmTags(api, attendeeId);

            const dialog: Dialog = {
                serverId: 0,
                id,
                animatorId,
                attendeeId,
                realm,
                messages: [],
                unread: false,
                answered: true,
                unansweredUnpaid: false,
                timestamp: new Date().getTime(),
                eventId: 999999,
                isExplicit: false,
                isExplicitPossibleForClient: false,
                someMessagesFromTheDialogWereLoaded: false,
            };

            batch(() => {
                dispatch(actions.apiRequestSuccessDialogList([dialog], true));
                dispatch(actions.setFoundDialog(id));
            });
        },
        [api, dispatch, trackSearchDialog],
    );

    const resetFoundDialog = useCallback(() => {
        dispatch(actions.resetFoundDialog());
    }, [dispatch]);

    return {
        findDialog,
        resetFoundDialog,
    };
};

export const useDialogActionsById = (dialogId: string) => {
    const dispatch = useDispatch();

    const switchOffSearchMessages = useCallback(
        () => dispatch(actions.clearSearchedMessages(dialogId)),
        [dialogId, dispatch],
    );

    const removeMessage = useCallback(
        (tag: string) => dispatch(actions.removeMessageByTag(dialogId, tag)),
        [dialogId, dispatch],
    );

    const setAudioMessages = useCallback(
        (audioMessages: AudioMessageTemplate[]) => dispatch(actions.setAudioMessages(dialogId, audioMessages)),
        [dialogId, dispatch],
    );

    const resetAudioMessages = useCallback(() => dispatch(actions.resetAudioMessages(dialogId)), [dialogId, dispatch]);

    return {
        switchOffSearchMessages,
        removeMessage,
        setAudioMessages,
        resetAudioMessages,
    };
};
