import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IAnnalsDomain } from './contracts';

export class AnnalsDomain implements IAnnalsDomain {
    constructor(private readonly transport: IHttpTransport) {}

    async send(userId: string, event: string, data?: object): Promise<void> {
        await this.transport.post(`/annals/${userId}/${event}`, {
            timestamp: Date.now(),
            ...data,
        });
    }
}
