import React, { useCallback, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { DialogModel } from 'modules/domain/dialog';

import { UserTabNotesImprovedView as View } from './view';

type Props = {
    ownerId: string;
};

export const UserTabNotes = (props: Props) => {
    const { ownerId } = props;
    const { saveDialogNotesImproved } = DialogModel.hooks.useDialogNoteApi();
    const { dialogId } = DialogModel.hooks.useSelectors();
    const { notesImproved } = DialogModel.hooks.useSelectorsById(dialogId);
    const defaultValues = useMemo(() => {
        if (notesImproved) {
            return notesImproved[ownerId];
        }

        return null;
    }, [notesImproved, ownerId]);

    const onSave = useCallback(
        (newValues: any) => {
            if (notesImproved && notesImproved[ownerId] && !isEqual(newValues, notesImproved[ownerId])) {
                const newNotes = cloneDeep({ ...notesImproved, [ownerId]: newValues });

                saveDialogNotesImproved(dialogId, newNotes);
            }
        },
        [dialogId, notesImproved, ownerId, saveDialogNotesImproved],
    );

    if (!defaultValues) {
        return null;
    }

    return <View defaultValues={defaultValues} onSave={onSave} />;
};
