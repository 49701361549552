import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';
import {
    getAnsweredDialogIdList,
    getCurrentDialog,
    getCurrentDialogId,
    getDialogsMetrics,
    getDialogsQueueIsEmpty,
    getCanToggleExplicit,
    getFoundDialog,
    getFoundDialogId,
    getLastBotMessage,
    getMessageList,
    getNoMoreMessages,
    getNotes,
    getPreferences,
    getSearchedMessages,
    getSortedDialogIdList,
    getSomeDialogMessagesWereLoaded,
    getUnansweredDialogIdList,
    getUnansweredUnpaidMessage,
    getUnansweredUnpaidState,
    getNotesImproved,
    getCopilotState,
    getScrollToMessageId,
} from 'modules/domain/dialog/selectors';
import { parseDialogId } from 'modules/utils';
import { UserModel } from 'modules/domain/users';
import { DialogMessageDto } from 'modules/api/dto';

import { Dialog, DialogMessage, Metrics, Note, SearchedDialogMessages, Preference, ClientImprovedNote } from '../types';
import { singleMessageAdapter, messagesAdapter } from '../adapter';
import { CopilotState } from '../types/copilot';

export const useSelectors = () => {
    const currentDialog = useSelector<RootState, Dialog | undefined>(state => getCurrentDialog(state));
    const currentDialogId = useSelector<RootState, string>(state => getCurrentDialogId(state));
    const answeredDialogIdList = useSelector<RootState, string[]>(state => getAnsweredDialogIdList(state));
    const unansweredDialogIdList = useSelector<RootState, string[]>(state => getUnansweredDialogIdList(state));
    const sortedDialogIdList = useSelector<RootState, string[]>(state => getSortedDialogIdList(state));
    const foundDialogId = useSelector<RootState, string | null>(state => getFoundDialogId(state));
    const foundDialog = useSelector<RootState, Dialog | null>(state => getFoundDialog(state));
    const lastBotMessage = useSelector<RootState, boolean>(state => getLastBotMessage(state));
    const dialogsQueueIsEmpty = useSelector<RootState, boolean>(state => getDialogsQueueIsEmpty(state));
    const canToggleExplicit = useSelector<RootState, boolean>(state => getCanToggleExplicit(state));
    const scrollToMessageId = useSelector<RootState, number | null>(state => getScrollToMessageId(state));

    const dialog = useMemo(() => foundDialog || currentDialog, [foundDialog, currentDialog]);
    const dialogId = useMemo(() => foundDialogId || currentDialogId, [foundDialogId, currentDialogId]);

    const metrics = useSelector<RootState, Metrics>(state => getDialogsMetrics(state));
    const copilot = useSelector<RootState, CopilotState>(state => getCopilotState(state));
    return {
        dialog,
        dialogId,
        answeredDialogIdList,
        unansweredDialogIdList,
        sortedDialogIdList,
        foundDialogId,
        metrics,
        lastBotMessage,
        canToggleExplicit,
        dialogsQueueIsEmpty,
        copilot,
        scrollToMessageId,
    };
};

export const useSelectorsById = (dialogId: string) => {
    const { animatorId } = parseDialogId(dialogId);

    const dialogMessages = useSelector<RootState, DialogMessageDto[]>(state => getMessageList(state, dialogId));
    const noMoreMessages = useSelector<RootState, boolean>(state => getNoMoreMessages(state, dialogId));
    const notes = useSelector<RootState, Note>(state => getNotes(state, dialogId));
    const preferences = useSelector<RootState, Preference>(state => getPreferences(state, dialogId));
    const notesImproved = useSelector<RootState, ClientImprovedNote | null>(state => getNotesImproved(state, dialogId));
    const { user: animator } = UserModel.hooks.useUserSelectorsById(animatorId);

    const unansweredUnpaidState = useSelector<RootState, boolean>(state => getUnansweredUnpaidState(state, dialogId));
    const unansweredUnpaidMessage = useSelector<RootState, DialogMessageDto | null>(state =>
        getUnansweredUnpaidMessage(state, dialogId),
    );
    const someMessagesFromTheDialogWereLoaded = useSelector<RootState, boolean>(state =>
        getSomeDialogMessagesWereLoaded(state, dialogId),
    );

    const messages = useMemo((): DialogMessage[] => {
        if (unansweredUnpaidState && unansweredUnpaidMessage) {
            let inserted = false;

            const unansweredUnpaid = singleMessageAdapter(unansweredUnpaidMessage, animatorId, false, false);
            const unansweredUnpaidToAdd = {
                ...singleMessageAdapter(unansweredUnpaidMessage, animatorId, false, false),
                meta: { ...unansweredUnpaid.meta, unansweredUnpaid: true },
            };

            const messagesWithUnpaid = dialogMessages.reduce<DialogMessage[]>((acc, curr, index) => {
                if (
                    !inserted &&
                    curr.timestamp > unansweredUnpaidMessage.timestamp &&
                    dialogMessages[index + 1]?.timestamp < unansweredUnpaidMessage.timestamp
                ) {
                    acc.push(singleMessageAdapter(curr, animatorId, false, false), unansweredUnpaidToAdd);
                    inserted = true;
                } else {
                    acc.push(singleMessageAdapter(curr, animatorId, false, false));
                }
                return acc;
            }, []);

            if (!inserted) {
                return [unansweredUnpaidToAdd, ...messagesWithUnpaid];
            }

            return messagesWithUnpaid;
        }

        return messagesAdapter(dialogMessages, animatorId, animator.timezone || 0);
    }, [dialogMessages, animatorId, unansweredUnpaidState, unansweredUnpaidMessage, animator.timezone]);

    const latestMessage = messages[0] as DialogMessage | undefined;

    const searchedMessages = useSelector<RootState, SearchedDialogMessages | undefined>(state =>
        getSearchedMessages(state, dialogId),
    );

    return {
        latestMessage,
        messages,
        noMoreMessages,
        notes,
        notesImproved,
        preferences,
        searchedMessages,
        unansweredUnpaidState,
        unansweredUnpaidMessage,
        someMessagesFromTheDialogWereLoaded,
    };
};
