import React, { memo, useCallback } from 'react';
import moment from 'moment';
import { Value } from 'react-calendar/src/shared/types';

import { ModalModel } from 'modules/domain/modals';
import { useDaysWithMessages, useCalendarNavigation, useCalendarTiles } from 'modules/domain/calendar';

import styles from './index.module.css';
import './calendar-styles.css';
import { CalendarWrapper } from './calendar-wrapper';
import { SelectedDateCircle } from './selected-date-circle';
import { CalendarNavigation } from './calendar-navigation';

export const CalendarModal = memo(() => {
    const { activeModalData } = ModalModel.hooks.useSelectors<{
        animatorId: string;
        recipientId: string;
        timestamp: Date;
    }>();

    const { closeModal } = ModalModel.hooks.useModal();
    const availableDays = useDaysWithMessages();

    const { slideMonthLeft, slideMonthRight, startOfActiveMonth, canSlideRight, canSlideLeft, currentDate } =
        useCalendarNavigation();

    const { scrollToMessageWithDateFromTile } = useCalendarTiles(
        activeModalData?.animatorId || '',
        activeModalData?.recipientId || '',
    );

    const changeTileDate = useCallback(
        (date: Value) => {
            scrollToMessageWithDateFromTile(date as Date);
            closeModal();
        },
        [scrollToMessageWithDateFromTile, closeModal],
    );

    return (
        <div className={styles.container}>
            <CalendarNavigation
                onClickPrev={slideMonthLeft}
                onClickNext={slideMonthRight}
                showNextButton={canSlideRight}
                showPrevButton={canSlideLeft}
                currentDate={currentDate}
            />
            <CalendarWrapper
                activeStartDate={startOfActiveMonth}
                onChange={changeTileDate}
                tileDisabled={({ date }) => {
                    const tileDate = moment(date).format('YYYY-MM-DD');
                    return availableDays.every(date => date.date !== tileDate);
                }}
                tileClassName={({ date }) => {
                    if (!activeModalData) {
                        return null;
                    }
                    return moment(date).isSame(moment.utc(activeModalData.timestamp), 'day')
                        ? 'multichat-calendar__active-date'
                        : null;
                }}
                tileContent={({ date }) => {
                    return (
                        <SelectedDateCircle
                            tileDate={date}
                            activeDate={activeModalData ? activeModalData.timestamp : null}
                        />
                    );
                }}
            />
        </div>
    );
});
