import pify from 'pify';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';
import { log } from 'modules/utils';

import { ApiSentMediaResponse, ApiMediaResponse } from './types';

const headers = { accept: 'application/vnd.sdv.filetags+json' };

export const getUserMedia = (api: SDVLegacyAPI, userId: string) => {
    return new Promise<ApiMediaResponse>((resolve, reject) => {
        api.users.photos.list(
            userId,
            (error: unknown, response: ApiMediaResponse) => {
                if (error) {
                    return reject({});
                }

                resolve(response);
            },
            headers,
        );
    });
};

export const getChatMedia = async (api: SDVLegacyAPI, animatorId: string): Promise<string[]> => {
    try {
        const list = await pify(api.users.photos.chat.list.get)(animatorId);

        return list;
    } catch (error) {
        log('error', 'Failed to get chat media', { service: 'getChatMedia', error, animatorId });
        return [];
    }
};
export const getChatMediaState = async (
    api: SDVLegacyAPI,
    animatorId: string,
    clientId: string,
): Promise<ApiSentMediaResponse> => {
    try {
        const response = pify(api.multichat.dialogs.media.allowed.get)(animatorId, clientId);

        return response;
    } catch (error) {
        log('error', 'Failed to get chat media state', { service: 'getChatMediaState', error, animatorId, clientId });

        return { result: false, sent: [] };
    }
};
