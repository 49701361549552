import { EarningsDto } from 'modules/api/dto';

import { calculateBonusIncome } from './calculateBonusIncome';

export function calculateTotalIncome(earnings: EarningsDto | null) {
    if (!earnings) {
        return 0;
    }

    // After applying the KPI earnings.value sometimes comes from the server not rounded
    const moneyForReplies = Math.floor(earnings.ReplyValueWithKpi * 100) / 100 || 0;

    const moneyForBonuses = calculateBonusIncome(earnings);

    const penalties = earnings.TrapPenalties?.TotalBonusValue || 0;
    return moneyForReplies + moneyForBonuses + penalties;
}
