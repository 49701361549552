import React, { memo } from 'react';

import { ExtendedTimerService } from '../extended-timer';

export const Services = memo(() => {
    return (
        <>
            <ExtendedTimerService />
        </>
    );
});
