import React, { memo } from 'react';

import { UserPanel } from '../user/user-panel';

import { useAnimatorData } from './hooks';
import { AnimatorProps } from './types';

export const Animator = memo((props: AnimatorProps) => {
    const userData = useAnimatorData(props);

    return <UserPanel {...props} {...userData} me={true} />;
});
