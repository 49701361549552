import React from 'react';
import cn from 'classnames';

import { IcebreakerContent } from 'modules/domain/dialog/types';
import { Loader } from 'modules/components/loader';
import purpurLogo from 'images/purpur-logo-small.svg';

import styles from './index.module.css';

type Props = {
    content: IcebreakerContent;
    outgoing: boolean;
};

export function IcebreakerMessage(props: Props) {
    const { content, outgoing } = props;
    const { type, text } = content;

    return (
        <div className={cn(styles.container, outgoing ? styles.right : styles.left)}>
            <div className={cn(styles.outerBorder, type === 'erotic' ? styles.erotic : styles.general)}>
                <div className={styles.card}>
                    <div className={styles.text}>{text || <Loader />}</div>
                    <img src={purpurLogo} className={styles.logo} />
                </div>
            </div>
            <div className={cn(styles.subtext, outgoing ? styles.right : styles.left)}>
                Don&apos;t miss the chance to deepen your connection with
                <br /> a meaningful discussion of the question!
            </div>
        </div>
    );
}
