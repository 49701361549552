import { AnimatorComplaints, ClientComplaints } from 'modules/domain/complaint/types';

export const ComplaintsOnAnimator: AnimatorComplaints[] = [
    'Poor grammar',
    'Inappropriate content',
    'Out of context',
    'Suggestion to meet',
    'Contact details',
    'Other',
];

export const ComplaintsOnClient: ClientComplaints[] = [
    'Disappointed with the product',
    'Insulted an animator',
    'Reported a technical issue',
    'User is a scammer',
    'Other',
];
