import { useMemo } from 'react';

import { useAuth } from 'modules/domain/auth/use-auth';

import { useApi } from './use-api';

/**
 * @deprecated use useApi from `modules/api` instead
 */
export const useAuthApi = () => {
    const { getApiInstance } = useApi();
    const { auth: user } = useAuth();

    const api = useMemo(() => user.id && user.token && getApiInstance(user.id, user.token), [getApiInstance, user]);

    return {
        api,
    };
};
