import { GiftsAllowedDataDto } from '../types';

const GIFTS_BASE_PRICE_LIMIT = 5000;

export const getGiftsPriceLimitFromDto = (dto: GiftsAllowedDataDto) => {
    const { result: allowed, priceAllowed } = dto;
    const priceLimit = priceAllowed ?? GIFTS_BASE_PRICE_LIMIT;
    const actualLimit = allowed ? priceLimit : 0;

    return actualLimit;
};
