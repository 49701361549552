import { useCallback } from 'react';

import { parseDialogId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { useAudioMessagesApi } from 'modules/domain/dialog/audio';

export const useAudioMessagesTemplates = () => {
    const { dialogId, dialog } = DialogModel.hooks.useSelectors();
    const { setAudioMessages, resetAudioMessages } = DialogModel.hooks.useDialogActionsById(dialogId);
    const { getAudioMessageTemplates, checkAudioMessagesAvailable } = useAudioMessagesApi();

    const loadAudioMessagesTemplates = useCallback(
        (reset?: boolean) => {
            const { animatorId, attendeeId } = parseDialogId(dialogId);

            if (reset) {
                resetAudioMessages();
            }

            checkAudioMessagesAvailable(attendeeId).then(available => {
                if (available) {
                    getAudioMessageTemplates(animatorId, attendeeId).then(setAudioMessages);
                }
            });
        },
        [dialogId, resetAudioMessages, setAudioMessages, getAudioMessageTemplates, checkAudioMessagesAvailable],
    );

    return { audioMessagesTemplates: dialog?.audioMessages, loadAudioMessagesTemplates };
};
