import React, { useMemo, useCallback, memo } from 'react';
import { useSelector } from 'react-redux';

import { MediaModel } from 'modules/domain/media';
import fallbackMaleImage from 'images/fallback-male.svg';
import fallbackFemaleImage from 'images/fallback-female.svg';
import fallbackUnknownImage from 'images/fallback-unknown-gender.svg';
import { UserModel } from 'modules/domain/users';
import { getUserThumbnail } from 'modules/domain/users/user-adapter';
import { ImageOverlay } from 'modules/components/image-overlay';
import { useToken } from 'modules/domain/auth/use-token';
import { testId } from 'modules/utils/test-id';
import { Image } from 'modules/components/image';
import { useConfig } from 'modules/config';

import styles from './index.module.scss';

type Props = {
    ownerId: string;
};

const IMAGE_SIZE = 560;

export const UserTabPhotos = memo((props: Props) => {
    const { ownerId } = props;
    // Selectors
    const appConfig = useConfig();
    const { token } = useToken();

    const { user } = UserModel.hooks.useUserSelectorsById(ownerId);
    const { gender } = user;
    const media = useSelector(state => MediaModel.selectors.getMedia(state, ownerId));
    const memoizedMedia = useMemo(() => media || [], [media]);

    const fallback = !gender ? fallbackUnknownImage : gender === 'mal' ? fallbackMaleImage : fallbackFemaleImage;
    const publicPhotos = useMemo(
        () =>
            memoizedMedia
                .filter(mediaItem => !mediaItem.isPrivate)
                .map(photoData => ({
                    ...photoData,
                    overlaySrc:
                        getUserThumbnail({ id: ownerId, thumbnail: photoData.id }, appConfig, IMAGE_SIZE) || fallback,
                })),
        [appConfig, fallback, memoizedMedia, ownerId],
    );
    const privatePhotos = useMemo(
        () =>
            memoizedMedia
                .filter(mediaItem => mediaItem.isPrivate)
                .map(photoData => ({
                    ...photoData,
                    overlaySrc:
                        getUserThumbnail(
                            { id: ownerId, thumbnail: photoData.id },
                            appConfig,
                            IMAGE_SIZE,
                            true,
                            false,
                            token,
                        ) || fallback,
                })),
        [appConfig, fallback, memoizedMedia, ownerId, token],
    );
    const publicOverlays = useMemo(() => publicPhotos.map(data => data.overlaySrc), [publicPhotos]);
    const privateOverlays = useMemo(() => privatePhotos.map(data => data.overlaySrc), [privatePhotos]);

    const renderPhotos = useCallback(
        (photos: any[], overlays, isPrivatePhoto) =>
            photos.map((photo, i) => {
                const photoUrl = `${photo.url}${isPrivatePhoto ? `?authorization=Token%20token="${token}"` : ''}`;
                return (
                    <ImageOverlay selectedItem={i} key={photo.id} src={overlays} size={IMAGE_SIZE}>
                        <Image
                            a11yLabel={`gallery-photo-${photo.id}`}
                            src={photoUrl}
                            alt={photo.id}
                            fallback={fallback}
                        />
                    </ImageOverlay>
                );
            }),
        [fallback, token],
    );

    const renderPhotoBlock = useCallback(
        (type: string) => {
            const photos = type === 'public' ? publicPhotos : privatePhotos;
            const overlays = type === 'public' ? publicOverlays : privateOverlays;
            const title = type === 'public' ? 'Photos' : 'Private Photos';

            if (photos.length === 0) {
                return;
            }

            return (
                <div className={styles.photosBlock}>
                    <div {...testId('user-tab-photo-block-title')} className={styles.title}>
                        {title}
                    </div>
                    <div {...testId('user-tab-photo-block-gallery')} className={styles.photosGrid}>
                        {renderPhotos(photos, overlays, type === 'private')}
                    </div>
                </div>
            );
        },
        [publicPhotos, privatePhotos, publicOverlays, privateOverlays, renderPhotos],
    );

    return (
        <div className={styles.container}>
            <div {...testId(`user-tab-photo-${ownerId}`)} className={styles.containerInner}>
                {renderPhotoBlock('public')}
                {renderPhotoBlock('private')}
            </div>
        </div>
    );
});
