import { SearchedDialogMessagesDto } from 'modules/api/dto';

import { DialogActions } from './common';

export type ApiSearchMessagesSuccessPayload = {
    dialogId: string;
    searchedPhrase: string;
    result: SearchedDialogMessagesDto;
};
export type ApiSearchMessagesSuccessAction = {
    type: DialogActions;
    payload: ApiSearchMessagesSuccessPayload;
};
const apiSearchMessagesSuccess = (dialogId: string, searchedPhrase: string, result: SearchedDialogMessagesDto) => ({
    type: DialogActions.ApiSearchMessagesSuccess,
    payload: { dialogId, result, searchedPhrase },
});

export type ClearSearchedMessagesPayload = {
    dialogId: string;
};
export type ClearSearchedMessagesAction = {
    type: DialogActions;
    payload: ClearSearchedMessagesPayload;
};
const clearSearchedMessages = (dialogId: string) => ({
    type: DialogActions.ClearSearchedMessages,
    payload: { dialogId },
});

export type SwitchSearchedMessageIndexPayload = {
    dialogId: string;
    searchedMessageIndex: number;
};
export type SwitchSearchedMessageIndexAction = {
    type: DialogActions;
    payload: SwitchSearchedMessageIndexPayload;
};
const switchSearchedMessageIndex = (dialogId: string, searchedMessageIndex: number) => ({
    type: DialogActions.SwitchSearchedMessageIndex,
    payload: { dialogId, searchedMessageIndex },
});

export const searchMessagesActions = {
    apiSearchMessagesSuccess,
    clearSearchedMessages,
    switchSearchedMessageIndex,
};
