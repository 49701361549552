import { useEffect, useState } from 'react';

import { DialogModel } from 'modules/domain/dialog';
import { AutoGeneratedAnswersUsage } from 'modules/api/dto';

export const useCopilotOptionClicked = (message: string) => {
    const [optionClicked, setOptionClicked] = useState(false);

    const { copilot } = DialogModel.hooks.useSelectors();

    useEffect(() => {
        if (copilot.usage === AutoGeneratedAnswersUsage.Used) {
            setOptionClicked(true);
        }
    }, [
        copilot.usage,
        message, // we need to recheck optionClickedState on message change
    ]);

    useEffect(() => {
        if (optionClicked) {
            setOptionClicked(false);
        }
    }, [optionClicked]);

    return optionClicked;
};
