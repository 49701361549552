import pify from 'pify';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';
import { UserTag, UserTargetedTag } from 'modules/api/dto';

export const getUserTags = (api: SDVLegacyAPI, id: string) => {
    return pify(api.users.tags.get)(id) as Promise<UserTag[]>;
};

export const getUserRealmTags = (api: SDVLegacyAPI, id: string) => {
    return pify(api.users.tags.realms.get)(id) as Promise<string[]>;
};

export const getUserTargetedTags = (api: SDVLegacyAPI, holderId: string, targetId: string) => {
    return pify(api.users.targeted.tags.get)(holderId, targetId) as Promise<UserTargetedTag[]>;
};
