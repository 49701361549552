import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useApi } from 'modules/api';
import { UpdateDialogExplicitStatusPayload } from 'modules/api/payload';
import { useOperatorId } from 'modules/domain/auth';
import { getDialogId, useLogger } from 'modules/utils';

import { actions } from '../actions';

import { useDialogAnalytics } from './use-dialog-analytics';

export const useDialogExplicitStatus = () => {
    const dispatch = useDispatch();
    const { dialogs: dialogsApi } = useApi();
    const operatorId = useOperatorId();
    const { trackDialogIsExplicit } = useDialogAnalytics();
    const { logError } = useLogger('useDialogExplicitStatus');

    const setDialogExplicitStatus = useCallback(
        async (status: boolean, animatorId: string, attendeeId: string) => {
            const payload: UpdateDialogExplicitStatusPayload = { operatorId, animatorId, attendeeId, status };
            try {
                if (status) {
                    trackDialogIsExplicit(animatorId, attendeeId);
                }
                await dialogsApi.updateDialogExplicitStatus(payload);
                const dialogId = getDialogId(animatorId, attendeeId);
                dispatch(actions.setExplicitStatus(dialogId, status));
            } catch (error) {
                logError('Failed to set dialog explicit status', { error, payload });
            }
        },
        [dialogsApi, dispatch, operatorId, trackDialogIsExplicit, logError],
    );

    return {
        setDialogExplicitStatus,
    };
};
