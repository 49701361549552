import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { IOperatorsDomain, OperatorEarningsQueryParams } from './contracts';
import { EarningsDto, FullKPIDto, OperatorCommissionDto, OperatorDto } from './dto';

export class OperatorsDomain implements IOperatorsDomain {
    constructor(private readonly transport: IHttpTransport, private readonly realm: string) {}

    getOperator(operatorId: string): Promise<OperatorDto> {
        return this.transport
            .get<OperatorDto>(`/multichat/operators/${operatorId}`, {
                params: { realm: this.realm },
            })
            .then(response => response.data);
    }

    getOperatorCommission(operatorId: string): Promise<OperatorCommissionDto> {
        return this.transport
            .get<OperatorCommissionDto>(`/multichat/operators/${operatorId}/commission`, {
                params: { realm: this.realm },
            })
            .then(response => response.data);
    }

    getOperatorEarnings(operatorId: string, params: OperatorEarningsQueryParams): Promise<EarningsDto> {
        return this.transport
            .get<EarningsDto>(`/multichat/operators/${operatorId}/earnings`, {
                params: { realm: this.realm, ...params },
            })
            .then(response => response.data);
    }

    getOperatorKPI(operatorId: string, params: OperatorEarningsQueryParams): Promise<FullKPIDto> {
        return this.transport
            .get<FullKPIDto>(`/multichat/operators/${operatorId}/kpi`, {
                params: { realm: this.realm, ...params },
            })
            .then(response => response.data);
    }
}
