import { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { DialogModel } from 'modules/domain/dialog';
import { UserModel } from 'modules/domain/users';
import { RootState } from 'modules/App/store/root-state';
import { getDialogId } from 'modules/utils';
import { UserTag } from 'modules/api/dto';

import { getFetchMatchController } from './api';
import { getSpotifyMatch } from './selectors';
import { MatchType } from './types';
import * as actions from './actions';
import { hasError } from './utils';

export const useSpotifyMatchSelector = (animatorId?: string, attendeeId?: string) => {
    const matches = useSelector<RootState, { [x: string]: MatchType }>(state => getSpotifyMatch(state));

    if (!animatorId || !attendeeId) {
        return undefined;
    }

    return matches[getDialogId(animatorId, attendeeId)];
};

export const useSpotifyEnabledFeature = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { dialog = { attendeeId: '', animatorId: '' } } = DialogModel.hooks.useSelectors();
    const { user } = UserModel.hooks.useUserSelectorsById(dialog.attendeeId);
    const hasSpotifyTag = useMemo(() => {
        const attendeeTags = (user && user.tags) || [];

        return attendeeTags.includes(UserTag.SpotifyConnected);
    }, [user]);
    const match = useSpotifyMatchSelector(dialog.animatorId, dialog.attendeeId);

    useEffect(() => {
        if (!hasSpotifyTag || !dialog.animatorId || !dialog.attendeeId) {
            return;
        }

        const fetchMatch = getFetchMatchController(api, dialog.animatorId, dialog.attendeeId);

        fetchMatch.load().then(matchData => {
            if (matchData && !hasError(matchData)) {
                dispatch(actions.setMatch(matchData, dialog.animatorId, dialog.attendeeId));

                return;
            }
        });

        return () => {
            fetchMatch.unsubscribe();
        };
    }, [api, dialog.animatorId, dialog.attendeeId, hasSpotifyTag, dispatch]);

    return !!match;
};
