import { NotesKey } from 'modules/domain/dialog/types';

export const sectionTitles: Record<NotesKey, string> = {
    currentNews: 'Current News:',
    previousRelationship: 'Previous Relationship:',
    nicknames: 'Nicknames:',
    favTopics: 'Fav Topics:',
    triggers: 'Triggers:',
    work: 'Work:',
    family: 'Family:',
    importantDates: 'Important Dates:',
    habitsLifestyle: 'Habits & Lifestyle:',
    hobbiesInterests: 'Hobbies & Interests:',
    travel: 'Travel:',
    sexting: 'Sexting:',
    other: 'Other:',
};

export const focusOnElement = (rootEl: HTMLDivElement | null, key: string, id: number) => {
    setTimeout(() => {
        if (!rootEl) {
            return;
        }

        try {
            // @ts-expect-error expect focus on editable div element
            rootEl.querySelector(`[data-id="${key}-${id}"]`)?.focus();
        } catch (error) {}
    }, 50);
};
