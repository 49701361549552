import { DialogModel } from 'modules/domain/dialog';
import { AutomationProductName, DialogMessageMetaDto } from 'modules/api/dto';
import { checkCopilotIncluded } from 'modules/domain/dialog/helpers';
import { useCopilotAnalytics } from 'modules/domain/dialog/hooks';
import { showChatErrorToast } from 'modules/components/chat/helpers';
import { getDialogId } from 'modules/utils';

import { IndicatorStatus } from './use-typed-message-indicator';
import { useUnansweredUnpaidMessageCheck } from './use-unanswered-unpaid-message-check';
import { useHasForbiddenMessageCheck } from './use-has-forbidden-message-check';

const UNANSWERED_UNPAID_MESSAGE_REASON = 'possible topic';

export const useSendTextMessage = (animatorId: string, attendeeId: string, indicatorStatus: IndicatorStatus) => {
    const dialogId = getDialogId(animatorId, attendeeId);

    const { copilot } = DialogModel.hooks.useSelectors();
    const { sendMessage } = DialogModel.hooks.useDialogMessagesApi(dialogId);
    const { trackCopilotUsage } = useCopilotAnalytics(animatorId, attendeeId);
    const { checkIsUnansweredUnpaidMessage, setUnansweredUnpaidMessageSent } =
        useUnansweredUnpaidMessageCheck(dialogId);
    const checkHasForbiddenMessage = useHasForbiddenMessageCheck(dialogId);

    const sendTextMessage = (message: string): boolean => {
        if (checkHasForbiddenMessage(message)) {
            showChatErrorToast("You can't send this message to the client");
            return false;
        }

        if (indicatorStatus === IndicatorStatus.Bad) {
            showChatErrorToast('Your message length is not appropriate. Please adjust it to send the message.', {
                toastId: 'message-length-error-toast',
            });
            return false;
        }

        const messageMeta: DialogMessageMetaDto = {};

        if (checkCopilotIncluded(copilot)) {
            messageMeta.automation = {
                product: AutomationProductName.Copilot,
                bot: copilot.botName,
                usage: copilot.usage,
            };

            trackCopilotUsage(message, copilot);
        }

        if (checkIsUnansweredUnpaidMessage()) {
            messageMeta.reason = UNANSWERED_UNPAID_MESSAGE_REASON;

            setUnansweredUnpaidMessageSent();
        }

        sendMessage({ text: message, meta: messageMeta }).catch(() => {
            showChatErrorToast(
                `Failed to send a message: "${message}". Please check your connection and try again later`,
            );
        });

        return true;
    };

    return sendTextMessage;
};
