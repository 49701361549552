import React, { memo, useMemo } from 'react';
import { FieldAttributes, FormikState } from 'formik';
import classNames from 'classnames';

import styles from './index.module.scss';

type FormValues = { [key: string]: any };

type Props = {
    field?: FieldAttributes<any>;
    form: FormikState<FormValues>;
    className?: string;
};

export const MessageInput = memo((props: Props) => {
    const {
        field,
        form: { errors, touched },
        className: customStyles,
        ...rest
    } = props;
    const { name: fieldName } = field;

    const className = useMemo(
        () =>
            classNames(styles.container, customStyles, {
                [styles.required]: errors[fieldName] && touched[fieldName],
            }),
        [customStyles, errors, fieldName, touched],
    );

    return <textarea className={className} {...field} {...rest} />;
});
