import cn from 'classnames';
import React, { memo } from 'react';

import { testId, formatMoney } from 'modules/utils';

import styles from './index.module.css';

type Props = {
    money: number | null;
    explanation: string;
    explanationDetails?: string;
    explanationValue?: string;
    isBadValue: boolean;
    isGoodValue: boolean;
    // for autotests
    testIdPostfix: string;
};

export const Card = memo((props: Props) => {
    const { money, explanation, explanationValue, explanationDetails, isBadValue, testIdPostfix, isGoodValue } = props;
    return (
        <div className={styles.body}>
            <div className={styles.explanation}>
                <div
                    className={styles.explanationTitle}
                    {...testId(`income-page-card-explanation-title-${testIdPostfix}`)}
                >
                    {explanation}
                    <span
                        className={cn(styles.explanationValue, {
                            [styles.badValue]: isBadValue,
                            [styles.goodValue]: isGoodValue,
                        })}
                        {...testId(`income-page-card-explanation-value-${testIdPostfix}`)}
                    >
                        {explanationValue ? explanationValue : null}
                    </span>
                </div>
                <div className={styles.explanationDetails}>{explanationDetails}</div>
            </div>
            <div
                className={cn(styles.money, {
                    [styles.badValue]: isBadValue,
                    [styles.goodValue]: isGoodValue,
                })}
                {...testId(`income-page-card-money-${testIdPostfix}`)}
            >
                {money !== null ? `${isGoodValue ? '+' : ''}${formatMoney(money)}` : ''}
            </div>
        </div>
    );
});
