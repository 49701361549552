import React, { memo } from 'react';
import classNames from 'classnames';

import { AudioMessageContent } from 'modules/domain/dialog/types';
import { TextMessage } from 'modules/components/chat/text-message';
import { AudioPlayButton } from 'modules/components/chat/audio/play-button';
import { AudioFileStatus, useAudioMessagePlayer } from 'modules/domain/dialog/audio';

import styles from './styles.module.scss';
import { AccuracyText } from './accuracy-text';

type Props = {
    content: AudioMessageContent;
    outgoing?: boolean;
};

const TEXT_PLACEHOLDER = 'Generating transcription...';
const ERROR_TEXT =
    'Failed to convert the voice message to text. Please listen to the message by clicking the button below.';

export const AudioMessage = memo((props: Props) => {
    const {
        content: { basename, transcription: { text, error, score } = {} },
        outgoing,
    } = props;

    const { play, pause, status, downloadProgress } = useAudioMessagePlayer(basename, outgoing);

    const handlePlayBtnClick = () => {
        status === AudioFileStatus.playing ? pause() : play();
    };

    const textContent = error ? ERROR_TEXT : text || TEXT_PLACEHOLDER;
    const accuracy = error ? 0 : score;

    return (
        <div className={styles.audioMessage}>
            <TextMessage content={textContent} outgoing={outgoing} error={error} />
            {typeof accuracy === 'number' && <AccuracyText accuracy={accuracy} />}
            <div
                className={classNames(styles.bottomRow, {
                    [styles.outgoing]: outgoing,
                })}
            >
                <AudioPlayButton
                    progress={downloadProgress}
                    status={status}
                    appearance={outgoing ? 'primary' : 'secondary'}
                    onClick={handlePlayBtnClick}
                />
                <span className={styles.messageNote}>Voice Message</span>
            </div>
        </div>
    );
});
