import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { GetDialogMessagesQueryParams, IDialogsMessagesDomain, SendMessagePayload } from './contracts';
import { DialogMessageDto, SearchedDialogMessagesDto } from './dto';

export class DialogsMessagesDomain implements IDialogsMessagesDomain {
    constructor(private readonly transport: IHttpTransport) {}

    getDialogMessages(
        sender: string,
        recipient: string,
        params: GetDialogMessagesQueryParams,
    ): Promise<DialogMessageDto[]> {
        return this.transport
            .get<DialogMessageDto[]>(`/dialogs/messages/sender/${sender}/recipient/${recipient}`, {
                params,
                headers: {
                    accept: 'application/json+videos',
                },
            })
            .then(response => response.data || []);
    }

    sendMessage(sender: string, recipient: string, payload: SendMessagePayload): Promise<DialogMessageDto> {
        return this.transport
            .post<DialogMessageDto>(`/dialogs/messages/sender/${sender}/recipient/${recipient}`, payload)
            .then(response => response.data);
    }

    searchDialogMessages(sender: string, recipient: string, text: string): Promise<SearchedDialogMessagesDto> {
        return this.transport
            .put<SearchedDialogMessagesDto>(`/dialogs/messages/sender/${sender}/recipient/${recipient}/search`, {
                text,
            })
            .then(response => response.data);
    }

    markMessageAsRead(sender: string, recipient: string, messageId: number): Promise<void> {
        return this.transport
            .patch<void>(`/dialogs/messages/sender/${sender}/recipient/${recipient}/${messageId}`, {
                read: true,
            })
            .then(response => response.data);
    }
}
