import React, { memo } from 'react';
import classNames from 'classnames';

import { RoundButton } from 'modules/components/common/button/round';
import scrollToBottomIcon from 'images/icon-scroll-to-bottom.svg';

import styles from './index.module.css';

type Props = {
    hidden: boolean;
    onClick: () => void;
};

export const ScrollToBottomButton = memo((props: Props) => {
    const { hidden, onClick } = props;
    return (
        <div className={classNames(styles.wrapper, { [styles.hidden]: hidden })} onClick={onClick}>
            <RoundButton size="sm" theme="darker">
                <img src={scrollToBottomIcon} alt="" />
            </RoundButton>
        </div>
    );
});
