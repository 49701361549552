import moment from 'moment/moment';

import { DialogMessageDto } from 'modules/api/dto';

export function messagesHaveTheSameDate(
    message1: DialogMessageDto,
    message2: DialogMessageDto | null,
    timezone: number,
) {
    if (!message2) {
        return false;
    }
    const moment1 = moment.utc(message1.timestamp).utcOffset(timezone);
    const moment2 = moment.utc(message2.timestamp).utcOffset(timezone);

    return moment1.isSame(moment2, 'day');
}
