import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getUserMedia } from '../api';
import { actions } from '../actions';
import { apiMediaToMedia } from '../media-adapter';

import { useCurrentApi } from './use-current-api';

export const useMediaApi = () => {
    const dispatch = useDispatch();
    const { currentApi, appConfig } = useCurrentApi();

    const fetchMediaData = useCallback(
        async (userId: string) => {
            const mediaPayload = await getUserMedia(currentApi, userId);
            const media = apiMediaToMedia(appConfig, userId, mediaPayload?.files || []);

            dispatch(actions.setMediaList(userId, media));
        },
        [dispatch, currentApi, appConfig],
    );

    return {
        fetchMediaData,
    };
};
