import React, { useState, useEffect } from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
    title: string;
    content: React.ReactNode;
    id: string;
    isAnimator: boolean;
};

const STORAGE_KEY_PREFIX = 'notes_expanded_';
export const AccordionSection = (props: Props) => {
    const { title, content, id, isAnimator } = props;
    const STORAGE_KEY = `${STORAGE_KEY_PREFIX}_${isAnimator ? 'animator' : 'client'}_${id}`;
    const storageItem = sessionStorage.getItem(STORAGE_KEY);
    const savedState = storageItem ? JSON.parse(storageItem) : id === 'notes';
    const [expanded, setExpanded] = useState<boolean>(savedState);

    useEffect(() => {
        sessionStorage.setItem(STORAGE_KEY, expanded.toString());
    }, [expanded, STORAGE_KEY]);

    return (
        <div className={styles.accordionSection}>
            <div className={styles.accordionHeader} onClick={() => setExpanded(prevCollapsed => !prevCollapsed)}>
                <div className={styles.caption} {...testId(`accordion-title-${id}`)}>
                    {title}
                </div>
                <div className={cn(styles.collapseIcon, { [styles.expandedIcon]: !expanded })}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                            d="M8 10.7457L4 6.74559L4.74557 6L8 9.25453L11.2544 6L12 6.74559L8 10.7457Z"
                            fill="#616466"
                        />
                    </svg>
                </div>
            </div>
            {expanded ? (
                <div {...testId(`accordion-body-${id}`)} className={styles.accordionContent}>
                    {content}
                </div>
            ) : null}
        </div>
    );
};
