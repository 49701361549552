import React, { memo, PropsWithChildren } from 'react';

import styles from './index.module.css';

type Props = PropsWithChildren<unknown>;

export const Centerer = memo((props: Props) => {
    const { children } = props;
    return <div className={styles.centerer}>{children}</div>;
});
