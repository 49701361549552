import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAuth } from 'modules/domain/auth/use-auth';

import { actions } from '../actions';

const STORAGE_PREFIX = 'onboarding_completed_for_';

export function useOnboarding() {
    const { auth: operator } = useAuth();
    const storageKey = STORAGE_PREFIX + operator.id;
    const dispatch = useDispatch();

    const initializeOnboardingMode = useCallback(() => {
        const currentOnboardingRecord = localStorage.getItem(storageKey) !== 'ok';
        dispatch(actions.setUserOnboardingStatus(operator.id || '', currentOnboardingRecord));
        return currentOnboardingRecord;
    }, [storageKey, dispatch, operator.id]);

    const finishOnboarding = useCallback(() => {
        localStorage.setItem(storageKey, 'ok');
        dispatch(actions.setUserOnboardingStatus(operator.id || '', false));
    }, [storageKey, dispatch, operator.id]);

    return {
        initializeOnboardingMode,
        finishOnboarding,
    };
}
