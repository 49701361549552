import moment from 'moment';

import { LabelIds } from './label-ids';

export const LabelsConfig: {
    [key in LabelIds]: {
        text: string | ((templateParam: string | number) => string);
        tooltip?: string;
        backgroundColor: string;
        color?: string;
        testNodeId?: string;
    };
} = {
    [LabelIds.VIP]: {
        text: 'vip',
        backgroundColor: '#ffd100',
        tooltip: "You're communicating with a VIP client. Be creative!",
        color: '#000000',
        testNodeId: 'vip-client-short-label',
    },
    [LabelIds.FIRST_CHAT]: {
        text: 'first chat',
        tooltip:
            'This is the first chat for the User! Make it most involving and impressive so the User wants to use the app further more!',
        backgroundColor: '#9AFF98',
        color: '#000000',
    },
    [LabelIds.SEXTING]: {
        text: 'sexting',
        tooltip:
            'This user is available for sexting. If he starts sharing\nsexually explicit messages just keep the conversation going',
        backgroundColor: '#eb9cff',
        color: '#000000',
    },
    [LabelIds.CurrentTime]: {
        text: timezone => moment.utc().utcOffset(timezone).format('h a'),
        backgroundColor: '#2F3133',
        color: '#FFFFFF',
    },
    [LabelIds.FirstMessageDate]: {
        text: firstDialogMessageTimeStamp => `First Message: ${moment(firstDialogMessageTimeStamp).format('ll')}`,
        backgroundColor: '#2F3133',
        color: '#FFFFFF',
    },
    [LabelIds.Birthday]: {
        text: birthdayDate => `Birthday: ${moment(birthdayDate).format('ll')}`,
        backgroundColor: '#2F3133',
        color: '#FFFFFF',
    },
};
