import { useEffect } from 'react';

import { UserModel } from 'modules/domain/users';

const pingDelay = 30 * 1000; // 30 sec

export const useOperatorPinger = () => {
    const { ping } = UserModel.hooks.useUserApi();

    useEffect(() => {
        ping();

        const timer = setInterval(() => {
            ping();
        }, pingDelay);

        return () => clearInterval(timer);
    }, [ping]);

    return {};
};
