import { EntitiesState, ModelState } from 'modules/domain/common/types';

export type ApiMedia = {
    basename: string;
    tags: string[];
    mediatype: string;
    created: string;
};

export type ApiMediaResponse = {
    files: ApiMedia[];
};

export enum SentMediaType {
    chat = 'chat',
    custom = 'custom',
}
export type ApiSentMedia = {
    baseName: string;
    type: SentMediaType;
};

export type ApiSentMediaResponse = {
    result: boolean;
    sent: ApiSentMedia[];
};

export type SentMedia = {
    id: string;
    url: string;
    thumbnail: string;
    type: SentMediaType;
};

export type Media = {
    id: string;
    url: string | null;
    isThumbnail: boolean;
    isPrivate: boolean;
    configuration: {
        backgroundColor?: string;
    };
};

export type ChatMedia = {
    id: string;
    url: string;
    thumbnail: string;
};

export type ChatMediaEntity = {
    list: ChatMedia[];
    sentMedia: SentMedia[];
    enabled?: boolean;
    available: boolean;
};

export type MediaState<Entity> = ModelState<Entity> & {
    chatEntities: EntitiesState<ChatMediaEntity>;
};
