import { DialogMessageBaseDto, DialogMessageDto } from 'modules/api/dto';

import { DialogActions } from './common';

// Send message
export type ApiMessageSentPayload = {
    dialogId: string;
    message: DialogMessageBaseDto;
};
export type ApiMessageSentAction = {
    type: DialogActions.ApiMessageSent;
    payload: ApiMessageSentPayload;
};

export function apiMessageSent(dialogId: string, message: DialogMessageBaseDto): ApiMessageSentAction {
    return {
        type: DialogActions.ApiMessageSent,
        payload: { dialogId, message },
    };
}

export type ApiSendMessageFailurePayload = { dialogId: string; messageTag: string; dialogForceLocked: boolean };
export type ApiSendMessageFailureAction = {
    type: DialogActions.ApiSendMessageFailure;
    payload: ApiSendMessageFailurePayload;
};

export function apiSendMessageFailure(
    dialogId: string,
    messageTag: string,
    dialogForceLocked: boolean,
): ApiSendMessageFailureAction {
    return { type: DialogActions.ApiSendMessageFailure, payload: { dialogId, messageTag, dialogForceLocked } };
}

export type SetMessagesPayload = { dialogId: string; messages: DialogMessageDto[] };
export type SetMessagesAction = {
    type: DialogActions.SetMessages;
    payload: SetMessagesPayload;
};

export function setMessages(dialogId: string, messages: DialogMessageDto[]): SetMessagesAction {
    return { type: DialogActions.SetMessages, payload: { dialogId, messages } };
}

export type SetNoMoreMessagesPayload = { dialogId: string };
export type SetNoMoreMessagesAction = {
    type: DialogActions.SetNoMoreMessages;
    payload: SetNoMoreMessagesPayload;
};

export function setNoMoreMessages(dialogId: string): SetNoMoreMessagesAction {
    return { type: DialogActions.SetNoMoreMessages, payload: { dialogId } };
}

export type SetUnpaidUnansweredMessagePayload = {
    dialogId: string;
    unpaidUnansweredMessage: DialogMessageDto;
};
export type SetUnpaidUnansweredMessageAction = {
    type: DialogActions.SetUnpaidUnansweredMessage;
    payload: SetUnpaidUnansweredMessagePayload;
};

export function setUnpaidUnansweredMessage(
    dialogId: string,
    unpaidUnansweredMessage: DialogMessageDto,
): SetUnpaidUnansweredMessageAction {
    return {
        type: DialogActions.SetUnpaidUnansweredMessage,
        payload: { dialogId, unpaidUnansweredMessage },
    };
}

export type ApiUploadMediaStartPayload = {
    dialogId: string;
    message: DialogMessageBaseDto;
};
export type ApiUploadMediaStartAction = {
    type: DialogActions.ApiUploadMediaStart;
    payload: ApiUploadMediaStartPayload;
};

export function apiUploadMediaStart(dialogId: string, message: DialogMessageBaseDto): ApiUploadMediaStartAction {
    return { type: DialogActions.ApiUploadMediaStart, payload: { dialogId, message } };
}

export type ApiUploadMediaSuccessPayload = {
    dialogId: string;
    messageTag: string;
};
export type ApiUploadMediaSuccessAction = {
    type: DialogActions.ApiUploadMediaSuccess;
    payload: ApiUploadMediaSuccessPayload;
};

export function apiUploadMediaSuccess(dialogId: string, messageTag: string): ApiUploadMediaSuccessAction {
    return { type: DialogActions.ApiUploadMediaSuccess, payload: { dialogId, messageTag } };
}

export type ApiUploadMediaFailurePayload = { dialogId: string; messageTag: string };
export type ApiUploadMediaFailureAction = {
    type: DialogActions.ApiUploadMediaFailure;
    payload: ApiUploadMediaFailurePayload;
};

export function apiUploadMediaFailure(dialogId: string, messageTag: string): ApiUploadMediaFailureAction {
    return { type: DialogActions.ApiUploadMediaFailure, payload: { dialogId, messageTag } };
}

export type ApiUploadMediaSetProgressPayload = { dialogId: string; messageTag: string; progress: number };
export type ApiUploadMediaSetProgressAction = {
    type: DialogActions.ApiUploadMediaSetProgress;
    payload: ApiUploadMediaSetProgressPayload;
};

export function apiUploadMediaSetProgress(
    dialogId: string,
    messageTag: string,
    progress: number,
): ApiUploadMediaSetProgressAction {
    return { type: DialogActions.ApiUploadMediaSetProgress, payload: { dialogId, messageTag, progress } };
}

export const apiMessageActions = {
    setMessages,
    setNoMoreMessages,
    apiMessageSent,
    apiSendMessageFailure,
    setUnpaidUnansweredMessage,
    apiUploadMediaStart,
    apiUploadMediaSuccess,
    apiUploadMediaFailure,
    apiUploadMediaSetProgress,
};
