import { useMemo } from 'react';

import { DialogMessage, MessageType } from 'modules/domain/dialog/types';

export enum IndicatorStatus {
    Good = 'good',
    Normal = 'normal',
    Bad = 'bad',
    None = 'none',
}

type TypedMessageIndicator = {
    status: IndicatorStatus;
    percent: number;
};

type MinMaxCutoffs = {
    MIN_NORMAL: number;
    MIN_GOOD: number;
    MAX_GOOD: number;
    MAX_NORMAL: number;
};

/**
 * Minimum message length to apply indicator status calculation
 * depending on message to last message length percent
 */
const MIN_LENGTH_FOR_RELATIVE_INDICATOR = 10;

/**
 * Minimum and maximum message to last message length percent cutoffs
 */
const MIN_MAX_PERCENT_CUTOFFS: MinMaxCutoffs = {
    MIN_NORMAL: 30,
    MIN_GOOD: 70,
    MAX_GOOD: 114,
    MAX_NORMAL: 299,
};

/**
 * Min and max message absolute length cutoffs
 */
const MIN_MAX_ABSOLUTE_CUTOFFS: MinMaxCutoffs = {
    MIN_NORMAL: 10,
    MIN_GOOD: 20,
    MAX_GOOD: 49,
    MAX_NORMAL: 109,
};

const getIndicatorStatus = (messageLength: number, cutoffs: MinMaxCutoffs): IndicatorStatus => {
    const { MIN_NORMAL, MIN_GOOD, MAX_GOOD, MAX_NORMAL } = cutoffs;

    if (!messageLength) {
        return IndicatorStatus.None;
    }
    if (messageLength >= MIN_GOOD && messageLength <= MAX_GOOD) {
        return IndicatorStatus.Good;
    }
    if (messageLength >= MIN_NORMAL && messageLength <= MAX_NORMAL) {
        return IndicatorStatus.Normal;
    }
    return IndicatorStatus.Bad;
};

const calculatePercent = (messageLength: number, messageMaxLength: number) => {
    if (messageLength >= messageMaxLength) {
        return 100;
    }
    return Math.floor((messageLength / messageMaxLength) * 100);
};

/**
 * Implements the indicator logic described here: https://knowledge.sdventures.com/pages/viewpage.action?pageId=179517853
 */
export const useTypedMessageIndicator = (
    messageText: string,
    lastDialogMessage: DialogMessage | undefined,
): TypedMessageIndicator => {
    const currentMessageLength = messageText.length;
    const lastMessageLength = typeof lastDialogMessage?.content === 'string' ? lastDialogMessage.content.length : 0;

    const isLastMessageFromClient = !!lastDialogMessage && !lastDialogMessage.outgoing;

    const shouldUseRelativePercent =
        isLastMessageFromClient &&
        lastDialogMessage.type === MessageType.TEXT &&
        lastMessageLength >= MIN_LENGTH_FOR_RELATIVE_INDICATOR;

    return useMemo(() => {
        const getStatus = () => {
            if (shouldUseRelativePercent) {
                const currentToLastMessageLengthPercent = Math.round((currentMessageLength / lastMessageLength) * 100);

                return getIndicatorStatus(currentToLastMessageLengthPercent, MIN_MAX_PERCENT_CUTOFFS);
            }

            return getIndicatorStatus(currentMessageLength, MIN_MAX_ABSOLUTE_CUTOFFS);
        };

        const getPercent = () => {
            if (shouldUseRelativePercent) {
                const relativeMessageMaxLength = Math.round(
                    (lastMessageLength / 100) * MIN_MAX_PERCENT_CUTOFFS.MIN_GOOD,
                );

                return calculatePercent(currentMessageLength, relativeMessageMaxLength);
            }

            return calculatePercent(currentMessageLength, MIN_MAX_ABSOLUTE_CUTOFFS.MIN_GOOD);
        };

        return {
            status: getStatus(),
            percent: getPercent(),
        };
    }, [lastMessageLength, currentMessageLength, shouldUseRelativePercent]);
};
