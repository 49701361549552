import React, { memo } from 'react';

import fallbackImage from 'images/image.svg';
import { Image } from 'modules/components/image';
import { ImageOverlay } from 'modules/components/image-overlay';

import { ImageParams, useImageUri } from './use-image-uri';
import styles from './index.module.scss';

type Props = {
    basename: string;
    sender: string;
    recipient: string;
};

const CHAT_IMAGE_SIZE = 100;

export const ImageMessage = memo((props: Props) => {
    const { basename, sender, recipient } = props;

    // Memos
    const imageParams: ImageParams = {
        basename,
        recipient,
        sender,
    };

    const imageSrc = useImageUri({ ...imageParams, size: CHAT_IMAGE_SIZE });
    const overlayImageSrc = useImageUri(imageParams);

    return (
        <ImageOverlay className={styles.overlay} src={overlayImageSrc}>
            <Image
                a11yLabel={`chat-image-message-${basename}`}
                fallback={fallbackImage}
                src={imageSrc}
                alt={basename}
                className={styles.chatImageMessage}
            />
        </ImageOverlay>
    );
});
