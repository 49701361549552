import React, { memo, useEffect, useState } from 'react';

import { DialogModel } from 'modules/domain/dialog';
import { UserModel } from 'modules/domain/users';
import { Operator } from 'modules/domain/users/types';

import { useDataPrefetch } from './hooks/use-data-prefetch';
import { useOperatorPinger } from './hooks/use-operator-pinger';
import { ChatWithEmptyStates } from './chat-with-empty-states';
import './styles.scss';

export const MultichatWindow = memo(() => {
    // Hooks
    useOperatorPinger();
    useDataPrefetch();

    const { dialog, foundDialogId, lastBotMessage, dialogsQueueIsEmpty } = DialogModel.hooks.useSelectors();
    const { changeLastBotMessage } = DialogModel.hooks.useDialogActionsWithBotAnswer();
    const { getOperatorFields } = UserModel.hooks.useUserApi();

    const searchMode = !!foundDialogId;
    const animatorId = dialog?.animatorId;
    const noNewChats = dialogsQueueIsEmpty && !searchMode;

    // Memo
    const dialogMessages = dialog?.messages || [];
    const lastBotUnansweredMessage =
        dialogMessages[0]?.sender === animatorId && dialogMessages[0]?.meta?.automation?.product === 'bot';

    // States
    const [messagesCount, setMessagesCount] = useState(dialogMessages.length || 0);
    const [operator, setOperator] = useState<Operator | null>(null);

    // Effects
    useEffect(() => {
        if (dialogMessages.length === messagesCount) {
            return;
        }
        setMessagesCount(dialogMessages.length);
        if (!lastBotMessage && lastBotUnansweredMessage) {
            changeLastBotMessage(true);
        }
        if (lastBotMessage && !lastBotUnansweredMessage) {
            changeLastBotMessage(false);
        }
    }, [changeLastBotMessage, dialogMessages.length, lastBotMessage, lastBotUnansweredMessage, messagesCount]);

    useEffect(() => {
        getOperatorFields().then(setOperator);
    }, [getOperatorFields]);

    return (
        <div className="multichat-window">
            <ChatWithEmptyStates noNewChats={noNewChats} dialog={dialog} operator={operator} />
        </div>
    );
});
