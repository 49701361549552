import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';
import { DictionaryTribes } from 'modules/domain/dictionaries';
import { getDictionaries } from 'modules/domain/dictionaries/selectors';
import { getUser } from 'modules/domain/users/selectors';
import { User } from 'modules/domain/users/types';
import { Dictionary, DictionaryOptions } from 'modules/domain/dictionaries/types';
import { EntityMap } from 'modules/domain/common/types';

export const useUserSelectorsById = (userId: string) => {
    const user = useSelector((state: RootState) => getUser(state, userId));
    const dictionaries = useSelector((state: RootState) => getDictionaries(state));

    const populatedUser = useMemo(() => populateUserFromDictionary(user, dictionaries), [user, dictionaries]);

    return {
        user,
        populatedUser,
    };
};

function populateUserFromDictionary(user: User, dictionaries: EntityMap<Dictionary>) {
    if (!user || !dictionaries) {
        return user;
    }

    const mergedDictionaries: EntityMap<Dictionary> = {
        ...dictionaries,
        interests: { ...dictionaries.interests, ...DictionaryTribes },
    };

    return {
        ...user,
        info: {
            ...user.info,
            eye: mergedDictionaries[DictionaryOptions.Eyes]?.[user.info?.eye],
            hair: mergedDictionaries[DictionaryOptions.Hairs]?.[user.info?.hair],
            drink: mergedDictionaries[DictionaryOptions.Drinking]?.[user.info?.drink],
            smoke: mergedDictionaries[DictionaryOptions.Smoke]?.[user.info?.smoke],
            education: mergedDictionaries[DictionaryOptions.Educations]?.[user.info?.education],
            relationship: mergedDictionaries[DictionaryOptions.Relationships]?.[user.info?.relationship],
            bodytype: mergedDictionaries[DictionaryOptions.BodyType]?.[user.info?.bodytype],
            languages: user.info?.languages?.map(lang => mergedDictionaries[DictionaryOptions.Languages]?.[lang]),
            interests: user.info?.interests?.map(it => mergedDictionaries[DictionaryOptions.Interests]?.[it] || it),
        },
    };
}
