import React from 'react';
import { Helmet } from 'react-helmet';

import { useConfig } from 'modules/config';

export const Head = () => {
    const { revision } = useConfig();

    return (
        <Helmet>
            <title>Multichat {revision}</title>
        </Helmet>
    );
};
