import React, { memo } from 'react';

import { AuthContext } from 'modules/domain/auth/context';
import { useAuthStorage } from 'modules/domain/auth/use-auth-storage';

export const AuthProvider = memo(({ children }) => {
    const { auth, setAuthInfo, clearAuthInfo } = useAuthStorage();

    return <AuthContext.Provider value={{ auth, setAuthInfo, clearAuthInfo }}>{children}</AuthContext.Provider>;
});
