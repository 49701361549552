import { RootState } from 'modules/App/store/root-state';

import { namespace } from './actions';
import { User } from './types';

export const getUser = (state: RootState, id: string): User => {
    return state[namespace].entities.byId[id] || {};
};

export const selectors = {
    getUser,
};
