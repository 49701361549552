import { createSelector } from 'reselect';

import { EntityMap } from 'modules/domain/common/types';
import { RootState } from 'modules/App/store/root-state';
import { DialogMessageDto } from 'modules/api/dto';

import { namespace } from './common';
import {
    Dialog,
    DialogModelState,
    FastAnswerInTypedMessage,
    Metrics,
    Note,
    Preference,
    ClientImprovedNote,
} from './types';
import { DialogMediaMessage } from './types/dialog';

export const getDialogState = (state: RootState): DialogModelState => state[namespace];
export const getDialogMap = (state: RootState): EntityMap<Dialog> => getDialogState(state).entities.byId;
export const getDialogList = (state: RootState): Dialog[] => Object.values(getDialogMap(state));

export const getDialog = (state: RootState, dialogId: string): Dialog => getDialogMap(state)[dialogId];
export const getCurrentDialogId = (state: RootState): string => getDialogState(state).currentDialogId;
export const getLastBotMessage = (state: RootState): boolean => getDialogState(state).lastBotMessage;
export const getDialogsQueueIsEmpty = (state: RootState): boolean => getDialogState(state).dialogsQueueIsEmpty;
export const getCanToggleExplicit = (state: RootState): boolean => getDialogState(state).canToggleExplicit;
export const getTypedMessage = (state: RootState): string => getDialogState(state).typedTextMessage.content;
export const getIsDialogInputFocused = (state: RootState): boolean => getDialogState(state).dialogInputIsFocused;
export const getScrollToMessageId = (state: RootState): number | null => getDialogState(state).scrollToMessageId;

export const getCurrentDialogIsForceLocked = (state: RootState): boolean =>
    getDialogState(state).currentDialogForceLocked;
export const getFastAnswerInTypedMessage = (state: RootState): null | FastAnswerInTypedMessage =>
    getDialogState(state).typedTextMessage.fastAnswer;
export const getCurrentDialog = (state: RootState): Dialog => getDialog(state, getCurrentDialogId(state));
export const getFoundDialogId = (state: RootState): string | null => getDialogState(state).foundDialogId;
export const getFoundDialog = (state: RootState): Dialog | null => {
    const id = getFoundDialogId(state);
    return id ? getDialog(state, id) : null;
};
export const getCopilotState = (state: RootState) => getDialogState(state).copilot;

// first preference: answered sort by newest first
export const getAnsweredDialogIdList = (state: RootState): string[] =>
    getDialogList(state)
        .filter(dialog => dialog.answered)
        .sort((a, b) => b.timestamp - a.timestamp)
        .map(dialog => dialog.id);
// second preference: unanswered sort by newest last
export const getUnansweredDialogIdList = (state: RootState): string[] =>
    getDialogList(state)
        .filter(dialog => !dialog.answered)
        .sort((a, b) => a.timestamp - b.timestamp)
        .map(dialog => dialog.id);
// queue = [...answeredIds, ...unansweredIds]
export const getSortedDialogIdList = createSelector(
    getAnsweredDialogIdList,
    getUnansweredDialogIdList,
    (answered, unanswered) => {
        return [...answered, ...unanswered];
    },
);

export const getMessageList = (state: RootState, dialogId: string): DialogMessageDto[] =>
    getDialog(state, dialogId)?.messages || [];

export const getMediaMessage = (state: RootState, dialogId: string, messageTag: string): DialogMediaMessage | null =>
    getDialog(state, dialogId)?.mediaMessages?.[messageTag] || null;

// eslint-disable-next-line @typescript-eslint/ban-types
export const getNotes = (state: RootState, dialogId: string): Note | {} => getDialog(state, dialogId)?.notes ?? {};
export const getNotesImproved = (state: RootState, dialogId: string): ClientImprovedNote | null =>
    getDialog(state, dialogId)?.notesImproved ?? {};

// eslint-disable-next-line @typescript-eslint/ban-types
export const getPreferences = (state: RootState, dialogId: string): Preference | {} => {
    return getDialog(state, dialogId)?.preferences || {};
};

export const getNoMoreMessages = (state: RootState, dialogId: string): boolean =>
    !!getDialog(state, dialogId)?.noMoreMessages ?? false;

export const getDialogsMetrics = (state: RootState): Metrics => getDialogState(state).metrics;

export const getSearchedMessages = (state: RootState, dialogId: string) => getDialog(state, dialogId)?.searchedMessages;

export const getUnansweredUnpaidState = (state: RootState, dialogId: string): boolean =>
    getDialog(state, dialogId)?.unansweredUnpaid ?? false;

export const getUnansweredUnpaidMessage = (state: RootState, dialogId: string): DialogMessageDto | null => {
    return getDialog(state, dialogId)?.unansweredUnpaidMessage ?? null;
};

export const getSomeDialogMessagesWereLoaded = (state: RootState, dialogId: string): boolean => {
    return getDialog(state, dialogId)?.someMessagesFromTheDialogWereLoaded ?? false;
};

export const selectors = {
    getDialog,
    getDialogMap,
    getDialogList,
    getAnsweredDialogIdList,
    getUnansweredDialogIdList,
    getLastBotMessage,
    getSortedDialogIdList,
    getCurrentDialog,
    getCurrentDialogId,
    getMessageList,
    getMediaMessage,
    getNoMoreMessages,
    getFoundDialogId,
    getFoundDialog,
    getNotes,
    getDialogsMetrics,
    getSearchedMessages,
    getUnansweredUnpaidState,
    getUnansweredUnpaidMessage,
    getSomeDialogMessagesWereLoaded,
    getScrollToMessageId,
};
