import { ImprovedNoteEntity } from 'modules/domain/dialog/types/notes';

/**
    We need an independent instance of the default_note every time, that's why the function
*/
export function generateDefaultNotes() {
    const DEFAULT_NOTE: ImprovedNoteEntity = {
        currentNews: [],
        previousRelationship: [],
        nicknames: [],
        favTopics: [],
        triggers: [],
        work: [],
        family: [],
        importantDates: [],
        habitsLifestyle: [],
        hobbiesInterests: [],
        travel: [],
        sexting: [],
        other: [],
    };

    return DEFAULT_NOTE;
}
