import React, { useRef, useLayoutEffect, useState } from 'react';
import cn from 'classnames';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';
import { CloseIcon } from './CloseIcon';
import { AutoFineMessage } from './AutoFineMessage';

type Prop = {
    text: string;
    secondShortcutPart: number;
    onClick: () => void;
    showTrapModal: boolean;
    onCloseTrapModal: () => void;
    trapPenalty: number;
};

const OPTIONAL_MODAL_SIZE = 360;

export const CopilotOption = (prop: Prop) => {
    const { trapPenalty, text, secondShortcutPart, onClick, showTrapModal, onCloseTrapModal } = prop;

    const [potentialOverflow, setPotentialOverflow] = useState<boolean>(false);

    const optionRef = useRef<HTMLDivElement>(null);

    useLayoutEffect(() => {
        const optionElement = optionRef.current;
        if (!optionElement) {
            return;
        }

        const containerElement = optionElement?.parentElement;

        if (!containerElement) {
            return;
        }

        const optionRect = optionElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();

        const distance = containerRect.right - optionRect.left;

        if (distance < OPTIONAL_MODAL_SIZE) {
            setPotentialOverflow(true);
        } else {
            setPotentialOverflow(false);
        }
    }, []);

    return (
        <div className={styles.optionContainer} ref={optionRef}>
            <div
                className={cn(styles.option, { [styles.activeTrap]: showTrapModal })}
                onClick={onClick}
                {...testId(`copilot-option-${secondShortcutPart}`)}
            >
                <div {...testId('copilot-option-text')} className={styles.optionText}>
                    {text}
                </div>
                <div className={styles.hotkey} {...testId('copilot-option-hotkey')}>
                    Alt + {secondShortcutPart}
                </div>
            </div>
            {showTrapModal ? (
                <div className={cn(styles.trapModal, potentialOverflow ? styles.dockToRight : styles.dockToLeft)}>
                    <AutoFineMessage penalty={trapPenalty} />
                    <div className={styles.closeButton} onClick={onCloseTrapModal}>
                        <CloseIcon />
                    </div>
                </div>
            ) : null}
        </div>
    );
};
