import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { EntityMap, ModelState, MultiChatReducer } from '../common/types';

import { namespace, DictionaryActions, SetDictionaryPayload } from './actions';
import { Dictionary } from './types';

type CommonPayload = SetDictionaryPayload;

interface CustomReducerMap extends ReducerMap<ModelState<Dictionary>, CommonPayload> {
    [DictionaryActions.Set]: ReducerMapValue<ModelState<Dictionary>, SetDictionaryPayload>;
}

const reducerMapping: CustomReducerMap = {
    [DictionaryActions.Set]: (state, { payload }) =>
        update(state, {
            entities: {
                byId: {
                    $apply: (map: EntityMap<Dictionary>) => {
                        const newState = { ...map };
                        newState[payload.name] = payload.input;

                        return newState;
                    },
                },
            },
        }),
};

export const reducer: MultiChatReducer<ModelState<Dictionary>, CommonPayload> = {
    [namespace]: handleActions(reducerMapping, { entities: { byId: {} } }),
};
