import { PatchApiImprovedNoteEntity, ImprovedNoteEntity, NotesKey } from '../../types';

import { generateDefaultNotes } from './constants';

export function transformClientNoteToPatchApiNote(data: ImprovedNoteEntity): PatchApiImprovedNoteEntity {
    const keys = Object.keys(generateDefaultNotes()) as NotesKey[];

    return keys.reduce((acc, noteKey) => {
        const values: string[] = (data[noteKey] || []).map(note => note.text);

        acc[noteKey] = { values };

        return acc;
    }, {} as PatchApiImprovedNoteEntity);
}
