import { useCallback } from 'react';

import { useAnnalsApi } from 'modules/api';

export const useFastAnswersAnalytics = () => {
    const { send } = useAnnalsApi();

    const trackChangesOfFastAnswers = useCallback(
        (fastAnswersPayload: object) => {
            return send('multichat-fast-answers-changed', {
                content: fastAnswersPayload,
            });
        },
        [send],
    );

    return { trackChangesOfFastAnswers };
};
