import url from 'url';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

export const getUrl = (api: SDVLegacyAPI, pathname: string, query?: any) => {
    return url.format({
        protocol: 'https',
        hostname: api.getBaseHost().replace('//', ''),
        pathname,
        query,
    });
};
