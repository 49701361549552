import React, { memo, useCallback } from 'react';

import { UserModel } from 'modules/domain/users';
import { testId } from 'modules/utils/test-id';

import { InfoBlock } from './info-block';
import { AdditionalInfoBlock } from './additional-info-block';
import styles from './index.module.scss';

type Props = {
    ownerId: string;
};

export const UserTabInfo = memo((props: Props) => {
    const { ownerId } = props;

    const {
        populatedUser: { info: userInfo, occupation },
    } = UserModel.hooks.useUserSelectorsById(ownerId);

    const renderBlocks = useCallback(() => {
        const blocks: JSX.Element[] = [];

        if (userInfo?.aboutMyPartner) {
            blocks.push(
                <InfoBlock key="about-my-partner" title="About My Partner">
                    {userInfo.aboutMyPartner}
                </InfoBlock>,
            );
        }

        if (userInfo?.aboutMe) {
            blocks.push(
                <InfoBlock key="about-me" title="About Me">
                    {userInfo.aboutMe}
                </InfoBlock>,
            );
        }

        if (userInfo?.interests?.length > 0) {
            blocks.push(
                <InfoBlock key="interests" title="Interests">
                    {userInfo.interests.join(', ')}
                </InfoBlock>,
            );
        }

        blocks.push(<AdditionalInfoBlock key="info" info={userInfo} occupation={occupation} />);

        return blocks;
    }, [userInfo, occupation]);

    return (
        <div {...testId(`user-tab-info-${ownerId}`)} className={styles.container}>
            {renderBlocks()}
        </div>
    );
});
