import { reducer } from './reducer';
import { actions } from './actions';
import { selectors } from './selectors';
import * as hooks from './hooks';

export const MediaModel = {
    reducer,
    actions,
    selectors,
    hooks,
};
