import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { Dictionary, AllowedDictionaryLanguages } from './types';

const APIAllowedLanguages: Record<AllowedDictionaryLanguages, string> = {
    en: 'EN-en',
    fr: 'FR-fr',
    de: 'DE-de',
};

export const getDictionary = (api: SDVLegacyAPI, dictionaryName: string, language: string) => {
    return new Promise<Dictionary>((resolve, reject) => {
        api.dict.get(
            dictionaryName,
            (error: unknown, response: Dictionary) => {
                if (error) {
                    return reject({});
                }

                return resolve(response);
            },
            {
                'Accept-Language': APIAllowedLanguages[language as AllowedDictionaryLanguages] || 'en',
            },
        );
    });
};
