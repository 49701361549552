import { useEffect } from 'react';

import { useAudioMessagesTemplates } from 'modules/domain/dialog/audio';

export const useAudioMessagesAvailable = () => {
    const { audioMessagesTemplates, loadAudioMessagesTemplates } = useAudioMessagesTemplates();

    useEffect(() => {
        loadAudioMessagesTemplates(true);
    }, [loadAudioMessagesTemplates]);

    return !!audioMessagesTemplates?.length;
};
