import { Api } from 'modules/api';
import { ApiEventsType } from 'modules/api/dto';
import { log, parseDialogId } from 'modules/utils';

export enum TypingSource {
    ChatInput = 'ChatInput',
    AudioPicker = 'AudioPicker',
}

export class TypingTracker {
    private static instance: TypingTracker;

    static shared(api: Api) {
        if (!this.instance) {
            this.instance = new TypingTracker(api);
        }
        return this.instance;
    }

    private activeTypings: Record<string, TypingSource[]> = {};

    private constructor(private readonly api: Api) {
        //
    }

    setTypingStart(dialogId: string, source: TypingSource) {
        if (!this.activeTypings[dialogId]?.length) {
            this.activeTypings[dialogId] = [source];

            this.postEvent(ApiEventsType.TypingStarted, dialogId);
        } else if (!this.activeTypings[dialogId].includes(source)) {
            this.activeTypings[dialogId].push(source);
        }
    }

    setTypingEnd(dialogId: string, source: TypingSource) {
        if (!this.activeTypings[dialogId]?.includes(source)) {
            return;
        }

        this.activeTypings[dialogId] = this.activeTypings[dialogId].filter(s => s !== source);

        if (!this.activeTypings[dialogId].length) {
            this.postEvent(ApiEventsType.TypingEnded, dialogId);
        }
    }

    private postEvent(event: ApiEventsType.TypingStarted | ApiEventsType.TypingEnded, dialogId: string) {
        const { attendeeId, animatorId } = parseDialogId(dialogId);

        this.api.events
            .postEvent(attendeeId, {
                label: event,
                payload: { sender: animatorId },
            })
            .catch(error => {
                log('error', `Failed to send ${event} event`, {
                    animatorId,
                    attendeeId,
                    error,
                    service: 'TypingTracker',
                    apiHost: this.api.host,
                });
            });
    }
}
