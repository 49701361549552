import React, { memo, useMemo } from 'react';

// the back icon is just a rotated first one, we could get rid of the first
import { BackChevronIcon, ForwardChevronIcon } from 'modules/components/icon';

import styles from './index.module.scss';

type Props = {
    direction: 'next' | 'prev';
    onClick: () => void;
};

export const FastAnswersSliderSwitchButton = memo((props: Props) => {
    const { direction, onClick } = props;
    const icon = useMemo(
        () =>
            ({
                next: <ForwardChevronIcon />,
                prev: <BackChevronIcon />,
            }[direction]),
        [direction],
    );

    return (
        <button type="button" className={styles.container} onClick={onClick}>
            {icon}
        </button>
    );
});
