import { Calendar, TileContentFunc, TileDisabledFunc, TileClassNameFunc } from 'react-calendar';
import { Value } from 'react-calendar/src/shared/types';
import moment from 'moment/moment';
import React from 'react';

type Props = {
    onChange: (date: Value) => void;
    tileDisabled: TileDisabledFunc;
    tileClassName: TileClassNameFunc;
    tileContent: TileContentFunc;
    activeStartDate: Date;
};

export const CalendarWrapper = (props: Props) => {
    const { onChange, tileDisabled, tileClassName, tileContent, activeStartDate } = props;

    return (
        <Calendar
            formatShortWeekday={(locale, date) => moment(date).format('dd')}
            onChange={onChange}
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
            tileContent={tileContent}
            view={'month'}
            showNavigation={false}
            activeStartDate={activeStartDate}
        />
    );
};
