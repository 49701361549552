import { ClientImprovedNote, GetApiImprovedNoteData } from '../../types';

import { transformGetApiNoteToClientNote } from './transformGetApiNoteToClientNote';
import { generateDefaultNotes } from './constants';

export const getClientNoteFromApiResponse = (
    data: GetApiImprovedNoteData,
    animatorId: string,
    attendeeId: string,
): ClientImprovedNote => {
    return {
        [attendeeId]: data?.user?.note ? transformGetApiNoteToClientNote(data.user.note) : generateDefaultNotes(),
        [animatorId]: data?.animator?.note
            ? transformGetApiNoteToClientNote(data.animator.note)
            : generateDefaultNotes(),
    };
};
