import { ModelState } from 'modules/domain/common/types';
import { UserLocationDto } from 'modules/api/dto';

import { namespace } from './actions';
import { AnimatorAliasesState, GeolocationState } from './types';

export const getAnimatorLocationsMap = (state: any): ModelState<AnimatorAliasesState> => {
    return state[namespace];
};

export const getAnimatorLocationMap = (state: any, animatorId: string): AnimatorAliasesState => {
    const animatorLocationsMap = getAnimatorLocationsMap(state).entities;
    return animatorLocationsMap && animatorLocationsMap.byId && animatorLocationsMap.byId[animatorId];
};
export const getAttendeeLocationMap = (state: any, animatorId: string, attendeeId: string): GeolocationState => {
    const locationMap = getAnimatorLocationMap(state, animatorId);
    return locationMap && locationMap.byAttendeeId && locationMap.byAttendeeId[attendeeId];
};

export const getAnimatorLocation = (
    state: any,
    animatorId: string,
    attendeeId: string,
): UserLocationDto | undefined => {
    const attendeeLocationMap = getAttendeeLocationMap(state, animatorId, attendeeId);
    return attendeeLocationMap && attendeeLocationMap.entity;
};

export const getAnimatorLocationFetchStatus = (state: any, animatorId: string, attendeeId: string): boolean => {
    const attendeeLocationMap = getAttendeeLocationMap(state, animatorId, attendeeId);
    return attendeeLocationMap && attendeeLocationMap.pending;
};

export const selectors = {
    getAnimatorLocation: getAnimatorLocation,
    getAnimatorLocationFetchStatus: getAnimatorLocationFetchStatus,
};
