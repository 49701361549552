import { useCallback, useEffect, useState } from 'react';
import { merge } from 'lodash';

import { log } from 'modules/utils';

import { clientConfig } from './client';
import { AppConfig, RemoteConfig } from './types';

export const useConfigLoader = () => {
    const [config, setConfig] = useState<AppConfig | null>(null);

    const loadConfig = useCallback(async () => {
        try {
            const response = await fetch(`/config/config.${clientConfig.appName}.json`);

            if (!response.ok) {
                throw new Error(`Failed to fetch a config. Status: ${response.status}`);
            }
            const remoteConfig: RemoteConfig = await response.json();

            const appConfig = merge({}, clientConfig, remoteConfig);

            setConfig(appConfig);
        } catch (error) {
            log('error', 'Fetching config error', { service: 'useConfigLoader', error });
        }
    }, []);

    useEffect(() => {
        if (!config) {
            loadConfig();
        }
    }, [config, loadConfig]);

    return config;
};
