import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getUserLifeStory } from 'modules/domain/users/api';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';

export const useUserStoryApi = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { logError } = useLogger('useUserStoryApi');

    const fetchLifeStory = useCallback(
        async (animatorId: string) => {
            try {
                const lifeStory = await getUserLifeStory(api, animatorId);
                dispatch(actions.setUserLifeStory(animatorId, lifeStory));
            } catch (error) {
                logError('fetchLifeStory error', { animatorId, error });
            }
        },
        [dispatch, api, logError],
    );

    return {
        fetchLifeStory,
    };
};
