export const namespace = 'fast-answers';

export enum FastAnswersActions {
    Set = 'fast-answers.set',
    FetchStart = 'fast-answers.fetch-start',
}

export type FastAnswersSetPayload = {
    loading: boolean;
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: {};
};
type FastAnswerSetAction = { type: FastAnswersActions; payload: FastAnswersSetPayload };
export const setFastAnswers = (data: any): FastAnswerSetAction => ({
    type: FastAnswersActions.Set,
    payload: { data, loading: false },
});

export const fetchFastAnswersStart = () => ({
    type: FastAnswersActions.FetchStart,
    payload: {},
});
