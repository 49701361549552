import { useCallback } from 'react';

import { useApi } from 'modules/api';
import { useOperatorId } from 'modules/domain/auth';

import { FastAnswerInTypedMessage } from '../types';

export const useDialogAnalytics = () => {
    const operatorId = useOperatorId();
    const { annals } = useApi();

    const trackDialogIsExplicit = useCallback(
        (animatorId: string, attendeeId: string) => {
            const payload = {
                'operator-id': operatorId,
                'animator-id': animatorId,
                'client-id': attendeeId,
            };
            return annals.send(operatorId, 'multichat-explicit-banner-checked', payload);
        },
        [annals, operatorId],
    );

    const trackSearchDialog = useCallback(
        (animatorId: string, attendeeId: string) => {
            const payload = {
                'operator-id': operatorId,
                'animator-id': animatorId,
                'client-id': attendeeId,
            };
            return annals.send(operatorId, 'multichat-search-button-pressed', payload);
        },
        [annals, operatorId],
    );

    const trackDialogMessageSendingWithFastAnswer = useCallback(
        (recipient: string, message: string, fastAnswer: FastAnswerInTypedMessage) => {
            const payload = {
                'operator-id': operatorId,
                'client-id': recipient,
                message,
                'fast-answer': fastAnswer,
            };
            return annals.send(operatorId, 'multichat-sent-message-with-fast-answer', payload);
        },
        [annals, operatorId],
    );

    const trackAnimatorStoryTold = useCallback(
        (animatorId: string, attendeeId: string) => {
            const payload = {
                'operator-id': operatorId,
                'animator-id': animatorId,
                'client-id': attendeeId,
            };
            return annals.send(operatorId, 'multichat-animator-story-told', payload);
        },
        [annals, operatorId],
    );

    return {
        trackDialogIsExplicit,
        trackSearchDialog,
        trackDialogMessageSendingWithFastAnswer,
        trackAnimatorStoryTold,
    };
};
