import { AppConfig } from 'modules/config';

import { HttpTransport, IHttpTransport } from './transport';
import { IdentityDomain, IIdentityDomain } from './domains/identity';
import { AnnalsDomain, IAnnalsDomain } from './domains/annals';
import { DialogsDomain, IDialogsDomain } from './domains/dialogs';
import { DialogsMessagesDomain, IDialogsMessagesDomain } from './domains/dialogs/messages';
import { OperatorsDomain, IOperatorsDomain } from './domains/operators';
import { IUsersDomain, UsersDomain } from './domains/users';
import { IUsersTagsDomain, UsersTagsDomain } from './domains/users/tags';
import {
    DialogsMessagesAutomationDomain,
    IDialogsMessagesAutomationDomain,
} from './domains/dialogs/messages/automation';
import { IDialogsAudioDomain, DialogsAudioDomain } from './domains/dialogs/audio';
import { FeaturesDomain, IFeaturesDomain } from './domains/features';
import { EventsDomain, IEventsDomain } from './domains/events';

export class Api {
    private readonly httpTransport: IHttpTransport;
    readonly host: string;

    readonly identity: IIdentityDomain;
    readonly annals: IAnnalsDomain;
    readonly dialogs: IDialogsDomain;
    readonly dialogsMessages: IDialogsMessagesDomain;
    readonly dialogsMessagesAutomation: IDialogsMessagesAutomationDomain;
    readonly dialogsAudio: IDialogsAudioDomain;
    readonly operators: IOperatorsDomain;
    readonly users: IUsersDomain;
    readonly usersTags: IUsersTagsDomain;
    readonly features: IFeaturesDomain;
    readonly events: IEventsDomain;

    constructor(config: AppConfig) {
        this.host = 'https://' + config.api;
        this.httpTransport = new HttpTransport(this.host);

        this.identity = new IdentityDomain(this.httpTransport);
        this.annals = new AnnalsDomain(this.httpTransport);
        this.dialogs = new DialogsDomain(this.httpTransport, config.backendAlias);
        this.dialogsMessages = new DialogsMessagesDomain(this.httpTransport);
        this.dialogsMessagesAutomation = new DialogsMessagesAutomationDomain(this.httpTransport, config.backendAlias);
        this.dialogsAudio = new DialogsAudioDomain(this.httpTransport);
        this.operators = new OperatorsDomain(this.httpTransport, config.backendAlias);
        this.users = new UsersDomain(this.httpTransport);
        this.usersTags = new UsersTagsDomain(this.httpTransport);
        this.features = new FeaturesDomain(this.httpTransport);
        this.events = new EventsDomain(this.httpTransport);
    }

    setAuthToken(token: string) {
        this.httpTransport.setAuthToken(token);
    }

    clearAuthToken() {
        this.httpTransport.clearAuthToken();
    }
}
