import { EntityMap, ModelState } from 'modules/domain/common/types';
import { RootState } from 'modules/App/store/root-state';

import { namespace } from './actions';
import { Dictionary } from './types';

export const getDictionaryModelState = (state: RootState): ModelState<Dictionary> => state[namespace] || {};
export const getDictionaryMap = (state: RootState): EntityMap<Dictionary> => {
    return getDictionaryModelState(state).entities.byId || {};
};

export const getDictionary = (state: RootState, name: string): Dictionary => {
    return getDictionaryMap(state)[name];
};

export const getDictionaries = (state: RootState): EntityMap<Dictionary> => {
    return getDictionaryMap(state);
};

export const selectors = {
    getDictionary,
    getDictionaries,
};
