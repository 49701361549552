export const namespace = 'auth';

export enum AuthActions {
    Logout = 'auth.logout',
}

export type UpdateDialogListAction = { type: AuthActions };

export function authLogout(): UpdateDialogListAction {
    return { type: AuthActions.Logout };
}

export const actions = {
    authLogout,
};
