import React, { memo, PropsWithChildren } from 'react';

import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = PropsWithChildren<{
    title: string;
    children: React.ReactNode;
}>;

export const InfoBlock = memo((props: Props) => {
    const { title, children } = props;

    return (
        <div {...testId(`info-block-${title}`)} className={styles.container}>
            <div className={styles.title}>{title}</div>
            <div className={styles.content}>{children}</div>
        </div>
    );
});
