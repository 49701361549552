import { getShardUri } from 'modules/utils';
import { AppConfig } from 'modules/config';
import { OperatorCommissionDto, OperatorDto, UserDto } from 'modules/api/dto';

import { Operator, User } from './types';

export const getUserLocationString = (city?: string, country?: string): string => {
    return [country, city].filter(Boolean).join(', ');
};

export const getUserAge = (user: UserDto): number => {
    if (!user.birthday) {
        return 0;
    }
    const ageDifMs = Date.now() - new Date(user.birthday).getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

export const getUserThumbnail = (
    user: Partial<UserDto>,
    appConfig: AppConfig,
    size = 56,
    isPrivate = false,
    isPreview = false,
    token?: string,
): string | null => {
    if (!user.thumbnail) {
        return null;
    }

    const privatePrefix = isPrivate ? '/private' : '';
    const previewPrefix = isPreview ? '/preview' : '';
    const tokenQueryParam = token && isPrivate ? `?authorization=Token%20token="${token}"` : '';

    return getShardUri(
        `/users/${user.id}/photos${privatePrefix}${previewPrefix}/${user.thumbnail}.${size}x${size}.thumb-fd${tokenQueryParam}`,
        appConfig.sharding,
    );
};

export const apiUserToUser = (appConfig: AppConfig, userDto: UserDto, email = '', token = ''): User => {
    const user: User = {
        id: userDto.id,
        name: userDto.name,
        age: getUserAge(userDto),
        birthday: userDto.birthday,
        location: getUserLocationString(userDto.city, userDto.country),
        thumbnail: userDto.thumbnail,
        parsedThumbnailForAvatar: getUserThumbnail(userDto, appConfig),
        tags: userDto.tags, // merge with data from /users/:userId/tag request in redux store
        experiments: [], // override by /users/:userId/tags/experiments request in redux store
        note: '', // override by /users/:userId/notes request in redux store
        occupation: userDto.occupation,
        timezone: userDto.timezone,
        gender: userDto.gender,
        preferredGender: null, // override by /users/:userId/preferences request in redux store
        email,
        token,

        info: {
            aboutMe: userDto.about,
            aboutMyPartner: '', // override by /users/:userId/preferences request in redux store
            interests: [], // override by /users/:userId/interests request in redux store
            lifeStory: null, // override by /users/:userId/life-style request in redux store
            eye: userDto.eye,
            hair: userDto.hair,
            drink: userDto.drink,
            smoke: userDto.smoke,
            education: userDto.education,
            relationship: userDto.relationship,
            haveKids: userDto.haveKids,
            bodytype: userDto.bodytype,
            languages: userDto.languages,
            height: userDto.height,
        },
    };

    return user;
};

export const mapOperatorDtoToOperator = (operatorDto: OperatorDto, commissionDto: OperatorCommissionDto): Operator => ({
    isReadyForExplicit: operatorDto.isReadyForExplicit,
    operatorId: operatorDto.operatorId,
    commission: commissionDto.Commission,
    trapPenalty: commissionDto.TrapPenalty ?? 0,
});
