export enum DialogActions {
    SwitchDialog = 'dialog.switch',
    ApiRequestListStart = 'dialog.api-request-list-start',
    ApiListEmpty = 'dialog.api=list-empty',
    ApiRequestListSuccess = 'dialog.api-request-list-success',
    ApiRequestListFailure = 'dialog.api-request-list-failure',
    SetNoMoreMessages = 'dialog.set-no-more-messages',
    SetMessages = 'dialog.set-messages',
    ApiMessageSent = 'dialog.api-message-sent',
    ApiSendMessageFailure = 'dialog.api-send-message-failure',
    ApiUploadMediaStart = 'dialog.api-upload-media-start',
    ApiUploadMediaSuccess = 'dialog.api-upload-media-success',
    ApiUploadMediaFailure = 'dialog.api-upload-media-failure',
    ApiUploadMediaSetProgress = 'dialog.api-upload-media-set-progress',

    SetMetrics = 'dialog.set-metrics',
    SetFoundDialog = 'dialog.set-found-dialog',
    ResetFoundDialog = 'dialog.reset-found-dialog',
    SetNote = 'user.set-note',
    SetNoteImproved = 'user.set-note-improved',
    SetPreference = 'user.set-preference',
    ApiSearchMessagesSuccess = 'dialog.api-search-messages-success',
    ClearSearchedMessages = 'dialog.clear-searched-messages',
    SwitchSearchedMessageIndex = 'dialog.switch-searched-message-index',
    SetLastBotMessage = 'dialog.set-last-bot-message',
    SetDialogExplicitStatus = 'dialog.set-explicit-status',
    SetTypedMessage = 'dialog.set-typed-message',
    SetAdditionalMessagePart = 'dialog.set-additional-message-part',
    SetUnpaidUnansweredMessage = 'dialog.set-unpaid-unanswered-message',
    RemoveMessageByTag = 'dialog.remove-message-by-tag',
    ResetCopilotState = 'copilot.reset-state',
    CopilotLoadSucceed = 'copilot.load-succeed',
    CopilotOptionClicked = 'copilot.option-clicked',
    SetDialogInputIsFocused = 'dialog.set-dialog-input-is-focused',
    SetScrollToMessageId = 'dialog.set-scroll-to-message-id',

    SetAudioMessages = 'dialog.set-audio-messages',
    ResetAudioMessages = 'dialog.reset-audio-messages',
}
