import React from 'react';

export const UserIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        <path
            d="M10.0003 9.74278C9.28157 9.74278 8.67133 9.49192 8.1696 8.99019C7.66786 8.48846 7.41699 7.87822 7.41699 7.15948C7.41699 6.44073 7.66786 5.8305 8.1696 5.32878C8.67133 4.82704 9.28157 4.57617 10.0003 4.57617C10.719 4.57617 11.3293 4.82704 11.831 5.32878C12.3327 5.8305 12.5836 6.44073 12.5836 7.15948C12.5836 7.87822 12.3327 8.48846 11.831 8.99019C11.3293 9.49192 10.719 9.74278 10.0003 9.74278ZM4.41699 15.4223V13.903C4.41699 13.6477 4.49312 13.4017 4.64537 13.1651C4.7976 12.9285 5.01047 12.7236 5.28397 12.5505C6.01047 12.1328 6.77399 11.8125 7.57451 11.5898C8.37504 11.367 9.18219 11.2557 9.99597 11.2557C10.8097 11.2557 11.6183 11.367 12.4218 11.5898C13.2252 11.8125 13.9901 12.1328 14.7166 12.5505C14.9901 12.7097 15.203 12.9111 15.3552 13.1547C15.5075 13.3983 15.5836 13.6477 15.5836 13.903V15.4223H4.41699ZM5.5003 14.3389H14.5003V13.903C14.5003 13.8151 14.4686 13.7324 14.4052 13.6549C14.3418 13.5774 14.2544 13.5116 14.1429 13.4575C13.5361 13.09 12.8804 12.8117 12.1759 12.6226C11.4714 12.4335 10.7462 12.3389 10.0003 12.3389C9.25444 12.3389 8.52925 12.4335 7.82472 12.6226C7.12019 12.8117 6.46451 13.09 5.85768 13.4575C5.7455 13.5345 5.65789 13.6081 5.59487 13.6785C5.53183 13.749 5.5003 13.8238 5.5003 13.903V14.3389ZM10.0047 8.65948C10.4184 8.65948 10.7711 8.51217 11.0628 8.21755C11.3545 7.92294 11.5003 7.56877 11.5003 7.15505C11.5003 6.74134 11.353 6.38865 11.0584 6.09698C10.7638 5.80532 10.4096 5.65948 9.99589 5.65948C9.58217 5.65948 9.22947 5.80679 8.9378 6.1014C8.64614 6.39603 8.5003 6.75019 8.5003 7.1639C8.5003 7.57762 8.64761 7.93032 8.94222 8.22198C9.23685 8.51365 9.59101 8.65948 10.0047 8.65948Z"
            fill="currentColor"
        />
    </svg>
);
