import { IHttpTransport } from 'modules/api/transport/http.contracts';

import { GetDialogsQueryParams, IDialogsDomain, UpdateDialogExplicitStatusPayload } from './contracts';
import { DialogsMetricsDto } from './dto';

export class DialogsDomain implements IDialogsDomain {
    constructor(private readonly transport: IHttpTransport, private readonly realm: string) {}

    getOperatorDialogs(operatorId: string, params?: GetDialogsQueryParams): Promise<DialogsMetricsDto> {
        return this.transport
            .get<DialogsMetricsDto>(`/multichat/operators/${operatorId}/dialogs`, {
                params: { realm: this.realm, ...params },
            })
            .then(response => response.data);
    }

    updateDialogExplicitStatus(payload: UpdateDialogExplicitStatusPayload): Promise<void> {
        const { operatorId, animatorId, attendeeId, status } = payload;
        return this.transport
            .put<void>('/multichat/dialogs/explicit/update', {
                operatorId,
                animatorId,
                clientId: attendeeId,
                isExplicit: status,
            })
            .then(response => response.data);
    }
}
