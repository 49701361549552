import { UserLocationDto } from 'modules/api/dto';

export const namespace = 'animator-locations';

export enum AnimatorLocationActions {
    Set = 'animator-location.set',
    FetchStart = 'animator-location.fetch-start',
    FetchSuccess = 'animator-location.fetch-success',
    FetchFailure = 'animator-location.fetch-failure',
}

type AnimatorLocationPayload = { id: string; input: UserLocationDto };
export type SetAnimatorLocationAction = { type: AnimatorLocationActions; payload: AnimatorLocationPayload };

export function setAnimatorLocation(id: string, input: UserLocationDto): SetAnimatorLocationAction {
    return { type: AnimatorLocationActions.Set, payload: { id, input } };
}

export type FetchAnimatorLocationPayload = { animatorId: string; attendeeId: string };
export type FetchAnimatorLocationAction = {
    type: AnimatorLocationActions.FetchStart;
    payload: FetchAnimatorLocationPayload;
};
export type FetchSuccessAnimatorLocationPayload = { animatorId: string; attendeeId: string; result: UserLocationDto };
export type FetchSuccessAnimatorLocationAction = {
    type: AnimatorLocationActions.FetchSuccess;
    payload: FetchSuccessAnimatorLocationPayload;
};
export type FetchFailureAnimatorLocationPayload = { animatorId: string; attendeeId: string; error: string };
export type FetchFailureAnimatorLocationAction = {
    type: AnimatorLocationActions.FetchFailure;
    payload: FetchFailureAnimatorLocationPayload;
};

export function fetchAnimatorLocation(animatorId: string, attendeeId: string): FetchAnimatorLocationAction {
    return { type: AnimatorLocationActions.FetchStart, payload: { animatorId, attendeeId } };
}
export function fetchSuccessAnimatorLocation(
    animatorId: string,
    attendeeId: string,
    result: UserLocationDto,
): FetchSuccessAnimatorLocationAction {
    return { type: AnimatorLocationActions.FetchSuccess, payload: { animatorId, attendeeId, result } };
}
export function fetchFailureAnimatorLocation(
    animatorId: string,
    attendeeId: string,
    error: string,
): FetchFailureAnimatorLocationAction {
    return { type: AnimatorLocationActions.FetchFailure, payload: { animatorId, attendeeId, error } };
}

export const actions = {
    fetchAnimatorLocation: fetchAnimatorLocation,
    fetchSuccessAnimatorLocation: fetchSuccessAnimatorLocation,
    fetchFailureAnimatorLocation: fetchFailureAnimatorLocation,
};
