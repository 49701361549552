import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from 'modules/App/private-route';
import { AuthProvider } from 'modules/components/auth/auth-provider';
import { MultichatPage } from 'modules/components/multichat/multichat-page';
import { OfflinePage } from 'modules/components/offline/offline-page';
import { IncomePage, KPIPage } from 'modules/components/pages';
import { AuthPage } from 'modules/components/auth/auth-page';
import { Head } from 'modules/components/common/head';
import { Services } from 'modules/components/services';
import { MajorPage } from 'modules/components/common/major-page';
import { StatsPage } from 'modules/components/stats-page';
import { AppRoutes } from 'modules/navigation';
import { Notification } from 'modules/components/notification';

export const App = () => {
    return (
        <AuthProvider>
            <Head />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Navigate to={AppRoutes.Chat} replace />} />
                    <Route
                        path={AppRoutes.Chat}
                        element={
                            <PrivateRoute>
                                <MajorPage>
                                    <MultichatPage />
                                </MajorPage>
                            </PrivateRoute>
                        }
                    />
                    <Route
                        path={AppRoutes.Offline}
                        element={
                            <PrivateRoute>
                                <OfflinePage />
                            </PrivateRoute>
                        }
                    />
                    <Route path={AppRoutes.Login} element={<AuthPage />} />
                    <Route path={AppRoutes.Stats} element={<StatsPage />}>
                        <Route path={AppRoutes.Kpi} element={<KPIPage />} />
                        <Route path={AppRoutes.Income} element={<IncomePage />} />
                    </Route>
                </Routes>
                <Services />
                <Notification />
            </BrowserRouter>
        </AuthProvider>
    );
};
