import { getUrl, LoHiType, chatLoHiId } from 'modules/utils';
import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { Note, ClientImprovedNote, Preference, GetApiImprovedNoteData } from '../types';
import { getApiNoteForPatchRequest, getClientNoteFromApiResponse } from '../adapter/note-adapter';

export const getAnimatorPreference = (api: SDVLegacyAPI, animatorId: string) => {
    return new Promise<Preference>((resolve, reject) => {
        api.multichat.animators.preferences.get(animatorId, (error: unknown, response: Preference) => {
            if (error) {
                return reject({});
            }
            return resolve(response);
        });
    });
};

export const postDialogNote = (api: SDVLegacyAPI, lohiIds: LoHiType, note: Note) => {
    return new Promise<void>((resolve, reject) => {
        api.multichat.notes.patch(lohiIds.lower, lohiIds.higher, note, (error: unknown) => {
            if (error) {
                return reject(error);
            }

            return resolve();
        });
    });
};

export const getDialogNoteImproved = async (
    api: SDVLegacyAPI,
    animatorId: string,
    attendeeId: string,
): Promise<ClientImprovedNote> => {
    const headers = {
        authorization: api.authorize(),
        'content-type': 'application/json',
    };

    try {
        const response = await fetch(getUrl(api, `/v3/multichat/notes/${attendeeId}:${animatorId}`), {
            headers,
        });

        const data: GetApiImprovedNoteData = await response.json();

        return getClientNoteFromApiResponse(data, animatorId, attendeeId);
    } catch (error) {
        return getClientNoteFromApiResponse({} as GetApiImprovedNoteData, animatorId, attendeeId);
    }
};

export const patchDialogNoteImproved = async (
    api: SDVLegacyAPI,
    animatorId: string,
    attendeeId: string,
    data: ClientImprovedNote,
): Promise<{ success: boolean }> => {
    const lohiIds = chatLoHiId(animatorId, attendeeId);
    const headers = {
        authorization: api.authorize(),
        'content-type': 'application/json',
    };

    try {
        const response = await fetch(getUrl(api, `/v2/multichat/notes/${lohiIds.lower}:${lohiIds.higher}`), {
            headers,
            method: 'PATCH',
            body: JSON.stringify(getApiNoteForPatchRequest(data, animatorId, attendeeId)),
        });
        if (response.ok) {
            return { success: true };
        }

        return { success: false };
    } catch (error) {
        return { success: false };
    }
};
