import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { useAuthApi } from 'modules/api-old/use-auth-api';
import { useLogger } from 'modules/utils';

import { actions } from '../actions';
import { getUserInterests } from '../api';

export const useUserInterestsApi = () => {
    const dispatch = useDispatch();
    const { api } = useAuthApi();
    const { logError } = useLogger('useUserInterestsApi');

    const fetchUserInterests = useCallback(
        async (userId: string) => {
            try {
                const interests = await getUserInterests(api, userId);
                dispatch(actions.setUserInterests(userId, interests));
            } catch (error) {
                logError('fetchUserInterests error', { userId, error });
            }
        },
        [api, dispatch, logError],
    );

    return {
        fetchUserInterests,
    };
};
