import moment from 'moment';

import { DatesRange } from '../types';

export const DEFAULT_DATES_RANGE = DatesRange.ThisWeek;

type DatesRangeItem = {
    title: string;
    from: string;
    to: string;
    id: DatesRange;
};

/*
 * It is not a statis object cause the ranges should be recalculated if the day has changed
 * todo probably should make it explicit

 * */

export const getDatesRangeMap = (): Record<DatesRange, DatesRangeItem> => {
    return {
        [DatesRange.ThisWeek]: {
            title: 'This week',
            from: moment().utc().startOf('isoWeek').format('YYYY-MM-DD'),
            to: moment().utc().format('YYYY-MM-DD'),
            id: DatesRange.ThisWeek,
        },
        [DatesRange.LastWeek]: {
            title: 'Last week',
            from: moment().utc().startOf('isoWeek').subtract(7, 'days').format('YYYY-MM-DD'),
            to: moment().utc().startOf('isoWeek').subtract(7, 'days').endOf('isoWeek').format('YYYY-MM-DD'),
            id: DatesRange.LastWeek,
        },
        [DatesRange.ThisMonth]: {
            title: 'This month',
            from: moment().utc().startOf('month').format('YYYY-MM-DD'),
            to: moment().utc().format('YYYY-MM-DD'),
            id: DatesRange.ThisMonth,
        },
        [DatesRange.LastMonth]: {
            title: 'Last month',
            from: moment().utc().startOf('month').subtract(1, 'month').format('YYYY-MM-DD'),
            to: moment().utc().startOf('month').subtract(1, 'month').endOf('month').format('YYYY-MM-DD'),
            id: DatesRange.LastMonth,
        },
    };
};
