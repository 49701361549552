import React from 'react';

import styles from './index.module.css';

type Props = {
    onClick: () => void;
};
export const PreviousIcon = (props: Props) => (
    <div className={styles.arrow} onClick={props.onClick}>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
                d="M8.4846 11.9999L13.7923 17.3076L14.5 16.5999L9.9 11.9999L14.5 7.39992L13.7923 6.69222L8.4846 11.9999Z"
                fill="currentColor"
            />
        </svg>
    </div>
);
