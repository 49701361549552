import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Dialog, DialogMessage } from 'modules/domain/dialog/types';
import { getDialogId } from 'modules/utils';
import { DialogModel } from 'modules/domain/dialog';
import { useConfig } from 'modules/config';
import { Operator } from 'modules/domain/users/types';
import { Attendee } from 'modules/components/attendee';
import { Animator } from 'modules/components/animator';
import { MessageRow, VipCaption, ChatHeader, ChatDropzone } from 'modules/components/chat';
import { ChatForm } from 'modules/components/chat-form';
import { Loader } from 'modules/components/loader';
import { usePoller } from 'modules/hooks';

import { ScrollToBottomButton } from './scroll-to-bottom-button';
import { ChatLog } from './chat-log';
import { CopilotAnswerOptions } from './copilot-answer-options';
import { useScrollToMessage, useChatScroll } from './hooks';
import './styles/index.scss';

type Props = {
    currentDialog: Dialog;
    operator: Operator | null;
};

const CHAT_PAGE_SIZE = 25;
/**
 * size of the chat header
 */
const CHAT_HEADER_OFFSET_SIZE = 96;

export const ChatWrapper = memo((props: Props) => {
    const { currentDialog, operator } = props;
    const { animatorId, attendeeId, id: currentDialogId, isExplicit } = currentDialog;

    const [shouldScrollToBottom, setShouldScrollToBottom] = useState(false);
    const [firstInvisibleMessageId, setFirstInvisibleMessageId] = useState<string | null>(null);

    const { scrollToMessageId } = DialogModel.hooks.useSelectors();
    const {
        messages,
        searchedMessages: searchedMessagesState,
        someMessagesFromTheDialogWereLoaded,
    } = DialogModel.hooks.useSelectorsById(currentDialogId);
    const { fetchDialogMessages, fetchUnansweredUnpaidDialogMessage } =
        DialogModel.hooks.useDialogMessagesApi(currentDialogId);

    DialogModel.hooks.useMarkLastMessageAsRead(currentDialog);

    const messagesCount = messages.length;

    const refs = useMemo(
        () =>
            messages.reduce((acc, value) => {
                acc[value.id] = React.createRef();
                return acc;
            }, {} as { [key: string]: React.RefObject<HTMLDivElement> }),
        [messages],
    );

    const lastReadOutgoingMessage = useMemo(
        () => messages.find(message => message.outgoing && message.read),
        [messages],
    );

    // Refs
    const logScrollableContainer = useRef<HTMLDivElement>(null);
    const chatLogWrapperInnerRef = useRef<HTMLDivElement>(null);

    const recalculateFirstInvisibleMessageIndex = useCallback(() => {
        const messagesIds = Object.keys(refs);
        const firstVisibleMessageIndex = messagesIds.findIndex(refKey => {
            const rect = refs[refKey].current?.getBoundingClientRect();
            return rect && rect.top > CHAT_HEADER_OFFSET_SIZE;
        });
        const firstInvisibleMessageId = messagesIds[firstVisibleMessageIndex - 1] || null;
        setFirstInvisibleMessageId(firstInvisibleMessageId);
    }, [refs]);

    const loadNextChatPage = useCallback(() => {
        if (!scrollToMessageId) {
            fetchDialogMessages(CHAT_PAGE_SIZE, messagesCount);
        }
    }, [scrollToMessageId, fetchDialogMessages, messagesCount]);

    const { scrollHandler, isBottomPosition } = useChatScroll(loadNextChatPage, recalculateFirstInvisibleMessageIndex);

    const scrollToBottom = useCallback(() => {
        if (logScrollableContainer.current) {
            logScrollableContainer.current.scrollTop = logScrollableContainer.current.scrollHeight;
        }
        setShouldScrollToBottom(false);
    }, []);

    const { chatMessagesPollingInterval } = useConfig();

    const messagesPoller = useCallback(() => {
        fetchDialogMessages(CHAT_PAGE_SIZE, 0).then(() => {
            recalculateFirstInvisibleMessageIndex();
        });

        if (currentDialog.unansweredUnpaid) {
            fetchUnansweredUnpaidDialogMessage();
        }
    }, [
        fetchDialogMessages,
        fetchUnansweredUnpaidDialogMessage,
        currentDialog.unansweredUnpaid,
        recalculateFirstInvisibleMessageIndex,
    ]);

    usePoller(messagesPoller, chatMessagesPollingInterval);

    useEffect(() => {
        if (!shouldScrollToBottom || scrollToMessageId) {
            return;
        }
        scrollToBottom();
    }, [scrollToMessageId, shouldScrollToBottom, scrollToBottom]);

    useScrollToMessage(refs);

    const firstInvisibleMessageTimestamp = useMemo(() => {
        const firstInvisibleMessage: DialogMessage | undefined = messages.find(
            message => message.id === Number(firstInvisibleMessageId),
        );
        return firstInvisibleMessage ? firstInvisibleMessage.timestamp : null;
    }, [messages, firstInvisibleMessageId]);

    const messagesList = useMemo(() => {
        const {
            messages: searchedMessages,
            searchedPhrase,
            currentSearchedMessageIndex = 0,
        } = searchedMessagesState || {};
        const currentSearchedMessage = searchedMessages?.[currentSearchedMessageIndex];

        return messages.map((message: DialogMessage) => {
            const isSearchedMessage = searchedMessages?.some(m => m.messageId === message.id);
            const highlight = isSearchedMessage ? searchedPhrase : undefined;

            const hasFocusBetweenSearchedMessages = currentSearchedMessage?.messageId === message.id;

            return (
                <MessageRow
                    key={message.tag}
                    {...message}
                    ref={refs[message.id]}
                    isLastReadOutgoingMessage={message.tag === lastReadOutgoingMessage?.tag}
                    highlight={highlight}
                    inFocus={hasFocusBetweenSearchedMessages}
                    dialogId={currentDialogId}
                />
            );
        });
    }, [messages, searchedMessagesState, refs, lastReadOutgoingMessage?.tag, currentDialogId]);

    return (
        <div className="chat-wrapper" key={getDialogId(animatorId, attendeeId)}>
            <div className="chat-middle">
                <div className="chat-block">
                    <div className="chat-interlocutor left">
                        <Attendee id={attendeeId} animatorId={animatorId} attendeeId={attendeeId} />
                    </div>
                    <div className="chat-log-wrapper">
                        <ChatHeader
                            dialogId={currentDialogId}
                            chatIsExplicit={isExplicit}
                            operator={operator}
                            timestamp={firstInvisibleMessageTimestamp}
                        />
                        <div className="chat-log-wrapper-inner" ref={chatLogWrapperInnerRef}>
                            <ChatDropzone
                                dialogId={currentDialogId}
                                animatorId={animatorId}
                                chatLogWrapperInnerRef={chatLogWrapperInnerRef}
                            />
                            <ChatLog
                                logScrollableContainer={logScrollableContainer}
                                messagesCount={messagesCount}
                                scrollHandler={scrollHandler}
                            >
                                {!someMessagesFromTheDialogWereLoaded && <Loader />}
                                <div className="chat-log-messages-wrapper">{messagesList}</div>
                                <VipCaption attendeeId={attendeeId} />
                                <ScrollToBottomButton hidden={isBottomPosition} onClick={scrollToBottom} />
                                <CopilotAnswerOptions
                                    attendeeId={attendeeId}
                                    animatorId={animatorId}
                                    lastMessage={messages[0]}
                                    onNeedToScrollCopilot={setShouldScrollToBottom}
                                    trapPenalty={operator?.trapPenalty || 0}
                                />
                            </ChatLog>
                        </div>
                        <ChatForm
                            animatorId={animatorId}
                            attendeeId={attendeeId}
                            loading={!messagesCount}
                            operatorCommission={(operator?.commission || '').toString()}
                        />
                    </div>
                    <div className="chat-interlocutor right">
                        <Animator id={animatorId} animatorId={animatorId} attendeeId={attendeeId} />
                    </div>
                </div>
            </div>
        </div>
    );
});
