import React, { memo, useMemo } from 'react';

import { PageBodyWithTitle } from 'modules/components/common/page-body-with-title';
import { useEarningsSelectors, useEarningsActualize } from 'modules/domain/earnings/hooks';
import { useStatsContext } from 'modules/components/stats-page/context';

import { calculateIncomeData } from './calculateIncomeData';
import { useCommission } from './useCommission';
import { Card } from './card';
import styles from './index.module.css';

export const IncomePage = memo(() => {
    const { selectedRange, showKPI } = useStatsContext();

    useEarningsActualize(selectedRange);

    const commissions = useCommission();
    const { earnings } = useEarningsSelectors(selectedRange);

    const incomeData = useMemo(() => calculateIncomeData(earnings), [earnings]);

    const { repliesNumber, penalties, repliesPayment, benefitsNumber, benefitsPayment, kpiEffectPayment, totalIncome } =
        incomeData;

    return (
        <div className={styles.container}>
            <PageBodyWithTitle title={'Income'}>
                {earnings && commissions ? (
                    <>
                        <Card
                            explanationValue={repliesNumber.toString()}
                            money={repliesPayment}
                            explanation="Replies:"
                            explanationDetails={
                                commissions
                                    ? `You receive $${commissions.replyCommission} when client reply on your message, photo or gift`
                                    : ''
                            }
                            isBadValue={false}
                            isGoodValue={false}
                            testIdPostfix={'replies'}
                        />
                        <Card
                            explanationValue={benefitsNumber.toString()}
                            money={benefitsPayment}
                            explanation="Extra Benefits:"
                            explanationDetails={
                                commissions
                                    ? `You receive $${commissions.replyCommission} when client open your photo, reply by gift on your gift etc.`
                                    : ''
                            }
                            isBadValue={false}
                            isGoodValue={false}
                            testIdPostfix={'extra-benefits'}
                        />
                        {earnings.KpiEffect !== undefined && (
                            <Card
                                explanationValue={showKPI ? `${earnings.KpiEffect || 0}%` : ''}
                                money={kpiEffectPayment}
                                explanation={showKPI ? 'KPI Effect:' : 'KPI Effect'}
                                explanationDetails={`Increase or decrease your income from Replies`}
                                isBadValue={kpiEffectPayment < 0 || earnings.KpiEffect < 0}
                                isGoodValue={kpiEffectPayment > 0}
                                testIdPostfix={'kpi-effect'}
                            />
                        )}
                        <Card
                            explanationValue={
                                earnings.TrapPenalties ? earnings.TrapPenalties.BonusesCount.toString() : '0'
                            }
                            money={penalties}
                            explanation={'Penalties:'}
                            explanationDetails={`You lose $${
                                commissions.penalty * -1
                            } when you choose an inappropriate answer`}
                            isBadValue={!!earnings.TrapPenalties?.TotalBonusValue}
                            isGoodValue={false}
                            testIdPostfix={'penalties'}
                        />
                        <Card
                            money={totalIncome}
                            explanation="Total Income"
                            testIdPostfix={'total-income'}
                            isBadValue={totalIncome < 0}
                            isGoodValue={false}
                        />
                    </>
                ) : null}
            </PageBodyWithTitle>
        </div>
    );
});
