import React, { memo } from 'react';

import { DialogModel } from 'modules/domain/dialog';

export * from './service';
export * from './use-average-timer';

import { ExtendedTimerView } from './view';

export const ExtendedTimer = memo(() => {
    const { metrics } = DialogModel.hooks.useSelectors();

    const queueIsEmpty = metrics.unansweredCount === 0;

    if (queueIsEmpty) {
        return null;
    }

    return <ExtendedTimerView />;
});
