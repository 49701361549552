import { useCallback, useEffect, useRef } from 'react';

type Params = {
    condition: boolean;
    action: () => void;
};

export const useClickOutside = <T extends HTMLElement>({ condition, action }: Params) => {
    const element = useRef<T>({} as T);

    const handleClickOutside = useCallback(
        event => {
            if (condition && element && !element.current?.contains(event.target)) {
                action();
            }
        },
        [action, condition],
    );

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return element;
};
