import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuthActions } from 'modules/domain/auth/use-auth-actions';
import { useAuth } from 'modules/domain/auth/use-auth';
import { AppRoutes } from 'modules/navigation';

export const useSignOut = () => {
    const navigate = useNavigate();
    const { authLogout } = useAuthActions();
    const { clearAuthInfo } = useAuth();

    const signOut = useCallback(() => {
        clearAuthInfo();
        authLogout();

        navigate(AppRoutes.Login);
    }, [navigate, clearAuthInfo, authLogout]);

    return signOut;
};
