import React from 'react';

import { UserHeader } from 'modules/components/user-header';
import { DialogModel } from 'modules/domain/dialog';
import { BadgesAndSearch } from 'modules/components/badges-and-search';

import styles from './index.module.css';

export const MultichatHeader = React.memo(function MultichatHeader() {
    const { dialog } = DialogModel.hooks.useSelectors();
    const { attendeeId, animatorId } = dialog || {};

    return (
        <div className={styles.container}>
            <UserHeader animatorId={animatorId || ''} isAnimator={false} attendeeId={attendeeId || ''} />
            <BadgesAndSearch />
            <UserHeader animatorId={animatorId || ''} isAnimator={true} attendeeId={attendeeId || ''} />
        </div>
    );
});
