import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';

import {
    namespace,
    SetMediaListPayload,
    MediaActions,
    SetMediaChatListPayload,
    UpdateMediaEnabledPayload,
} from './actions';
import { MediaState } from './types';

// eslint-disable-next-line @typescript-eslint/ban-types
type CommonMediaEntity = {};
type CommonMediaPayload = { userId: string; input: any };

interface CustomReducerMap extends ReducerMap<MediaState<CommonMediaEntity>, CommonMediaPayload> {
    [MediaActions.SetList]: ReducerMapValue<MediaState<CommonMediaEntity>, SetMediaListPayload>;
    [MediaActions.SetChatList]: ReducerMapValue<MediaState<CommonMediaEntity>, SetMediaChatListPayload>;
    [MediaActions.UpdateChatState]: ReducerMapValue<MediaState<CommonMediaEntity>, UpdateMediaEnabledPayload>;
}

const initialState = { entities: { byId: {} }, chatEntities: { byId: {} } };

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [MediaActions.SetList]: (state, { payload: { userId, input } }: { payload: SetMediaListPayload }) =>
        update(state, {
            entities: {
                byId: {
                    [userId]: {
                        $set: input,
                    },
                },
            },
        }),
    [MediaActions.SetChatList]: (state, { payload: { userId, input } }: { payload: SetMediaChatListPayload }) =>
        update(state, {
            chatEntities: {
                byId: {
                    [userId]: {
                        $set: input,
                    },
                },
            },
        }),
    [MediaActions.UpdateChatState]: (state, { payload: { userId, input } }: { payload: UpdateMediaEnabledPayload }) =>
        update(state, {
            chatEntities: {
                byId: {
                    [userId]: {
                        $apply: data => {
                            return {
                                ...data,
                                list: data.list.filter(
                                    media =>
                                        (input.sentMedia || []).findIndex(
                                            allowedMedia => allowedMedia.id === media.id,
                                        ) === -1,
                                ),
                                sentMedia: input.sentMedia,
                                enabled: input.result,
                            };
                        },
                    },
                },
            },
        }),
};

export const reducer: MultiChatReducer<MediaState<CommonMediaEntity>, CommonMediaPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
