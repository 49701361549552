import cn from 'classnames';
import React, { memo } from 'react';

import { SingleKPIDto } from 'modules/api/dto';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.css';

type Props = {
    kpiNumbers: SingleKPIDto;
    weight: number;
    postfixMessage: string;
    testIdPostfix: string;
};

export const SingleKPIIndicator = memo((props: Props) => {
    const { kpiNumbers, weight, postfixMessage, testIdPostfix } = props;
    const { negative, positive, current } = kpiNumbers;

    const goodKPI = current >= positive;
    const mediocreKPI = current >= negative && current < positive;
    const badKPI = current < negative;

    const visibleCurrent = Math.floor(current * 10) / 10;

    const topItem = goodKPI ? (
        <div>
            <span {...testId(`single-kpi-indicator-current-value-top-${testIdPostfix}`)}>{visibleCurrent}</span>
            {postfixMessage}
        </div>
    ) : (
        <div {...testId(`single-kpi-indicator-current-value-top-${testIdPostfix}`)}>
            {positive}
            {postfixMessage}
        </div>
    );

    const middleItem = mediocreKPI ? (
        <div>
            <span {...testId(`single-kpi-indicator-current-value-middle-${testIdPostfix}`)}>{visibleCurrent}</span>
            {postfixMessage}
        </div>
    ) : (
        <div>
            <span {...testId(`single-kpi-indicator-current-value-middle-${testIdPostfix}`)}>{negative}</span>
            {postfixMessage}
        </div>
    );

    const bottomItem = badKPI ? (
        <div>
            <span {...testId(`single-kpi-indicator-current-value-bottom-${testIdPostfix}`)}>{visibleCurrent}</span>
            {postfixMessage}
        </div>
    ) : (
        <div>
            <span {...testId(`single-kpi-indicator-current-value-bottom-${testIdPostfix}`)}>0</span>
            {postfixMessage}
        </div>
    );

    return (
        <div className={styles.singleKPI}>
            <div
                className={cn(goodKPI ? styles.goodKPI : styles.unactiveKPI)}
                {...testId(goodKPI ? 'single-kpi-active' : 'single-kpi-unactive')}
            >
                {topItem}
                <div {...testId(`single-kpi-indicator-top-weight-${testIdPostfix}`)}>{weight}</div>
            </div>
            <div
                className={cn(mediocreKPI ? styles.mediocreKPI : styles.unactiveKPI)}
                {...testId(mediocreKPI ? 'single-kpi-active' : 'single-kpi-unactive')}
            >
                {middleItem}
                <div {...testId(`single-kpi-indicator-middle-weight-${testIdPostfix}`)}>0</div>
            </div>
            <div
                className={cn(badKPI ? styles.badKPI : styles.unactiveKPI)}
                {...testId(badKPI ? 'single-kpi-active' : 'single-kpi-unactive')}
            >
                {bottomItem}
                <div {...testId(`single-kpi-indicator-bottom-weight-${testIdPostfix}`)}>-{weight}</div>
            </div>
        </div>
    );
});
