export enum AutoGeneratedAnswersUsage {
    Used = 'used',
    Edited = 'edited',
    NotUsed = 'not-used',
}

export enum AutoGeneratedAnswerOptionType {
    Valid = 'Valid',
    Trap = 'Trap',
    Wrong = 'Wrong',
}

export type AutoGeneratedAnswerOptionDto = {
    name: string;
    type: AutoGeneratedAnswerOptionType;
};

export type AutoGeneratedAnswersDto = {
    options: AutoGeneratedAnswerOptionDto[];
    botName: string;
};
