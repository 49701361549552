import { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';

import { DialogModel } from 'modules/domain/dialog';
import { DialogMessageDto } from 'modules/api/dto';

export function useHeaderTimer(onTimerStoped?: (lastTime: string) => void): string {
    const currentDialog = useSelector(DialogModel.selectors.getCurrentDialog);
    const [timer, setTimer] = useState('00:00');
    const timerState = useRef<{ time?: moment.Moment; isActive: boolean }>({ isActive: false });
    const prevMessageData = useRef<DialogMessageDto | undefined>();

    const dialog = useMemo(() => currentDialog || {}, [currentDialog]);
    const dialogMessages = useMemo(() => dialog.messages || [], [dialog]);
    const dialogMessagesCount = useMemo(() => dialogMessages.length, [dialogMessages]);
    const lastMessage = useMemo(() => dialogMessages[0] || {}, [dialogMessages]);

    useLayoutEffect(() => {
        // we should insure that we have more than 1 message
        if (!dialogMessagesCount) {
            prevMessageData.current = undefined;

            return;
        }
        // initialize prevMessageData
        if (lastMessage?.tag && typeof prevMessageData.current === 'undefined') {
            prevMessageData.current = lastMessage;
        }

        // if we derived new message
        if (lastMessage?.tag !== prevMessageData.current?.tag) {
            if (lastMessage.sender === dialog.animatorId) {
                const { isActive: prevIsActive } = timerState.current;

                timerState.current = { isActive: false, time: moment() };

                setTimer(lastTime => {
                    if (typeof onTimerStoped === 'function' && prevIsActive) {
                        onTimerStoped(lastTime);
                    }

                    return '00:00';
                });

                prevMessageData.current = lastMessage;

                return undefined;
            } else if (lastMessage.recipient === dialog.animatorId) {
                if (!timerState.current.isActive) {
                    timerState.current = { isActive: true, time: moment().add(1, 'second') };
                } else {
                    timerState.current = { isActive: true, time: moment() };
                }
                prevMessageData.current = lastMessage;
            }
        }
        prevMessageData.current = lastMessage;
    }, [dialog.animatorId, dialogMessagesCount, lastMessage, onTimerStoped]);

    useLayoutEffect(() => {
        let timerId: NodeJS.Timeout;

        if (timerState.current.isActive) {
            timerId = setInterval(() => {
                if (!timerState.current.isActive) {
                    clearInterval(timerId);
                }

                const dif = moment().diff(timerState.current.time, 'milliseconds');

                setTimer(moment(dif).format('mm:ss'));
            }, 1000);
        }
        return () => {
            clearInterval(timerId);
        };
    }, [timerState.current.isActive]);

    useLayoutEffect(() => {
        if (currentDialog?.id) {
            timerState.current = { isActive: true, time: moment() };
        }
    }, [currentDialog?.id]);

    return timer;
}
