import React, { memo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { MediaModel } from 'modules/domain/media';
import { ModalModel } from 'modules/domain/modals';
import { DialogModel } from 'modules/domain/dialog';
import { parseDialogId } from 'modules/utils';
import { showChatErrorToast } from 'modules/components/chat/helpers';

import { MediaItem } from './media-item';
import styles from './index.module.scss';

export const MediaSelectorModal = memo(() => {
    const dispatch = useDispatch();
    const { closeModal } = ModalModel.hooks.useModal();
    const { trackAllLibraryMediaSent } = MediaModel.hooks.useMediaAnalytics();
    const { activeModalData: data } =
        ModalModel.hooks.useSelectors<{ userId: string; dialogId: string; operatorCommission: string }>();
    const { sendPhotoMessage, messageSending } = DialogModel.hooks.useDialogMessagesApi(
        (data as { dialogId: string }).dialogId,
    );

    const media = useSelector(state => {
        if (!data) {
            return [];
        }

        return MediaModel.selectors.getChatMedia(state, data.userId);
    });

    const onSelect = useCallback(
        async (dialogId: string, basename: string) => {
            const { animatorId, attendeeId } = parseDialogId(dialogId);
            const lastMediaLength = media.length;

            dispatch(
                MediaModel.actions.updateChatMediaState(animatorId, {
                    result: false,
                    sentMedia: [],
                }),
            );

            try {
                await sendPhotoMessage(basename);

                if (lastMediaLength === 1) {
                    trackAllLibraryMediaSent(animatorId, attendeeId, basename);
                }
            } catch (error) {
                showChatErrorToast('Failed to send a photo. Please check your connection and try again later');
            }

            closeModal();
        },
        [media.length, dispatch, sendPhotoMessage, closeModal, trackAllLibraryMediaSent],
    );

    if (!data || messageSending) {
        return null;
    }

    const commissionText = `${data.operatorCommission || 0}`;

    return (
        <div className={styles.container}>
            <h4>Send a Photo</h4>
            <p className={styles.text}>
                Send one of these photos to a client once in a while and get ${commissionText} if the photo is viewed.
                If a client&apos;s reply is photo you will also get ${commissionText} for it.
            </p>

            <div className={styles.list}>
                {media.length === 0 && <p>There are no photos</p>}
                {media.map(mediaItem => (
                    <MediaItem
                        key={mediaItem.id}
                        dialogId={data.dialogId}
                        id={mediaItem.url}
                        url={mediaItem.thumbnail}
                        disabled={messageSending}
                        onSelect={onSelect}
                    />
                ))}
            </div>
        </div>
    );
});
