import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';

import { AuthActions } from 'modules/domain/auth/actions';
import { MultiChatReducer } from 'modules/domain/common/types';

import { namespace, FastAnswersActions, FastAnswersSetPayload } from './actions';
import { FastAnswersModelState } from './types';

type CommonPayload = FastAnswersSetPayload;

interface CustomReducerMap extends ReducerMap<FastAnswersModelState, CommonPayload> {
    [FastAnswersActions.Set]: ReducerMapValue<FastAnswersModelState, FastAnswersSetPayload>;
    // eslint-disable-next-line @typescript-eslint/ban-types
    [FastAnswersActions.FetchStart]: ReducerMapValue<FastAnswersModelState, {}>;
}

const initialState = {
    loading: true,
    data: {},
};

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [FastAnswersActions.Set]: (state, { payload }) =>
        update(state, {
            $set: {
                loading: false,
                data: payload.data,
            },
        }),
    [FastAnswersActions.FetchStart]: state =>
        update(state, {
            loading: { $set: true },
        }),
};

export const reducer: MultiChatReducer<FastAnswersModelState, CommonPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
