import { useEffect, useState } from 'react';

import { UserModel } from 'modules/domain/users';

type Commission = {
    replyCommission: number;
    penalty: number;
};

export function useCommission() {
    const [commission, setCommission] = useState<Commission | null>(null);
    const { getOperatorFields } = UserModel.hooks.useUserApi();

    useEffect(() => {
        getOperatorFields().then(operator => {
            setCommission({
                replyCommission: operator?.commission || 0,
                penalty: operator?.trapPenalty || 0,
            });
        });
    }, [getOperatorFields]);

    return commission;
}
