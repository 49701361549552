import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'modules/App/store/root-state';

import { getFastAnswerInTypedMessage, getIsDialogInputFocused, getTypedMessage } from '../selectors';
import { actions } from '../actions';
import { FastAnswerInTypedMessage } from '../types';

export const useDialogTypedMessage = () => {
    const typedTextMessage = useSelector<RootState, string>(state => getTypedMessage(state));
    const fastAnswerInTypedMessage = useSelector<RootState, FastAnswerInTypedMessage | null>(state =>
        getFastAnswerInTypedMessage(state),
    );
    const isDialogInputFocused = useSelector<RootState, boolean>(state => getIsDialogInputFocused(state));

    const dispatch = useDispatch();

    const setTypedTextMessage = useCallback(
        (message: string) => {
            dispatch(actions.typeMessage(message));
        },
        [dispatch],
    );

    const setAdditionalMessagePart = useCallback(
        (message: string, type: 'smile' | 'fast-answer', payload?: FastAnswerInTypedMessage) => {
            dispatch(actions.setAdditionalMessagePart(message, payload));
        },
        [dispatch],
    );

    const setIsDialogInputFocused = useCallback(
        (isFocused: boolean) => {
            dispatch(actions.setDialogInputIsFocused(isFocused));
        },
        [dispatch],
    );

    return {
        typedTextMessage,
        isDialogInputFocused,
        fastAnswerInTypedMessage,
        setTypedTextMessage,
        setAdditionalMessagePart,
        setIsDialogInputFocused,
    };
};
