import { IHttpTransport } from 'modules/api/transport';

import { IEventsDomain } from './contracts';
import { EventDto } from './dto';

export class EventsDomain implements IEventsDomain {
    constructor(private readonly transport: IHttpTransport) {}

    postEvent(userId: string, event: EventDto): Promise<void> {
        return this.transport.post<void>(`events/${userId}`, event).then(response => response.data);
    }
}
