import { MessageType } from 'modules/domain/dialog/types';

const FILE_TYPES = Object.freeze({
    get: (type: string): string => {
        switch (true) {
            case /image/.test(type):
                return 'photo';
            default:
                return '';
        }
    },
});

export const MESSAGE_TYPES = Object.freeze({
    get: (type: string): MessageType | null => {
        switch (true) {
            case /image/.test(type):
                return MessageType.PHOTO;
            default:
                return null;
        }
    },
});

export const getFormData = (files: any) => {
    const filesArray = Array.isArray(files) ? files : [files];
    const formData = new FormData();

    filesArray.forEach(file => {
        FILE_TYPES.get(file.type) &&
            formData.append(FILE_TYPES.get(file.type), new Blob([file], { type: file.type }), file.name);
    });

    return formData;
};
