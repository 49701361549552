import { getShardUri } from 'modules/utils';
import { AppConfig } from 'modules/config';
import { getUserThumbnail } from 'modules/domain/users/user-adapter';

import { ApiMedia, ApiSentMedia, Media, SentMedia, SentMediaType } from './types';

export const apiMediaToMedia = (
    appConfig: AppConfig,
    userId: string,
    media: Pick<ApiMedia, 'basename' | 'tags'>[],
    mediaSize = 72,
): Media[] => {
    const parsedMedia = media.map(mediaItem => {
        const isThumbnail = mediaItem.tags.includes('thumbnail');
        const isPrivate = mediaItem.tags.includes('hidden');

        const [, backgroundColor] = (mediaItem.tags.find(tag => tag.startsWith('color:')) || '').split(':');

        const configuration: Media['configuration'] = {
            backgroundColor: backgroundColor ? `#${backgroundColor}` : undefined,
        };

        return {
            id: mediaItem.basename,
            url: getUserThumbnail({ id: userId, thumbnail: mediaItem.basename }, appConfig, mediaSize, isPrivate),
            isThumbnail,
            isPrivate,
            configuration,
        } as Media;
    });

    return parsedMedia;
};

export const apiMediaToChatMedia = (
    appConfig: AppConfig,
    userId: string,
    media: Pick<ApiMedia, 'basename'>[],
    size = 160,
    token: string,
) => {
    const parsedMedia = media.map(mediaItem => {
        const url = `/users/${userId}/photos/chat/file/${mediaItem.basename}`;

        return {
            id: mediaItem.basename,
            url,
            thumbnail: getShardUri(
                `${url}.${size}x${size}.thumb-fd?authorization=Token%20token="${token}"`,
                appConfig.sharding,
            ),
        };
    });

    return parsedMedia;
};

export const apiSentMediaAdapter = (
    appConfig: AppConfig,
    animatorId: string,
    clientId: string,
    sentMedia: ApiSentMedia[],
    size = 72,
    token: string,
): SentMedia[] => {
    const parsedMedia = sentMedia.map(sentMediaItem => {
        const { baseName, type } = sentMediaItem;

        if (type === SentMediaType.chat) {
            const url = `/users/${animatorId}/photos/chat/file/${baseName}`;
            return {
                id: baseName,
                url,
                thumbnail: getShardUri(
                    `${url}.${size}x${size}.thumb-fd?authorization=Token%20token="${token}"`,
                    appConfig.sharding,
                ),
                type,
            };
        } else {
            const url = `/dialogs/usermedia/${clientId}/${animatorId}/${baseName}`;

            return {
                id: baseName,
                url,
                thumbnail: getShardUri(
                    `${url}.${size}x${size}.thumb-fd?authorization=Token%20token="${token}"`,
                    appConfig.sharding,
                ),
                type,
            };
        }
    });

    return parsedMedia;
};
