import { IHttpTransport } from 'modules/api/transport/http.contracts';
import { calculateProgress } from 'modules/api/utils';

import { DownloadAnimatorAudioFilePayload, DownloadClientAudioFilePayload, IDialogsAudioDomain } from './contracts';
import { AudioFileDto, AudioMessageTemplateDto, AudioMessageTemplatesResponseDto } from './dto';

export class DialogsAudioDomain implements IDialogsAudioDomain {
    constructor(private readonly transport: IHttpTransport) {}

    getAudioMessageTemplates(animatorId: string, attendeeId: string): Promise<AudioMessageTemplateDto[]> {
        return this.transport
            .get<AudioMessageTemplatesResponseDto>(`/multichat/dialogs/${animatorId}/${attendeeId}/audio/list`)
            .then(response => response.data.messages);
    }

    downloadAttendeeAudioFile(params: DownloadClientAudioFilePayload): Promise<AudioFileDto> {
        const { animatorId, attendeeId, basename, onDownloadProgress } = params;

        return this.downloadAudioFile(`/dialogs/audios/${animatorId}/${attendeeId}/${basename}`, onDownloadProgress);
    }

    downloadAnimatorAudioFile(params: DownloadAnimatorAudioFilePayload): Promise<AudioFileDto> {
        const { animatorId, basename, onDownloadProgress } = params;

        return this.downloadAudioFile(`users/${animatorId}/audios/${basename}`, onDownloadProgress);
    }

    private async downloadAudioFile(path: string, onDownloadProgress?: (progress: number) => void) {
        const response = await this.transport.get<string>(path, {
            responseType: 'arraybuffer',
            onDownloadProgress: event => {
                onDownloadProgress?.(calculateProgress(event));
            },
        });

        const type = response.headers['content-type'] || 'audio/mp4';

        const blob = new Blob([response.data], {
            type,
        });

        const url = URL.createObjectURL(blob);

        return { url };
    }
}
