import React, { useCallback } from 'react';

import { DialogModel } from 'modules/domain/dialog';
import { ModalModel } from 'modules/domain/modals';

import { DialogsSearchForm } from './form';

export const DialogSearchModal = () => {
    const { findDialog } = DialogModel.hooks.useDialogActions();
    const { closeModal } = ModalModel.hooks.useModal();

    const submit = useCallback(
        (userId, animatorId) => {
            findDialog(userId, animatorId).finally(() => closeModal());
        },
        [findDialog, closeModal],
    );

    return <DialogsSearchForm onSubmit={submit} onClickClose={closeModal} />;
};
