import { Dictionary } from './types';

export const namespace = 'dictionary';

export enum DictionaryActions {
    Set = 'dictionary.set',
}

export type SetDictionaryPayload = { name: string; input: Dictionary };
export type SetDictionaryAction = { type: DictionaryActions; payload: SetDictionaryPayload };

export function setDictionary(name: string, input: Dictionary): SetDictionaryAction {
    return { type: DictionaryActions.Set, payload: { name, input } };
}

export const actions = {
    setDictionary,
};
