import { ModalNames, ModalData } from './types';

export const namespace = 'modal';

export enum ModalActions {
    Open = `modal.open`,
    Close = 'modal.close',
}

export type OpenModalPayload<T = ModalData> = { name: ModalNames; data?: T };
type OpenModalAction<T> = { type: ModalActions; payload: OpenModalPayload<T> };

export const openModal = <T>(modalName: ModalNames, data?: T): OpenModalAction<T> => ({
    type: ModalActions.Open,
    payload: { name: modalName, data },
});

// eslint-disable-next-line @typescript-eslint/ban-types
export type CloseModalPayload = {};
type CloseModalAction = { type: ModalActions; payload: CloseModalPayload };

export const closeModal = (): CloseModalAction => ({
    type: ModalActions.Close,
    payload: {},
});
