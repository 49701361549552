import React, { memo, useMemo } from 'react';
import copy from 'copy-to-clipboard';

import { ThreeDotsIcon } from 'modules/components/icon';
import { RoundButton } from 'modules/components/common/button/round';
import { useSignOut } from 'modules/domain/users/hooks';
import { ModalNames } from 'modules/domain/modals/types';
import { ModalModel } from 'modules/domain/modals';
import { DialogModel } from 'modules/domain/dialog';
import { TooltipMenu } from 'modules/components/tooltip-menu';
import { MenuItemProps } from 'modules/components/tooltip-menu/menu-item';
import { toast, testId, parseDialogId } from 'modules/utils';

import { useDialogSearchEnabled } from './hooks';
import { UserIcon, SearchIcon, LogoutIcon } from './icons';

export const ThreeDotsMenu = memo(() => {
    const signOut = useSignOut();
    const { openModal } = ModalModel.hooks.useModal();
    const { dialogId } = DialogModel.hooks.useSelectors();
    const dialogSearchEnabled = useDialogSearchEnabled();

    const tooltipOptions = useMemo(() => {
        const { attendeeId, animatorId } = parseDialogId(dialogId);

        const options: MenuItemProps[] = [
            {
                icon: <UserIcon />,
                caption: `Clients's ID: ${attendeeId}`,
                onClick: () => {
                    copy(attendeeId);
                    toast.showMessage("Client's ID copied to clipboard");
                },
                testIdPrefix: 'header-copy-client-id-button',
                doNotClose: true,
            },
            {
                icon: <UserIcon />,
                caption: `Animator's ID: ${animatorId}`,
                onClick: () => {
                    copy(animatorId);
                    toast.showMessage("Animator's ID copied to clipboard");
                },
                testIdPrefix: 'header-copy-animator-id-button',
                doNotClose: true,
            },
        ];

        if (dialogSearchEnabled) {
            options.push({
                icon: <SearchIcon />,
                caption: 'Search by ID',
                onClick: () => openModal(ModalNames.DialogSearch),
                testIdPrefix: 'logout-menu__search-by-id-button',
            });
        }

        options.push({
            icon: <LogoutIcon />,
            caption: 'Logout',
            onClick: signOut,
            dangerous: true,
            testIdPrefix: 'logout-menu__logout-button',
        });

        return options;
    }, [dialogId, signOut, openModal, dialogSearchEnabled]);

    return (
        <TooltipMenu
            position={'bottom-right'}
            trigger={
                <RoundButton size="md" theme="darker" {...testId('logout-menu__trigger-button')}>
                    <ThreeDotsIcon />
                </RoundButton>
            }
            options={tooltipOptions}
        />
    );
});
