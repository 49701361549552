import React, { memo } from 'react';

import { RoundButton } from 'modules/components/common/button/round';
import { CrossIcon } from 'modules/components/icon/cross';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
    a11yLabel?: string;
    onClick: () => void;
};

export const CloseButton = memo((props: Props) => {
    const { a11yLabel, onClick } = props;
    return (
        <RoundButton
            {...testId(`${a11yLabel ? a11yLabel + '-' : ''}close-modal-button`)}
            onClick={onClick}
            size="xs"
            theme="white"
            className={styles.container}
        >
            <CrossIcon />
        </RoundButton>
    );
});
