import React from 'react';

export const SendMediaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
        <rect width="18" height="18" rx="3" fill="#DAEDFF" />
        <path
            d="M0 13.037L3.08579 10.4845C3.86683 9.83849 5.13316 9.83849 5.91421 10.4845L15 18H3C1.34315 18 0 16.6569 0 15V13.037Z"
            fill="#0B74D6"
        />
        <path
            d="M4.5 7C5.88071 7 7 5.88071 7 4.5C7 3.11929 5.88071 2 4.5 2C3.11929 2 2 3.11929 2 4.5C2 5.88071 3.11929 7 4.5 7Z"
            fill="#0B8AFF"
        />
        <path
            d="M18 11.9997L14.9142 8.91391C14.1332 8.13286 12.8668 8.13286 12.0858 8.91391L3 17.9997H15C16.6569 17.9997 18 16.6566 18 14.9997V11.9997Z"
            fill="#0B8AFF"
        />
    </svg>
);
