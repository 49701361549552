import { request } from 'modules/utils';

import { SDVLegacyAPI } from '../legacyAPIType';

export async function getAvailableDates(
    api: SDVLegacyAPI,
    animatorId: string,
    recipientId: string,
    dateFrom: string,
    dateTo: string,
) {
    const res = await request(
        `${api.getBaseHost()}/dialogs/messages/${animatorId}/${recipientId}/dates?dateFrom=${dateFrom}&dateTo=${dateTo}`,
        {
            headers: {
                Authorization: api.authorize(),
                'content-type': 'application/json',
            },
        },
    );
    const resJSON = JSON.parse(res.response);
    return resJSON.dates;
}
