import { AudioMessageTemplate } from 'modules/domain/dialog/audio';

type TemplatesByCategories = Record<string, AudioMessageTemplate[]>;

type GroupTemplatesByCategoriesResult = {
    templatesByCategories: TemplatesByCategories | null;
    categories: string[];
};

export const groupTemplatesByCategories = (
    templates: AudioMessageTemplate[] | undefined,
): GroupTemplatesByCategoriesResult => {
    if (!templates) {
        return {
            templatesByCategories: null,
            categories: [],
        };
    }

    let templatesByCategories: TemplatesByCategories = {};

    templates.forEach(template => {
        if (templatesByCategories[template.topic]) {
            templatesByCategories[template.topic].push(template);
        } else {
            templatesByCategories[template.topic] = [template];
        }
    });

    const sortedCategories = Object.keys(templatesByCategories).sort();

    templatesByCategories = sortedCategories.reduce((result, topic) => {
        result[topic] = templatesByCategories[topic];
        return result;
    }, {} as TemplatesByCategories);

    return { templatesByCategories, categories: sortedCategories };
};
