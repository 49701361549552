import React, { HTMLAttributes, memo } from 'react';

import { UserModel } from 'modules/domain/users';
import { Image } from 'modules/components/image';
import fallbackMaleImage from 'images/fallback-male.svg';
import fallbackFemaleImage from 'images/fallback-female.svg';
import fallbackUnknownImage from 'images/fallback-unknown-gender.svg';

type Props = {
    ownerId: string;
} & HTMLAttributes<HTMLImageElement>;

export const UserThumbnail = memo((props: Props) => {
    const { ownerId, className: externalStyles } = props;
    const { user } = UserModel.hooks.useUserSelectorsById(ownerId);
    const { parsedThumbnailForAvatar, name, gender } = user;

    return (
        <Image
            fallback={!gender ? fallbackUnknownImage : gender === 'mal' ? fallbackMaleImage : fallbackFemaleImage}
            src={parsedThumbnailForAvatar}
            className={externalStyles}
            alt={name}
        />
    );
});
