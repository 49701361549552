import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { actions } from 'modules/domain/dialog/actions';
import {
    getAnimatorPreference,
    getDialogNoteImproved,
    postDialogNote,
    patchDialogNoteImproved,
} from 'modules/domain/dialog/api';
import { useAuthApi } from 'modules/api-old/use-auth-api';
import { chatLoHiId } from 'modules/utils/lo-hi';
import { getDialogId, parseDialogId, useLogger } from 'modules/utils';

import { Note, ClientImprovedNote } from '../types';

import { useDialogAnalytics } from './use-dialog-analytics';

export const useDialogNoteApi = () => {
    const dispatch = useDispatch();
    const { logError } = useLogger('dialog notes');
    const { api } = useAuthApi();
    const { trackAnimatorStoryTold } = useDialogAnalytics();

    const fetchDialogNotesImproved = useCallback(
        async (animatorId: string, attendeeId: string) => {
            const dialogId = getDialogId(animatorId, attendeeId);

            try {
                const note = await getDialogNoteImproved(api, animatorId, attendeeId);
                if (note) {
                    dispatch(actions.setDialogNotesImproved(dialogId, note));
                }
            } catch (error) {
                logError('fetchUserNote error', { dialogId, error });
            }
        },
        [dispatch, api, logError],
    );

    const fetchDialogNotes = useCallback(
        (animatorId: string, attendeeId: string) => {
            fetchDialogNotesImproved(animatorId, attendeeId);
        },
        [fetchDialogNotesImproved],
    );

    const fetchDialogPreferences = useCallback(
        async (animatorId: string, attendeeId: string) => {
            const dialogId = getDialogId(animatorId, attendeeId);

            try {
                const preferences = await getAnimatorPreference(api, animatorId);
                dispatch(actions.setDialogPreferences(dialogId, preferences));
            } catch (error) {
                logError('fetchAnimatorPreferences error', { dialogId, animatorId, error });
            }
        },
        [dispatch, api, logError],
    );

    const saveDialogNotes = useCallback(
        async (dialogId: string, note: Note) => {
            const { animatorId, attendeeId } = parseDialogId(dialogId);

            try {
                await postDialogNote(api, chatLoHiId(animatorId, attendeeId), note);

                if (note.storyTold === 'true') {
                    trackAnimatorStoryTold(animatorId, attendeeId);
                }

                dispatch(actions.setDialogNotes(dialogId, note));
            } catch (error) {
                logError('saveDialogNotes error', { dialogId, note, error });
            }
        },
        [dispatch, api, logError, trackAnimatorStoryTold],
    );

    const saveDialogNotesImproved = useCallback(
        async (dialogId: string, note: ClientImprovedNote) => {
            const { animatorId, attendeeId } = parseDialogId(dialogId);

            try {
                await patchDialogNoteImproved(api, animatorId, attendeeId, note);

                dispatch(actions.setDialogNotesImproved(dialogId, note));
            } catch (error) {
                logError('Save dialog notesImproved (error)', {
                    animatorId,
                    attendeeId,
                    note: JSON.stringify(note),
                    error,
                });
            }
        },
        [dispatch, api, logError],
    );

    return {
        fetchDialogNotes,
        fetchDialogPreferences,
        saveDialogNotes,
        saveDialogNotesImproved,
    };
};
