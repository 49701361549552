import { AutoGeneratedAnswersDto } from 'modules/api/dto';

import { Metrics, Note, Preference, ClientImprovedNote } from '../types';
import { AudioMessageTemplate } from '../audio';

import { apiDialogActions } from './api-dialog-actions';
import { entityActions } from './entity-actions';
import { apiMessageActions } from './api-dialog-message-actions';
import { searchMessagesActions } from './search-messages-actions';
import { messageTypingActions } from './message-typing';
import { DialogActions } from './common';

export type SetDialogNotesPayload = { id: string; input: Note };
export type SetDialogNotesAction = { type: DialogActions; payload: SetDialogNotesPayload };

export type SetDialogNotesImprovedPayload = { id: string; input: ClientImprovedNote };
export type SetDialogNotesImprovedAction = { type: DialogActions; payload: SetDialogNotesImprovedPayload };

export type SetDialogPreferencesPayload = { id: string; input: Preference };
export type SetDialogPreferencesAction = { type: DialogActions; payload: SetDialogPreferencesPayload };

export function setDialogNotes(id: string, input: Note): SetDialogNotesAction {
    return { type: DialogActions.SetNote, payload: { id, input } };
}

export function setDialogNotesImproved(id: string, input: ClientImprovedNote): SetDialogNotesImprovedAction {
    return { type: DialogActions.SetNoteImproved, payload: { id, input } };
}

export function setDialogPreferences(id: string, input: Preference): SetDialogPreferencesAction {
    return { type: DialogActions.SetPreference, payload: { id, input } };
}

export type SetDialogsMetricsPayload = { metrics: Metrics };
export type SetLastBotMessagePayload = { lastBotMessage: boolean };
export type SetDialogsMetricsAction = { type: DialogActions; payload: SetDialogsMetricsPayload };
export type SetLastBotMessageAction = { type: DialogActions; payload: SetLastBotMessagePayload };

export function setDialogsMetrics(metrics: Metrics): SetDialogsMetricsAction {
    return { type: DialogActions.SetMetrics, payload: { metrics } };
}

export function setLastBotMessage(lastBotMessage: boolean): SetLastBotMessageAction {
    return { type: DialogActions.SetLastBotMessage, payload: { lastBotMessage } };
}

export type SetDialogExplicitStatusPayload = { id: string; status: boolean };
export type SetDialogExplicitStatusAction = { type: DialogActions; payload: SetDialogExplicitStatusPayload };

export function setExplicitStatus(id: string, status: boolean): SetDialogExplicitStatusAction {
    return { type: DialogActions.SetDialogExplicitStatus, payload: { id, status } };
}

export type RemoveMessageByTagPayload = { dialogId: string; tag: string };
export type RemoveMessageByTagAction = { type: DialogActions; payload: RemoveMessageByTagPayload };

function removeMessageByTag(dialogId: string, tag: string): RemoveMessageByTagAction {
    return { type: DialogActions.RemoveMessageByTag, payload: { dialogId, tag } };
}

export type ResetCopilotStateAction = { type: DialogActions.ResetCopilotState };

function resetCopilotState(): ResetCopilotStateAction {
    return { type: DialogActions.ResetCopilotState };
}

export type CopilotLoadSucceedPayload = AutoGeneratedAnswersDto;

function copilotLoadSucceed(copilotData: AutoGeneratedAnswersDto) {
    return {
        type: DialogActions.CopilotLoadSucceed,
        payload: copilotData,
    };
}

function copilotOptionClicked(message: string) {
    return {
        type: DialogActions.CopilotOptionClicked,
        payload: { message: message },
    };
}

export type SetScrollToMessageIdPayload = {
    messageId: number | null;
};

function setScrollToMessageId(messageId: number | null) {
    return {
        type: DialogActions.SetScrollToMessageId,
        payload: { messageId },
    };
}

export type SetAudioMessagesPayload = {
    dialogId: string;
    audioMessages: AudioMessageTemplate[];
};
export type SetAudioMessagesAction = {
    type: DialogActions.SetAudioMessages;
    payload: SetAudioMessagesPayload;
};

function setAudioMessages(dialogId: string, audioMessages: AudioMessageTemplate[]): SetAudioMessagesAction {
    return {
        type: DialogActions.SetAudioMessages,
        payload: { dialogId, audioMessages },
    };
}

export type ResetAudioMessagesPayload = {
    dialogId: string;
};
export type ResetAudioMessagesAction = {
    type: DialogActions.ResetAudioMessages;
    payload: ResetAudioMessagesPayload;
};

function resetAudioMessages(dialogId: string): ResetAudioMessagesAction {
    return {
        type: DialogActions.ResetAudioMessages,
        payload: { dialogId },
    };
}

export const actions = {
    ...apiDialogActions,
    ...apiMessageActions,
    ...searchMessagesActions,
    ...entityActions,
    ...messageTypingActions,
    setDialogNotes,
    setDialogNotesImproved,
    setDialogPreferences,
    setDialogsMetrics,
    setLastBotMessage,
    setExplicitStatus,
    removeMessageByTag,
    resetCopilotState,
    copilotLoadSucceed,
    copilotOptionClicked,
    setScrollToMessageId,
    setAudioMessages,
    resetAudioMessages,
};
