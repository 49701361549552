import { useCallback } from 'react';

import { useOperatorId } from 'modules/domain/auth';

import { useApi } from './use-api';

export const useAnnalsApi = () => {
    const { annals } = useApi();
    const operatorId = useOperatorId();

    const send = useCallback(
        (event: string, data?: object) => annals.send(operatorId, event, data),
        [annals, operatorId],
    );

    return { send };
};
