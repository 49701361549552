import { useEffect } from 'react';
import { useLocation, useNavigationType, createRoutesFromChildren, matchRoutes } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { clientConfig } from 'modules/config/client';

export function init() {
    const { env } = clientConfig;

    if (env === 'debug') {
        // If you want to test sentry logs in debug mode just disable this check
        return;
    }

    Sentry.init({
        dsn: clientConfig.sentry.dsn,
        environment: env,
        release: clientConfig.revision,
        integrations: [
            Sentry.reactRouterV6BrowserTracingIntegration({
                useEffect,
                useLocation,
                useNavigationType,
                createRoutesFromChildren,
                matchRoutes,
            }),
        ],
        // Capture 1% of the transactions
        tracesSampleRate: 0.01,
    });

    console.log(`Initialize Sentry with env: ${env}`);
}
