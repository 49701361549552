import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import { testId } from 'modules/utils/test-id';
import { MessageType } from 'modules/domain/dialog/types';
import { UserThumbnail } from 'modules/components/user/user-thumbnail';

import styles from './index.module.css';

type Props = PropsWithChildren<{
    isOutgoingMessage: boolean;
    isLastReadOutgoingMessage: boolean;
    messageType: MessageType;
    onClickReport: () => void;
    recipientId: string;
}>;

export const CommonMessageControls = (props: Props) => {
    const { children, isOutgoingMessage, isLastReadOutgoingMessage, messageType, onClickReport, recipientId } = props;

    const containerClassName = classNames(styles.reportBtnContainer, {
        [styles.reportBtnWithDeliveryStatus]: isOutgoingMessage && isLastReadOutgoingMessage,
        [styles.reportBtnWithoutDeliveryStatus]: isOutgoingMessage && !isLastReadOutgoingMessage,
        [styles.reportBtnWithDeliveryStatusForOutgoingMessage]: !isOutgoingMessage,
    });

    const showReport =
        messageType === MessageType.TEXT || messageType === MessageType.GIFT || messageType === MessageType.PHOTO;

    return (
        <div
            className={classNames(styles.item, {
                [styles.reportWrapperLeft]: !isOutgoingMessage,
                [styles.reportWrapperRight]: isOutgoingMessage,
            })}
        >
            {showReport ? (
                <div {...testId('message-report-button')} className={containerClassName}>
                    <button onClick={onClickReport} className={styles.reportBtn}>
                        Report
                    </button>
                </div>
            ) : null}
            {isLastReadOutgoingMessage && (
                <div {...testId('message-delivery-status')} className={styles.deliveryStatus}>
                    <UserThumbnail ownerId={recipientId} className={styles.deliveryStatusThumbnail} />
                </div>
            )}
            {children}
        </div>
    );
};
