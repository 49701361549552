import { useState, useEffect } from 'react';

import { useApi } from 'modules/api';
import { useLogger } from 'modules/utils';

export function useIcebreakersAvailable(attendeeId: string) {
    const [icebreakersAvailable, setIcebreakersAvailable] = useState<boolean>(false);

    const { features: featuresApi } = useApi();
    const { logError } = useLogger('useIcebreakersAvailable');

    useEffect(() => {
        featuresApi
            .getIcebreakersAvailability(attendeeId)
            .then(result => setIcebreakersAvailable(result.enabled))
            .catch(error => {
                logError(`Failed to get icebreakers availability`, { error, attendeeId });
            });
    }, [featuresApi, logError, attendeeId]);

    return icebreakersAvailable;
}
