import React, { memo } from 'react';

import { useStatsContext } from 'modules/components/stats-page/context';
import { AppRoutes } from 'modules/navigation';

import { NavButton } from '../nav-button';

import styles from './index.module.css';

export const PageSwitcher = memo(() => {
    const { showKPI } = useStatsContext();

    return (
        <div className={styles.switcher}>
            <NavButton title="Income" url={AppRoutes.Income} />
            {showKPI && <NavButton title="KPI" url={AppRoutes.Kpi} />}
        </div>
    );
});
