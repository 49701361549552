export type LoHiType = {
    lower: string;
    higher: string;
};

export function chatLoHiId(a: string, b: string): LoHiType {
    const lower = +a > +b ? b : a;
    const higher = lower === a ? b : a;

    return {
        lower,
        higher,
    };
}
