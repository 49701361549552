import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { User } from 'modules/domain/users/types';
import { useAuth } from 'modules/domain/auth/use-auth';

import { getChatMedia, getChatMediaState } from '../api';
import { actions } from '../actions';
import { apiMediaToChatMedia, apiSentMediaAdapter } from '../media-adapter';

import { useCurrentApi } from './use-current-api';
import { useChatMediaEnabled } from './use-chat-media-enabled';

export const useChatMediaApi = () => {
    const dispatch = useDispatch();
    const { currentApi, appConfig } = useCurrentApi();
    const {
        auth: { token = '' },
    } = useAuth();

    const fetchChatMediaData = useCallback(
        async (userId: string, clientId: string) => {
            const userAllMediaList = await getChatMedia(currentApi, userId);
            const userMediaState = await getChatMediaState(currentApi, userId, clientId);
            const sentMedia = apiSentMediaAdapter(appConfig, userId, clientId, userMediaState.sent, 72, token);
            const userMediaList = (userAllMediaList || [])
                .filter(
                    basename =>
                        userMediaState.sent?.findIndex(allowedMedia => allowedMedia.baseName === basename) === -1,
                )
                .map(basename => ({
                    basename,
                    tags: [],
                }));
            const media = apiMediaToChatMedia(
                appConfig,
                userId,
                userMediaList,
                Math.round(160 * window.devicePixelRatio),
                token,
            );

            dispatch(
                actions.setChatMediaList(userId, {
                    list: media,
                    enabled: userMediaState.result,
                    available: true,
                    sentMedia,
                }),
            );
        },
        [dispatch, currentApi, appConfig, token],
    );

    const fetchChatMediaState = useCallback(
        async (userId: string, clientId: string) => {
            const userMediaState = await getChatMediaState(currentApi, userId, clientId);
            const sentMedia = apiSentMediaAdapter(appConfig, userId, clientId, userMediaState.sent, 72, token);

            dispatch(
                actions.updateChatMediaState(userId, {
                    ...userMediaState,
                    sentMedia,
                }),
            );

            return userMediaState;
        },
        [appConfig, token, dispatch, currentApi],
    );

    return {
        fetchChatMediaData,
        fetchChatMediaState,
    };
};

export const useChatMediaStateWatcher = (userId?: string, clientId?: string) => {
    const { fetchChatMediaState } = useChatMediaApi();
    const enabled = useChatMediaEnabled(userId || '');

    useEffect(() => {
        const isChatInMediaSupported = !!userId && !!clientId;
        const needToGetUpdate = typeof enabled === 'boolean' && !enabled;

        if (!isChatInMediaSupported || !needToGetUpdate) {
            return;
        }

        const timerId = setInterval(async () => {
            const mediaState = await fetchChatMediaState(userId, clientId);

            if (mediaState.result) {
                clearInterval(timerId);
            }
        }, 6000);

        return () => {
            clearInterval(timerId);
        };
    }, [userId, clientId, fetchChatMediaState, enabled]);
};

export const useChatMedia = (animatorUser: User, attendeeUser: User) => {
    const dispatch = useDispatch();
    const { fetchChatMediaData } = useChatMediaApi();

    useEffect(() => {
        const usersExist = !!animatorUser.id && !!attendeeUser.id;

        if (usersExist) {
            dispatch(actions.setChatMediaList(animatorUser.id, { list: [], available: true, sentMedia: [] }));
            fetchChatMediaData(animatorUser.id, attendeeUser.id);
        } else if (animatorUser.id) {
            dispatch(actions.setChatMediaList(animatorUser.id, { list: [], available: false, sentMedia: [] }));
        }
    }, [animatorUser.id, attendeeUser.id, dispatch, fetchChatMediaData]);

    useChatMediaStateWatcher(animatorUser.id, attendeeUser.id);
};
