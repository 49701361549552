import pify from 'pify';

import { SDVLegacyAPI } from 'modules/domain/legacyAPIType';

import { GiftsAllowedDataDto } from '../types';

export function fetchGiftsAllowedData(
    api: SDVLegacyAPI,
    senderId: string,
    recipientId: string,
): Promise<GiftsAllowedDataDto> {
    return pify(api.multichat.dialogs.gifts.allowed.get)(senderId, recipientId);
}
