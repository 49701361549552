import { clientConfig } from 'modules/config/client';

export const APP_ROUTE = clientConfig.appName;

export const AppRoutes = {
    Chat: `/${APP_ROUTE}`,
    Login: `/${APP_ROUTE}/login`,
    Offline: `/${APP_ROUTE}/offline`,
    Stats: `/${APP_ROUTE}/stats`,
    Kpi: `/${APP_ROUTE}/stats/kpi`,
    Income: `/${APP_ROUTE}/stats/income`,
} as const;
export type AppRoutes = typeof AppRoutes;
