import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { User } from 'modules/domain/users/types';
import { useConfig } from 'modules/config';
import { Image } from 'modules/components/image';
import fallbackMaleImage from 'images/fallback-male.svg';
import fallbackFemaleImage from 'images/fallback-female.svg';
import fallbackNonImage from 'images/fallback-non.svg';
import fallbackUnknownImage from 'images/fallback-unknown-gender.svg';
import { ImageOverlay } from 'modules/components/image-overlay';
import { getUserThumbnail } from 'modules/domain/users/user-adapter';
import { MediaModel } from 'modules/domain/media';
import styles from 'modules/components/user/user-panel/index.module.scss';

export type Props = Pick<User, 'thumbnail' | 'name' | 'id' | 'gender' | 'parsedThumbnailForAvatar'> & {
    a11yLabel?: string;
};

const IMAGE_SIZE = 560;

export const Avatar = memo((props: Props) => {
    const { thumbnail, name, id, gender, parsedThumbnailForAvatar, a11yLabel } = props;
    const appConfig = useConfig();

    const media = useSelector(state => MediaModel.selectors.getMedia(state, id));
    const memoizedMedia = useMemo(() => media || [], [media]);

    const fallback = !gender
        ? fallbackUnknownImage
        : gender === 'mal'
        ? fallbackMaleImage
        : gender === 'fem'
        ? fallbackFemaleImage
        : fallbackNonImage;
    const imageSrc = parsedThumbnailForAvatar || fallback;
    const overlaySrc = (id && thumbnail && getUserThumbnail({ id, thumbnail }, appConfig, IMAGE_SIZE)) || fallback;

    const sources = useMemo(() => {
        const temp = [overlaySrc];

        const gallery = memoizedMedia
            .filter(file => file.id !== thumbnail)
            .map(file => getUserThumbnail({ id, thumbnail: file.id }, appConfig, IMAGE_SIZE));

        return [...temp, ...gallery];
    }, [appConfig, id, memoizedMedia, overlaySrc, thumbnail]) as string[];

    return (
        <ImageOverlay src={sources} size={IMAGE_SIZE}>
            <Image a11yLabel={a11yLabel} className={styles.thumbnail} fallback={fallback} src={imageSrc} alt={name} />
        </ImageOverlay>
    );
});
