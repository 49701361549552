import classNames from 'classnames';
import { ToastOptions, toast } from 'react-toastify';

import styles from './styles.module.scss';

export type { ToastOptions };

export const showMessage = (message: string, options?: ToastOptions) => {
    toast(message, options);
};

export const showError = (message: string, options?: ToastOptions) => {
    const { className, ...rest } = options || {};

    showMessage(message, {
        className: classNames(styles.errorToast, className),
        ...rest,
    });
};
