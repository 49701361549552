import { AutoGeneratedAnswersUsage } from './automation/dto';

export enum MessageDeliveryStatus {
    UNKNOWN = 0,
    DELIVERED = 1,
    UNPAID = 2,
    FAILED = 3,
}

export enum AutomationProductName {
    Onboarding = 'onboarding',
    Invitation = 'invitation',
    Bot = 'bot',
    Copilot = 'copilot',
}

export type AudioTranscriptionDto = {
    text?: string;
    error?: boolean;
    /**
     * Transcription accuracy.
     * Value range: 0 - 1 (1 means 100%)
     */
    score?: number;
};

export type DialogMessageMetaDto = {
    Auto?: boolean;
    automation?: {
        product: AutomationProductName;
        usage?: AutoGeneratedAnswersUsage;
        bot?: string;
    };
    gift?: string;
    giftId?: string;
    isAnimated?: boolean;
    localPhotoUri?: string;
    reason?: string;
    reference?: string;
    unansweredUnpaid?: boolean;
    icebreakerType?: 'erotic' | 'general';
    icebreakerText?: string;
    sensitive?: boolean;
    // audio related meta
    audioMessageId?: number;
    transcription?: AudioTranscriptionDto;
};

export type DialogMessageBaseDto = {
    tag: string;
    sender: string;
    recipient: string;
    text: string;
    timestamp: number;
    meta: DialogMessageMetaDto;
};

export type DialogMessageDto = DialogMessageBaseDto & {
    id: number;
    status?: MessageDeliveryStatus;
    read?: boolean;
};

export type SearchedDialogMessageDto = {
    messageId: number;
    /**
     * Shows message position in the chat text messages list (not considering other types of messages).
     * Starts from 1
     */
    n: number;
};

export type SearchedDialogMessagesDto = {
    totalCount: number;
    messages: SearchedDialogMessageDto[];
};
