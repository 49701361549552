import { DialogMessage } from 'modules/domain/dialog/types';
import { DialogMessageDto } from 'modules/api/dto';

import { messagesHaveTheSameDate } from './messages-have-the-same-date';
import { singleMessageAdapter } from './single-message-adapter';

export const messagesAdapter = (messages: DialogMessageDto[], identity: string, timezone: number): DialogMessage[] => {
    const adaptedMessages: DialogMessage[] = [];
    let onboardingNotAttached = true;
    messages.forEach((message, index) => {
        const previousMessage = messages[index + 1];
        const firstMessageOfTheDay = !messagesHaveTheSameDate(message, previousMessage, timezone);
        const attachOnboarding = onboardingNotAttached && firstMessageOfTheDay;
        if (attachOnboarding) {
            onboardingNotAttached = false;
        }
        return adaptedMessages.push(singleMessageAdapter(message, identity, firstMessageOfTheDay, attachOnboarding));
    });
    return adaptedMessages;
};
