import React from 'react';
import classNames from 'classnames';

import { testId } from 'modules/utils/test-id';

import { IndicatorStatus } from '../hooks/use-typed-message-indicator';

import styles from './index.module.scss';

type Props = {
    status: IndicatorStatus;
    percent: number;
};

const statusToStylesMap: Record<IndicatorStatus, string> = {
    [IndicatorStatus.Bad]: styles.danger,
    [IndicatorStatus.Normal]: styles.normal,
    [IndicatorStatus.Good]: styles.good,
    [IndicatorStatus.None]: '',
};

export const TypedMessageIndicator = (props: Props) => {
    const { status, percent } = props;

    const statusStyles = statusToStylesMap[status];

    return (
        <div
            className={classNames(styles.indicator, statusStyles)}
            style={{ width: `${percent}%` }}
            {...testId('send-message-input-indicator')}
        />
    );
};
