import { useState, useCallback, useMemo } from 'react';

import { useAuth } from 'modules/domain/auth/use-auth';
import { useConfig } from 'modules/config';

import { ResponseTimeStorage } from './storage';

export function useAverageTimer() {
    const {
        auth: { id },
    } = useAuth();
    const {
        timer: { responseLimitSeconds },
    } = useConfig();

    const [timeList, updateTimeList] = useState<number[]>(ResponseTimeStorage.get(id || '') || []);

    const addTime = useCallback(
        (time: string) => {
            const [mm, ss] = time.split(':');
            const timeInSeconds = parseInt(mm) * 60 + parseInt(ss);

            if (!timeInSeconds) {
                return;
            }

            ResponseTimeStorage.set(id || '', timeInSeconds);

            updateTimeList(times => [...times, timeInSeconds]);
        },
        [id],
    );

    const averageTime = useMemo(() => {
        if (!timeList.length) {
            return 0;
        }
        const sum = timeList.reduce((sum, item) => sum + item, 0);

        return Math.round(sum / timeList.length);
    }, [timeList]);

    const averageTimePretty = useMemo(() => {
        const mm = `${Math.trunc(averageTime / 60)}`.padStart(2, '0');
        const ss = `${averageTime % 60}`.padStart(2, '0');

        return `${mm}:${ss}`;
    }, [averageTime]);

    return {
        averageTime,
        outOfLimit: averageTime > responseLimitSeconds,
        averageTimePretty,
        addTime,
    };
}
