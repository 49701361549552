import { useGetShardUri } from 'modules/hooks';
import { useToken } from 'modules/domain/auth/use-token';
import { HttpTransport } from 'modules/api';

export type ImageParams = {
    basename: string;
    recipient: string;
    sender: string;
    size?: number;
};

const useAuthQuery = () => {
    const { token } = useToken();
    const authHeader = HttpTransport.getAuthHeader(token);
    return `?authorization=${encodeURIComponent(authHeader)}`;
};

export const useImageUri = (params: ImageParams) => {
    const { basename: basenameParam, recipient, sender, size } = params;
    const authQuery = useAuthQuery();
    const getShardUri = useGetShardUri();

    let basename = basenameParam.startsWith('/')
        ? basenameParam
        : `/dialogs/usermedia/${recipient}/${sender}/${basenameParam}`;

    if (typeof size === 'number') {
        basename += `.${size}x${size}`;
    }

    return getShardUri(basename + authQuery);
};
