export type ScoreResponse = {
    platform: string;
    level: 'High' | 'Low' | 'Medium';
    score: number;
    matchType?: string;
    content: string[];
};

export type TopContentResponse = {
    platform: string;
    top: {
        artists: string[];
        genres: string[];
        tracks: string[];
    };
};

export type MatchType = {
    score: ScoreResponse['score'];
    level: ScoreResponse['level'];
    artists: TopContentResponse['top']['artists'];
    genres: TopContentResponse['top']['genres'];
    tracks: TopContentResponse['top']['tracks'];
};

export type MatchResponseError = {
    error: string;
};

export const namespace = 'spotifyMatches' as const;

export type MatchState = {
    [namespace]: { [x: string]: MatchType };
};
