export enum IdentityRole {
    Animator = 'animator',
}

export type IdentityDto = {
    id: string;
    email: string;
    token: string;
    roles: IdentityRole[];
};
