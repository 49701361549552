import axios, { AxiosError, AxiosInstance, isAxiosError } from 'axios';

import { IHttpTransport, RequestConfig, RequestData, RequestHeaders, Response } from './http.contracts';
import { HttpError } from './http-error';

export class HttpTransport implements IHttpTransport {
    static getAuthHeader(token: string) {
        return `Token token="${token}"`;
    }

    protected readonly instance: AxiosInstance;
    protected readonly basicHeaders?: RequestHeaders;
    protected readonly host: string;

    constructor(host: string, basicHeaders?: RequestHeaders) {
        this.host = host;
        this.basicHeaders = basicHeaders;

        this.instance = axios.create({
            baseURL: host,
            headers: basicHeaders,
        });

        this.instance.interceptors.response.use(
            res => res,
            async error => {
                if (isAxiosError(error)) {
                    throw this.parseAxiosError(error);
                }
                throw error;
            },
        );
    }

    setAuthToken(token: string) {
        this.instance.defaults.headers.common['Authorization'] = HttpTransport.getAuthHeader(token);
    }

    clearAuthToken() {
        this.instance.defaults.headers.common['Authorization'] = undefined;
    }

    get<D>(url: string, config?: RequestConfig): Promise<Response<D>> {
        return this.instance.get(url, config);
    }

    post<D>(url: string, data?: RequestData, config?: RequestConfig): Promise<Response<D>> {
        return this.instance.post(url, data, config);
    }

    delete<D>(url: string, config?: RequestConfig): Promise<Response<D>> {
        return this.instance.delete(url, config);
    }

    put<D>(url: string, data?: RequestData, config?: RequestConfig): Promise<Response<D>> {
        return this.instance.put(url, data, config);
    }

    patch<D>(url: string, data?: RequestData, config?: RequestConfig): Promise<Response<D>> {
        return this.instance.patch(url, data, config);
    }

    private parseAxiosError = (error: AxiosError) => {
        const { status, data } = error.response || {};
        return new HttpError(error.message, status, data);
    };
}
