import { handleActions, ReducerMap, ReducerMapValue } from 'redux-actions';
import update from 'immutability-helper';
import { cloneDeep } from 'lodash';

import { MultiChatReducer } from 'modules/domain/common/types';
import { AuthActions } from 'modules/domain/auth/actions';

import { setStateSpec } from '../utils/reducer';

import { DatesRange, EarningsState, namespace } from './types';
import { EarningsActionPayload, EarningsActions, SetEarningsPayload, SetKPIPayload } from './actions';

type CommonEarningsPayload = SetEarningsPayload & SetKPIPayload;

type EarningsReducerMapValue<Payload> = ReducerMapValue<EarningsState, Payload>;

interface CustomReducerMap extends ReducerMap<EarningsState, CommonEarningsPayload> {
    [EarningsActions.SetEarnings]: EarningsReducerMapValue<SetEarningsPayload>;
    [EarningsActions.SetKPI]: EarningsReducerMapValue<SetKPIPayload>;
}

const initialState: EarningsState = {};

const setEarningsStateSpec = <P extends EarningsActionPayload<unknown>>(
    fn: (payload: P) => EarningsState[DatesRange],
): EarningsReducerMapValue<P> =>
    setStateSpec(payload => ({
        [payload.range]: {
            $apply: (rangeState: EarningsState[DatesRange]) => {
                const rangeStateCopy = cloneDeep(rangeState);
                return {
                    ...rangeStateCopy,
                    ...fn(payload),
                };
            },
        },
    }));

const reducerMapping: CustomReducerMap = {
    [AuthActions.Logout]: state => update(state, { $set: initialState }),
    [EarningsActions.SetEarnings]: setEarningsStateSpec(payload => ({
        earnings: payload.earnings,
    })),
    [EarningsActions.SetKPI]: setEarningsStateSpec(payload => ({
        fullKPI: payload.fullKPI,
    })),
};

export const reducer: MultiChatReducer<EarningsState, CommonEarningsPayload> = {
    [namespace]: handleActions(reducerMapping, initialState),
};
