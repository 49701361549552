import React, { memo, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import classNames from 'classnames';

import { useChatMediaEnabled } from 'modules/domain/media/hooks';
import { DialogModel } from 'modules/domain/dialog';
import iconCustomPhoto from 'images/icon-custom-photo.svg';

import styles from './index.module.scss';

type Props = {
    dialogId: string;
    animatorId: string;
    chatLogWrapperInnerRef: React.RefObject<HTMLDivElement>;
};

export const ChatDropzone = memo((props: Props) => {
    const { dialogId, chatLogWrapperInnerRef, animatorId } = props;
    const { sendPhotoMessageWithFileUpload } = DialogModel.hooks.useDialogMessagesApi(dialogId);

    const mediaInChatEnabled = useChatMediaEnabled(animatorId);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            if (acceptedFiles.length) {
                sendPhotoMessageWithFileUpload(acceptedFiles[0]);
            }
        },
        [sendPhotoMessageWithFileUpload],
    );

    const { getRootProps, getInputProps, isDragAccept } = useDropzone({
        accept: { 'image/*': ['.png', '.jpg', '.jpeg'] },
        maxFiles: 1,
        noClick: true,
        noKeyboard: true,
        onDrop,
    });

    if (!mediaInChatEnabled) {
        return null;
    }

    return (
        <div
            {...getRootProps({
                className: classNames(styles.dropzoneContainer, isDragAccept && styles.dropzoneActiveContainer),
            })}
        >
            <input {...getInputProps()} />

            {isDragAccept && (
                <div
                    className={styles.dropzoneContentWrapper}
                    style={{
                        height: chatLogWrapperInnerRef.current?.clientHeight,
                        width: chatLogWrapperInnerRef.current?.clientWidth,
                    }}
                >
                    <div className={styles.dropzoneContent}>
                        <img src={iconCustomPhoto} alt={''} />
                        <p>Drop photos here to send them</p>
                    </div>
                </div>
            )}
        </div>
    );
});
