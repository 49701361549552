import React, { memo, useCallback, useMemo } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import classNames from 'classnames';

import { DialogModel } from 'modules/domain/dialog';
import { SearchMessagesDirection } from 'modules/domain/dialog/types';
import { RoundButton } from 'modules/components/common/button/round';
import { ChevronUpIcon } from 'modules/components/icon/chevron/up';
import { ChevronDownIcon } from 'modules/components/icon/chevron/down';
import { testId } from 'modules/utils/test-id';

import styles from './index.module.scss';

type Props = {
    dialogId: string;
    onClose: () => void;
};

export const ChatSearchWidget = memo((props: Props) => {
    const { dialogId, onClose } = props;
    const { searchMessages, switchSearchMessages } = DialogModel.hooks.useDialogMessagesApi(dialogId);
    const { switchOffSearchMessages } = DialogModel.hooks.useDialogActionsById(dialogId);
    const { searchedMessages } = DialogModel.hooks.useSelectorsById(dialogId);

    const searchMessagesEnabled = !!searchedMessages;
    const someResultFound = !!searchedMessages?.totalCount;
    const current = useMemo(
        () => (searchedMessages ? searchedMessages.totalCount - searchedMessages.currentSearchedMessageIndex : null),
        [searchedMessages],
    );
    const total = useMemo(() => (searchedMessages ? searchedMessages.totalCount : null), [searchedMessages]);

    const hasPreviousSearchResults = useMemo(
        () => someResultFound && !!current && current > 1,
        [someResultFound, current],
    );

    const hasNextSearchResults = useMemo(
        () => someResultFound && !!current && !!total && current < total,
        [someResultFound, current, total],
    );

    const close = useCallback(() => {
        switchOffSearchMessages();
        onClose();
    }, [onClose, switchOffSearchMessages]);

    const handleTextChange = useDebouncedCallback(searchMessages, 500);

    const handleChevronClick = (direction: SearchMessagesDirection) => {
        switchSearchMessages(direction);
    };

    return (
        <div className={styles.panel}>
            <input
                {...testId('in-chat-search-field')}
                className={styles.messageInput}
                type="text"
                placeholder="Type something to search in this chat…"
                onChange={({ target: { value } }) => handleTextChange(value)}
            />
            {searchMessagesEnabled && (
                <div {...testId('in-chat-search-progress-value')} className={styles.progress}>
                    {current}/{total}
                </div>
            )}
            <div className={styles.activityButtons}>
                <RoundButton
                    size="md"
                    theme="darker"
                    {...testId('in-chat-search-go-down-button')}
                    className={classNames(styles.nextButton, {
                        [styles.disabledButton]: !hasNextSearchResults,
                    })}
                    onClick={() => handleChevronClick('down')}
                >
                    <ChevronDownIcon />
                </RoundButton>
                <RoundButton
                    size="md"
                    theme="darker"
                    {...testId('in-chat-search-go-up-button')}
                    className={classNames(styles.prevButton, {
                        [styles.disabledButton]: !hasPreviousSearchResults,
                    })}
                    onClick={() => handleChevronClick('up')}
                >
                    <ChevronUpIcon />
                </RoundButton>
                <RoundButton
                    {...testId('in-chat-search-close-button')}
                    size="md"
                    theme="darker"
                    className={styles.closeButton}
                    onClick={close}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M4 4L12 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M12 4L4 12" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                </RoundButton>
            </div>
        </div>
    );
});
