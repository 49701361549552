import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { actions } from '../actions';

export const useDialogActionsWithBotAnswer = () => {
    const dispatch = useDispatch();
    const changeLastBotMessage = useCallback(
        (lastBotMessage: boolean) => dispatch(actions.setLastBotMessage(lastBotMessage)),
        [dispatch],
    );

    return {
        changeLastBotMessage,
    };
};
