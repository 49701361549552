import { isDateInRange } from 'modules/utils/date';

import { GiftDto } from '../types';

function isActiveGift({ meta }: GiftDto): boolean {
    if (!meta?.period) {
        return true;
    }
    const {
        date: { day, month },
        duration: { before, after },
    } = meta.period;

    const today = Date.now();

    return isDateInRange(today, month, day, before, after);
}

export const isInPriceLimit = (gift: GiftDto, priceLimit: number) => {
    return gift.price <= priceLimit;
};

export const filterAvailableGifts = (gifts: GiftDto[], priceLimit: number) =>
    gifts.filter(gift => isActiveGift(gift) && isInPriceLimit(gift, priceLimit));
