import React, { memo, useState } from 'react';

import { DialogModel } from 'modules/domain/dialog';

import styles from './index.module.css';

type Pref = {
    id: string;
    title: string;
};

const preferencesToShow = [
    { id: 'character', title: 'Character' },
    { id: 'favouriteEmojis', title: 'Favorite Emojis' },
    { id: 'typing', title: 'Typing' },
    { id: 'style', title: 'Style' },
];

export const AnimatorPreferences = memo(() => {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { dialogId } = DialogModel.hooks.useSelectors();
    const { preferences } = DialogModel.hooks.useSelectorsById(dialogId);

    const hasSomePreference = preferencesToShow.some(pref => preferences[pref.id] !== undefined);
    if (!hasSomePreference) {
        return null;
    }

    return (
        <div
            className={styles.preferencesIcon}
            onMouseEnter={() => setTooltipOpen(true)}
            onMouseLeave={() => setTooltipOpen(false)}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                    d="M11.5 16.5H12.5V11H11.5V16.5ZM12 9.57693C12.1744 9.57693 12.3205 9.51795 12.4385 9.4C12.5564 9.28205 12.6154 9.13589 12.6154 8.96152C12.6154 8.78718 12.5564 8.64102 12.4385 8.52307C12.3205 8.40512 12.1744 8.34615 12 8.34615C11.8256 8.34615 11.6795 8.40512 11.5615 8.52307C11.4436 8.64102 11.3846 8.78718 11.3846 8.96152C11.3846 9.13589 11.4436 9.28205 11.5615 9.4C11.6795 9.51795 11.8256 9.57693 12 9.57693ZM12.0034 21C10.7588 21 9.58872 20.7638 8.4931 20.2915C7.39748 19.8192 6.44444 19.1782 5.63397 18.3685C4.82352 17.5588 4.18192 16.6066 3.70915 15.512C3.23638 14.4174 3 13.2479 3 12.0034C3 10.7588 3.23616 9.58872 3.70848 8.4931C4.18081 7.39748 4.82183 6.44444 5.63153 5.63398C6.44123 4.82353 7.39337 4.18192 8.48795 3.70915C9.58255 3.23638 10.7521 3 11.9966 3C13.2412 3 14.4113 3.23616 15.5069 3.70847C16.6025 4.18081 17.5556 4.82182 18.366 5.63152C19.1765 6.44122 19.8181 7.39337 20.2908 8.48795C20.7636 9.58255 21 10.7521 21 11.9966C21 13.2412 20.7638 14.4113 20.2915 15.5069C19.8192 16.6025 19.1782 17.5556 18.3685 18.366C17.5588 19.1765 16.6066 19.8181 15.512 20.2909C14.4174 20.7636 13.2479 21 12.0034 21ZM12 20C14.2333 20 16.125 19.225 17.675 17.675C19.225 16.125 20 14.2333 20 12C20 9.76667 19.225 7.875 17.675 6.325C16.125 4.775 14.2333 4 12 4C9.76667 4 7.875 4.775 6.325 6.325C4.775 7.875 4 9.76667 4 12C4 14.2333 4.775 16.125 6.325 17.675C7.875 19.225 9.76667 20 12 20Z"
                    fill="white"
                />
            </svg>
            {tooltipOpen ? (
                <div className={styles.tooltip}>
                    {preferencesToShow.map((pref: Pref) => (
                        <div className={styles.preferenceContainer} key={pref.id}>
                            <div className={styles.title}>{pref.title}:</div>
                            <div className={styles.content}>{preferences[pref.id]}</div>
                        </div>
                    ))}
                </div>
            ) : null}
        </div>
    );
});
